import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Slide,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import {
  Enrolment,
  EnrolmentPayment,
  Fees,
  getNewFeesObject,
  Registration,
  Student,
} from "../../util/data-models";
import Emoji from "../ui-elements/emoji";
import InvoiceModal from "./invoicemodal";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCardIcon from "@mui/icons-material/AddCard";
import DescriptionIcon from "@mui/icons-material/Description";
import { v4 as uuidv4 } from "uuid";
import EditEnrolmentPayments from "./editenrolmentpayments";
import { getFees } from "../../util/api-calls";
import {
  updateEnrolmentFees,
  enrolmentStatuses,
  getFeeAmountByGradeStudentGrade,
  statusToColor,
  getEnrolmentStatus,
  colorType,
} from "../../util/enrolment-utils";
import { InvoiceData, ReceiptData } from "./pdfgenerator/invoice-data";
import ReceiptModal from "./receiptmodal";
import { TransitionProps } from "@mui/material/transitions";

class EditEnrolments extends React.Component<
  {
    onEnrolmentsUpdate: (enrolments: Enrolment[]) => void;
    student: Student;
    registrations: Registration[];
  },
  {
    enrolments: Enrolment[];
    fees: Fees;
    invoiceModalFlags: boolean[];
    receiptModalFlags: boolean[];
    deletePopUp: boolean;
    currentRegistration: number;
  }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      enrolments: this.props.student?.enrolments ?? [],
      fees: getNewFeesObject(),
      invoiceModalFlags: [],
      receiptModalFlags: [],
      deletePopUp: false,
      currentRegistration: 0,
    };
    const studentName =
      this.props.student.firstname + " " + this.props.student.lastname;
  }

  componentDidMount() {
    getFees()
      .then((fees) => {
        this.setState({
          fees: fees,
        });
      })
      .then(() => {
        this.setState((state, props) => ({
          enrolments: updateEnrolmentFees(
            state.enrolments,
            props.student,
            state.fees
          ),
        }));
      });
  }

  Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  handleCloseDelete() {
    this.setState({
      ...this.state,
      deletePopUp: false,
      currentRegistration: 0,
    });
  }

  handleOpenDelete(i: number) {
    console.log(i);
    this.setState({
      currentRegistration: i,
      deletePopUp: true,
    });
  }

  getEnrolmentYear() {
    let result = new Date().getFullYear().toString();
    if (new Date().getMonth() >= 11) {
      result = (new Date().getFullYear() + 1).toString();
    }
    return result;
  }

  addClick() {
    if (this.state.enrolments) {
      this.setState(
        (prevState, props) => ({
          enrolments: [
            ...prevState.enrolments,
            {
              id: uuidv4(),
              status: enrolmentStatuses.pending,
              discount: "",
              year: this.getEnrolmentYear(),
              grade: props?.student?.grade ?? "",
              amountOwing: getFeeAmountByGradeStudentGrade(
                props.student,
                prevState.fees
              ),
              totalAmount: getFeeAmountByGradeStudentGrade(
                props.student,
                prevState.fees
              ),
              payments: [] as EnrolmentPayment[],
              createdAt: new Date(),
            },
          ],
          invoiceModalFlags: [...prevState.invoiceModalFlags, false],
          receiptModalFlags: [...prevState.receiptModalFlags, false],
        }),
        () => {
          this.props.onEnrolmentsUpdate(this.state.enrolments);
        }
      );
    } else {
      this.setState(
        (prevState, props) => ({
          enrolments: [
            {
              id: uuidv4(),
              status: enrolmentStatuses.pending,
              discount: "",
              year: this.getEnrolmentYear(),
              grade: "",
              amountOwing: getFeeAmountByGradeStudentGrade(
                this.props.student,
                prevState.fees
              ),
              totalAmount: getFeeAmountByGradeStudentGrade(
                this.props.student,
                prevState.fees
              ),
              payments: [] as EnrolmentPayment[],
              createdAt: new Date(),
            },
          ],
          invoiceModalFlags: [false],
          receiptModalFlags: [false],
        }),
        () => {
          this.props.onEnrolmentsUpdate(this.state.enrolments);
        }
      );
    }
  }

  getInvoiceData(e: Enrolment): InvoiceData {
    console.log(e.discount);
    const currentDate = new Date().toISOString().slice(0, 10);
    const createdDate = new Date(e.createdAt).toISOString().slice(0, 10);
    return {
      student: this.props.student,
      id: e.id,
      invoice_no: currentDate.replace(/-/g, "") + e.id.slice(0, 4),
      company:
        this.props.student.guardians[0]?.firstname +
        " " +
        this.props.student.guardians[0]?.lastname,
      email: this.props.student.guardians[0]?.email,
      phone: this.props.student.guardians[0]?.phone,
      address: this.props.student.guardians[0]?.address,
      trans_date: createdDate,
      items: [
        {
          item_num: 0,
          discount: e.discount,
          desc: "Enrolment fee for " + this.props.student.grade,
          qty: 1,
          rate: e.totalAmount,
        },
      ],
    };
  }

  getReceiptData(e: Enrolment): ReceiptData {
    console.log(e);
    const currentDate = new Date().toISOString().slice(0, 10);
    const createdDate = new Date(e.createdAt).toISOString().slice(0, 10);
    return {
      student: this.props.student,
      id: e.id,
      invoice_no: currentDate.replace(/-/g, "") + e.id.slice(0, 4),
      company:
        this.props.student.guardians[0]?.firstname +
        " " +
        this.props.student.guardians[0]?.lastname,
      email: this.props.student.guardians[0]?.email,
      phone: this.props.student.guardians[0]?.phone,
      address: this.props.student.guardians[0]?.address,
      trans_date: createdDate,
      items: [
        {
          item_num: 0,
          discount: e.discount,
          desc: "Enrolment fee for " + this.props.student.grade,
          qty: 1,
          rate: e.totalAmount,
          createdAt: e.createdAt,
        },
      ],
      payments: e.payments,
    };
  }

  removeClick(i: number) {
    let enrlmnts = [...this.state.enrolments];
    enrlmnts.splice(i, 1);
    this.setState({
      ...this.state,
      enrolments: enrlmnts,
      deletePopUp: false,
      currentRegistration: 0,
    });
    this.props.onEnrolmentsUpdate(enrlmnts);
  }

  updateModalFlag(i: number, value: boolean) {
    let flags = this.state.invoiceModalFlags;
    flags[i] = value;
    this.setState({
      ...this.state,
      invoiceModalFlags: flags,
    });
  }

  updateReceiptModalFlag(i: number, value: boolean) {
    let flags = this.state.receiptModalFlags;
    flags[i] = value;
    this.setState({
      ...this.state,
      receiptModalFlags: flags,
    });
  }

  enrolmentForCurrentYearExists(): boolean {
    const currentYear = new Date().getFullYear().toString();
    const currentMonth = new Date().getMonth().toString();
    const result = this.state.enrolments.some(
      (enrolment: Enrolment) => enrolment.year === this.getEnrolmentYear()
    );
    return result;
  }

  enrolmentForNextYearExists(): boolean {
    const nextYear = (new Date().getFullYear() + 1).toString();
    const result = this.state.enrolments.some(
      (enrolment: Enrolment) => enrolment.year === nextYear
    );
    return result;
  }

  studentIsRegistered(): boolean {
    return this.props.registrations.some(
      (r) => r.status === "Complete" && r.student_id === this.props.student.id
    );
  }

  studentHasGrade(): boolean {
      return "" !== this.props.student.grade
  }

  createUI() {
    const paymentUpdateHandler = (
      payments: EnrolmentPayment[],
      enrolment_id: string
    ) => {
      const enrolmentToUpdate = this.state.enrolments.find(
        (e) => e.id === enrolment_id
      );
      if (enrolmentToUpdate && enrolmentToUpdate?.payments) {
        enrolmentToUpdate.payments = payments;
      }
      this.updateEnrolment(enrolmentToUpdate);
    };
    const sortedEnrolments = this.state?.enrolments.sort(
      (e1, e2) =>
        Number(new Date(e2.createdAt)) - Number(new Date(e1.createdAt))
    );
    return (
      <>
        <Dialog
          open={this.state.deletePopUp}
          TransitionComponent={this.Transition}
          keepMounted
          onClose={this.handleCloseDelete.bind(this)}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>{"Are you sure you want to delete?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              If you click Yes, the record will be removed. Click No to cancel.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseDelete.bind(this)}>No</Button>
            <Button
              onClick={this.removeClick.bind(
                this,
                this.state.currentRegistration
              )}
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>

        {sortedEnrolments?.map((e: Enrolment, i) => (
          <React.Fragment key={e.id}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="stretch"
              spacing={2}
              style={{ verticalAlign: "center" }}
            >
              <Grid item xs={7} alignSelf="center">
                <Typography
                  style={{
                    paddingLeft: 0,
                    fontSize: 13,
                  }}
                  variant="body2"
                >
                  Enrolment Year {e.year}
                </Typography>
              </Grid>

              <Grid item xs={3} alignSelf="center">
                <Chip
                  label={e.status}
                  color={statusToColor[e.status] as colorType}
                  size="small"
                  style={{ display: "flex" }}
                />
              </Grid>

              <Grid item xs={1} alignSelf="center">
                <IconButton
                  onClick={() => {
                    this.handleOpenDelete(i);
                  }}
                  style={{ display: "flex" }}
                  aria-label="delete"
                  color="warning"
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>

            <Grid
              container
              direction="row"
              justifyContent="start"
              alignItems="stretch"
              spacing={2}
            >
              <Grid item xs={6} alignSelf="center">
                <Typography
                  style={{
                    paddingLeft: 0,
                    fontSize: 13,
                  }}
                  variant="body1"
                >
                  Total Amount:
                </Typography>
              </Grid>

              <Grid item xs={2} alignSelf="center">
                <Typography variant="body2" textAlign={"right"}>
                  K{Number(e.totalAmount).toFixed(2)}
                </Typography>
              </Grid>
            </Grid>

            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={2}
            >
              {/* <Grid item xs={3.6} alignSelf="center">
            <Typography
              style={{
                paddingLeft: 0,
                fontSize: 13,
                marginTop: 12
              }}
              variant="body1"
            >
              Discount:
            </Typography>
          </Grid> */}

              <Grid item xs={6} mt={2} alignSelf="center">
                <TextField
                  InputLabelProps={{ shrink: true }}
                  size="small"
                  style={{
                    marginTop: 5,
                    //display: "flex",
                    minWidth: 80,
                    maxWidth: 172,
                    //flex: 1,
                  }}
                  type="number"
                  className="form-control "
                  label="Discount percentage"
                  name="discount"
                  error={Number(e.discount || 0) > 100}
                  helperText={
                    Number(e.discount || 0) > 100
                      ? "Discount must be less than 100"
                      : " "
                  }
                  defaultValue={e.discount}
                  InputProps={{
                    inputProps: { min: 0, max: 100 },
                    endAdornment: (
                      <InputAdornment position="start">%</InputAdornment>
                    ),
                  }}
                  onChange={(event) => this.updateDiscount(event, e)}
                />
              </Grid>

              <Grid item xs={2} mt={2} mb={2} alignSelf="center">
                <Typography variant="body2" textAlign={"right"}>
                  K
                  {Number(
                    (e.totalAmount * parseInt(e.discount)) / 100 || 0
                  ).toFixed(2)}
                </Typography>
              </Grid>
            </Grid>

            <Grid
              container
              direction="row"
              justifyContent="start"
              alignItems="stretch"
              spacing={2}
            >
              <Grid item xs={6} alignSelf="center">
                <Typography
                  style={{
                    paddingLeft: 0,
                    fontSize: 13,
                  }}
                  variant="body1"
                >
                  Discounted Amount:
                </Typography>
              </Grid>

              <Grid item xs={2} alignSelf="center">
                <Typography variant="body2" textAlign={"right"}>
                  K
                  {Number(
                    (
                      e.totalAmount -
                      e.totalAmount * (parseInt(e.discount) / 100)
                    ).toFixed(2)
                  ) || "0"}
                </Typography>
              </Grid>
            </Grid>

            <Grid
              container
              direction="row"
              justifyContent="start"
              alignItems="stretch"
              spacing={2}
              mt={-1}
              mb={1}
            >
              <Grid item xs={3} alignSelf="center" >
                <Button
                  startIcon={<DescriptionIcon />}
                  variant="contained"
                  color="secondary"
                  size="small"
                  onClick={() => this.updateModalFlag(i, true)}
                >
                  View Invoice
                </Button>
                <InvoiceModal  key={e.discount}
                  open={this.state.invoiceModalFlags[i]}
                  onClose={() => this.updateModalFlag(i, false)}
                  invoiceData={this.getInvoiceData(e)}
                />
              </Grid>
            </Grid>

            {/* <Grid
          container
          direction="row"
          justifyContent="start"
          alignItems="stretch"
          mt={0}
          spacing={2}
        > */}
            {/* <Grid item xs={6} alignSelf="center">
            <Typography
              style={{
                paddingLeft: 0,
                fontSize: 13,
              }}
              variant="body1"
            >
              Amount due:
            </Typography>
          </Grid>

          <Grid item xs={2} alignSelf="center">
            <Typography variant="body2" textAlign={"right"}>
              K {Number(e.amountOwing).toFixed(2)}
            </Typography>
          </Grid> */}

            {/* </Grid> */}
            <Grid
              container
              direction="row"
              justifyContent="start"
              alignItems="stretch"
              spacing={2}
            >
              <Grid item xs={6} alignSelf="center">
                <Typography
                  style={{
                    paddingLeft: 0,
                    fontSize: 13,
                  }}
                  variant="body1"
                >
                  Total amount paid:
                </Typography>
              </Grid>

              <Grid item xs={2} alignSelf="center">
                <Typography variant="body2" textAlign={"right"}>
                  K{Number(this.getSumOfPayments(e)).toFixed(2)}
                </Typography>
              </Grid>
            </Grid>

            <Grid
              container
              direction="row"
              justifyContent="start"
              alignItems="stretch"
              spacing={2}
            >
              <Grid item xs={6} alignSelf="center">
                <Typography
                  style={{
                    paddingLeft: 0,
                    fontSize: 13,
                  }}
                  variant="body1"
                >
                  Remaining amount owing:
                </Typography>
              </Grid>
              <Grid item xs={2} alignSelf="center">
                <Typography variant="body2" textAlign={"right"}>
                  K
                  {Number(
                    (
                      e.totalAmount -
                      e.totalAmount * ((parseInt(e.discount) || 0) / 100) -
                      this.getSumOfPayments(e)
                    ).toFixed(2)
                  ) || "0"}
                </Typography>
              </Grid>
            </Grid>

            {e.status !== "Pending" ? (
              <>
                <Grid
                  container
                  direction="row"
                  justifyContent="start"
                  alignItems="stretch"
                  spacing={2}
                  mt={-1}
                  mb={1}
                >
                  <Grid item xs={4} alignSelf="center">
                    <Button
                      startIcon={<DescriptionIcon />}
                      variant="contained"
                      color="secondary"
                      size="small"
                      onClick={() => this.updateReceiptModalFlag(i, true)}
                    >
                      View Payment Receipt
                    </Button>

                    <ReceiptModal key={e.discount}
                      open={this.state.receiptModalFlags[i]}
                      onClose={() => this.updateReceiptModalFlag(i, false)}
                      receiptData={this.getReceiptData(e)}
                    />
                  </Grid>
                </Grid>
              </>
            ) : (
              <></>
            )}

            <EditEnrolmentPayments
              onPaymentsUpdate={paymentUpdateHandler}
              enrolment={e}
            />
            <Divider style={{ marginTop: 12, marginBottom: 24 }}></Divider>
          </React.Fragment>
        ))}
      </>
    );
  }

  getSumOfPayments(enrolment: Enrolment): number {
    return enrolment?.payments?.reduce(
      (accumulator: number, e: EnrolmentPayment) => {
        return accumulator + Number(e.amount);
      },
      0
    );
  }

  updateDiscount = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    e: Enrolment
  ) => {
    e[event.target.name] = event.target.value.toString();
    e.amountOwing =
      e.amountOwing - (e.amountOwing * parseInt(event.target.value)) / 100;
    this.updateEnrolment(e);
  };

  updateEnrolment(enrolmentToUpdate?: Enrolment) {
    console.log(enrolmentToUpdate);
    let indexToBeUpdated = -1;
    if (enrolmentToUpdate) {
      const discountAmount =
        (enrolmentToUpdate.totalAmount * parseInt(enrolmentToUpdate.discount)) /
          100 || 0;
      const sum = this.getSumOfPayments(enrolmentToUpdate) + discountAmount;
      enrolmentToUpdate.status = getEnrolmentStatus(
        sum,
        enrolmentToUpdate.totalAmount
      );
      let newEnrolments = [...this.state.enrolments];
      newEnrolments.map((e, index) => {
        if (e.id === enrolmentToUpdate.id) {
          indexToBeUpdated = index;
        }
      });
      if (indexToBeUpdated >= 0) {
        newEnrolments[indexToBeUpdated] = enrolmentToUpdate;
        this.setState((oldState, props) => ({
          ...oldState,
          enrolments: newEnrolments,
        }));
        this.props.onEnrolmentsUpdate(newEnrolments);
      }
    }
  }

  render() {
    return (
      <>
        <Typography
          style={{
            paddingLeft: 0,
            fontSize: 16,
            marginTop: 10,
          }}
        >
          Enrolments
        </Typography>
        {this.createUI()}
        <Button
          startIcon={<AddCardIcon />}
          style={{ marginBottom: 12 }}
          variant="contained"
          color="success"
          disabled={
            this.enrolmentForNextYearExists() ||
            this.enrolmentForCurrentYearExists() ||
            !this.studentIsRegistered() ||
            !this.studentHasGrade()
          }
          onClick={this.addClick.bind(this)}
        >
          Add Enrolment
        </Button>

        {!this.studentIsRegistered() && (
          <Typography variant="caption">
            <Emoji symbol="⚠️" /> Please ensure student is registered before
            proceeding with enrolment.
          </Typography>
        )}
      </>
    );
  }
}

export default EditEnrolments;

import {
  Button,
  Chip,
  Divider,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import React, { ChangeEvent } from "react";
import {
  Enrolment,
  EnrolmentPayment,
  Fees,
  getNewFeesObject,
  Registration,
  Student,
} from "../../util/data-models";
import Emoji from "../ui-elements/emoji";
import InvoiceModal from "./invoicemodal";
import StudentListDialog from "./studentlistdialog";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCardIcon from "@mui/icons-material/AddCard";
import AddLinkIcon from "@mui/icons-material/AddLink";
import DescriptionIcon from "@mui/icons-material/Description";
import { v4 as uuidv4 } from "uuid";
import EditEnrolmentPayments from "./editenrolmentpayments";
import { getFees } from "../../util/api-calls";
import {
  updateEnrolmentFees,
  enrolmentStatuses,
  getFeeAmountByGradeStudentGrade,
  statusToColor,
  getEnrolmentStatus,
  colorType,
} from "../../util/enrolment-utils";
import { InvoiceData, ReceiptData } from "./pdfgenerator/invoice-data";
import { boolean } from "yup";
import ViewEnrolmentPayments from "./ViewEnrolmentPayments";
import ReceiptModal from "./receiptmodal";

class ViewEnrolments extends React.Component<
  {
    student: Student | any;
    registrations: Registration[];
  },
  {
    enrolments: Enrolment[];
    fees: Fees;
    invoiceModalFlags: boolean[];
    receiptModalFlags: boolean[];
  }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      enrolments: this.props.student?.enrolments ?? [],
      fees: getNewFeesObject(),
      invoiceModalFlags: new Array(this.props.student?.enrolments?.length ?? 1),
      receiptModalFlags: new Array(this.props.student?.enrolments?.length ?? 1),
    };
    this.state.invoiceModalFlags.forEach((f, i) => {
      this.state.invoiceModalFlags[i] = false;
    });
    this.state.receiptModalFlags.forEach((f, i) => {
      this.state.receiptModalFlags[i] = false;
    });
    const studentName =
      this.props.student.firstname + " " + this.props.student.lastname;
  }

  componentDidUpdate(prevProps: { student: any }) {
    if (prevProps.student !== this.props.student) {
      this.setState({
        ...this.state,
        enrolments: this.props.student?.enrolments ?? [],
      });
    }
  }

  componentDidMount() {
    getFees().then((fees) => {
      this.setState({
        fees: fees,
      });
    });
  }

  getInvoiceData(e: Enrolment): InvoiceData {
    const currentDate = new Date().toISOString().slice(0, 10);
    const createdDate = new Date(e.createdAt).toISOString().slice(0, 10);
    return {
      student: this.props.student,
      id: e.id,
      invoice_no: currentDate.replace(/-/g, "") + e.id.slice(0, 4),
      company:
        this.props.student.guardians[0]?.firstname +
        " " +
        this.props.student.guardians[0]?.lastname,
      email: this.props.student.guardians[0]?.email,
      phone: this.props.student.guardians[0]?.phone,
      address: this.props.student.guardians[0]?.address,
      trans_date: createdDate,
      items: [
        {
          item_num: 0,
          desc: "Enrolment fee for " + this.props.student.grade,
          qty: 1,
          rate: e.totalAmount,
          discount: e.discount,
        },
      ],
    };
  }

  getReceiptData(e: Enrolment): ReceiptData {
    console.log(e)
    const currentDate = new Date().toISOString().slice(0, 10);
    const createdDate = new Date(e.createdAt).toISOString().slice(0, 10);
    return {
      student: this.props.student,
      id: e.id,
      invoice_no: currentDate.replace(/-/g, "") + e.id.slice(0, 4),
      company:
        this.props.student.guardians[0]?.firstname +
        " " +
        this.props.student.guardians[0]?.lastname,
      email: this.props.student.guardians[0]?.email,
      phone: this.props.student.guardians[0]?.phone,
      address: this.props.student.guardians[0]?.address,
      trans_date: createdDate,
      items: [
        {
          item_num: 0,
          desc: "Enrolment fee for " + this.props.student.grade,
          qty: 1,
          rate: e.totalAmount,
          discount: e.discount,
        },
      ],
      payments: e.payments,
    };
  }

  updateReceiptModalFlag(i: number, value: boolean) {
    let flags = this.state.receiptModalFlags;
    flags[i] = value;
    this.setState({
      ...this.state,
      receiptModalFlags: flags,
    });
  }

  updateModalFlag(i: number, value: boolean) {
    let flags = this.state.invoiceModalFlags;
    flags[i] = value;
    this.setState({
      ...this.state,
      invoiceModalFlags: flags,
    });
  }

  createUI() {
    const sortedEnrolments = this.state?.enrolments.sort(
      (e1, e2) => Number(new Date(e2.createdAt)) - Number(new Date(e1.createdAt)),
    );
    return sortedEnrolments?.map((e: Enrolment, i) => (
      <React.Fragment key={e.id}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="stretch"
          spacing={2}
          style={{ verticalAlign: "center" }}
        >
          <Grid item xs={7} alignSelf="center">
            <Typography
              style={{
                paddingLeft: 0,
                fontSize: 13,
              }}
              variant="body2"
            >
              Enrolment Year {e.year}
            </Typography>
          </Grid>

          <Grid item xs={3} alignSelf="center">
            <Chip
              label={e.status}
              color={statusToColor[e.status] as colorType}
              size="small"
              style={{ display: "flex" }}
            />
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="start"
          alignItems="stretch"
          spacing={2}
        >
          <Grid item xs={4} alignSelf="center">
            <Typography
              style={{
                paddingLeft: 0,
                fontSize: 13,
              }}
              variant="body1"
            >
              Total Amount:
            </Typography>
          </Grid>

          <Grid item xs={2} alignSelf="center">
            <Typography variant="body2" textAlign={"right"}>
              K{Number(e.totalAmount).toFixed(2)}
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="start"
          alignItems="stretch"
          spacing={2}
        >
          <Grid item xs={4} alignSelf="center">
            <Typography
              style={{
                paddingLeft: 0,
                fontSize: 13,
              }}
              variant="body1"
            >
              Discount:
            </Typography>
          </Grid>

          <Grid item xs={2} alignSelf="center">
            <Typography variant="body2" textAlign={"right"}>
              {(e.discount || "0")} %
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="start"
          alignItems="stretch"
          spacing={2}
        >
          <Grid item xs={4} alignSelf="center">
            <Typography
              style={{
                paddingLeft: 0,
                fontSize: 13,
              }}
              variant="body1"
            >
              Discounted Amount:
            </Typography>
          </Grid>

          <Grid item xs={2} alignSelf="center">
            <Typography variant="body2" textAlign={"right"}>
              K{Number(
                (e.totalAmount - e.totalAmount * (parseInt(e.discount) / 100)).toFixed(2)) || "0"}
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="start"
          alignItems="stretch"
          spacing={2}
          mt={-1}
          mb={1}
        >
          <Grid item xs={3} alignSelf="center">
            <Button
              startIcon={<DescriptionIcon />}
              variant="contained"
              color="secondary"
              size="small"
              onClick={() => this.updateModalFlag(i, true)}
            >
              View Invoice
            </Button>

            <InvoiceModal key={e.discount}
              open={this.state.invoiceModalFlags[i]}
              onClose={() => this.updateModalFlag(i, false)}
              invoiceData={this.getInvoiceData(e)}
            />
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="start"
          alignItems="stretch"
          spacing={2}
        >
          <Grid item xs={4} alignSelf="center">
            <Typography
              style={{
                paddingLeft: 0,
                fontSize: 13,
              }}
              variant="body1"
            >
              Total amount paid:
            </Typography>
          </Grid>

          <Grid item xs={2} alignSelf="center">
            <Typography variant="body2" textAlign={"right"}>
              K{Number(this.getSumOfPayments(e)).toFixed(2)}
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="start"
          alignItems="stretch"
          spacing={2}
        >
          <Grid item xs={4} alignSelf="center">
            <Typography
              style={{
                paddingLeft: 0,
                fontSize: 13,
              }}
              variant="body1"
            >
              Remaining amount owing:
            </Typography>
          </Grid>

          <Grid item xs={2} alignSelf="center">
            <Typography variant="body2" textAlign={"right"}>
              K{Number(
                ((e.totalAmount - e.totalAmount * ((parseInt(e.discount) || 0) / 100)) - this.getSumOfPayments(e)
                ).toFixed(2)) || "0"}
            </Typography>
          </Grid>
        </Grid>

        {e.status != "Pending" ? (
          <><Grid
            container
            direction="row"
            justifyContent="start"
            alignItems="stretch"
            spacing={2}
            mt={-1}
            mb={1}
          >
            <Grid item xs={4} alignSelf="center">
              <Button
                startIcon={<DescriptionIcon />}
                variant="contained"
                color="secondary"
                size="small"
                onClick={() => this.updateReceiptModalFlag(i, true)}
              >
                View Payment Receipt
              </Button>

              <ReceiptModal
                open={this.state.receiptModalFlags[i]}
                onClose={() => this.updateReceiptModalFlag(i, false)}
                receiptData={this.getReceiptData(e)}
              />
            </Grid>
          </Grid></>
        ) : (
          <></>
        )}


        <ViewEnrolmentPayments enrolment={e} />
        {this.props.student.enrolments?.length > 1 && (
          <Divider style={{ marginTop: 12, marginBottom: 24 }}></Divider>
        )}
      </React.Fragment>
    ));
  }
  getSumOfPayments(enrolment: Enrolment): number {
    return enrolment?.payments?.reduce(
      (accumulator: number, e: EnrolmentPayment) => {
        return accumulator + Number(e.amount);
      },
      0
    );
  }

  render() {
    return (
      <>
        {!!this.props.student.enrolments?.length &&
          this.props.student.enrolments.length > 0 && (
            <Typography
              style={{
                paddingLeft: 0,
                fontSize: 16,
                marginTop: 20,
              }}
            >
              Enrolments
            </Typography>
          )}
        {this.createUI()}
      </>
    );
  }
}

export default ViewEnrolments;

import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Avatar,
  Snackbar,
  Alert,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Chip,
  CircularProgress,
  List,
  ListItemText,
  Menu,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import SchoolIcon from "@mui/icons-material/School";
import {
  DataGrid,
  GridCallbackDetails,
  GridColDef,
  GridColumnHeaderParams,
  GridEventListener,
  GridRenderCellParams,
  GridRowParams,
  MuiEvent,
} from "@mui/x-data-grid";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { NavigateFunction, useNavigate } from "react-router";
import {
  Dispatch,
  MouseEventHandler,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import {
  Enrolment,
  EnrolmentPayment,
  Grades,
  JobTitle,
  Registration,
} from "../../util/data-models";
import { Student } from "./customerlist";
import {
  deleteStudent,
  getAllEmployees,
  getAllGuardians,
  getAllStudents,
} from "../../util/api-calls";
import { Guardian } from "../../util/data-models";

import { StepIconProps } from "@mui/material/StepIcon";
import PersonIcon from "@mui/icons-material/Person";
import FamilyRestroomIcon from "@mui/icons-material/FamilyRestroom";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import ClassIcon from "@mui/icons-material/Class";
import CancelIcon from "@mui/icons-material/Cancel";
import CloseIcon from "@mui/icons-material/Close";

import MainCard from "../dashboard/ui-component/cards/MainCard";
import { styled } from "@mui/material/styles";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import React from "react";
import NumberFormat from "react-number-format";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import TableRowsIcon from "@mui/icons-material/TableRows";
import NumbersIcon from "@mui/icons-material/Numbers";

import countries from "i18n-iso-countries";
import enLocale from "i18n-iso-countries/langs/en.json";
import ReactCountryFlag from "react-country-flag";
import EditGuardian from "../editcontact/editguardian";

import { v4 as uuidv4 } from "uuid";
import PermissionsGate from "../../util/perminssion-gate";
import { SCOPES } from "../../util/permission-map";
import EditEnrolments from "../editcontact/neweditenrolment";
import ViewEnrolments from "../editcontact/viewEnrolments";
import { TransitionProps } from "@mui/material/transitions";
import { statusToColor, colorType, getEnrolmentStatus } from "../../util/enrolment-utils";
import { listeners } from "process";

import InvoiceModal from "../editcontact/invoicemodal";
import SummarizeIcon from "@mui/icons-material/Summarize";
import { InvoiceData } from "../editcontact/pdfgenerator/invoice-data";
import ReceiptModal from "../editcontact/receiptmodal";
import EnrolmentPaymentDialog from "../editcontact/enrolmentpaymentmodal";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const options = ["ACTIVE", "ALUMNI", "INACTIVE", "ALL"];

const CardWrapper = styled(MainCard)(({ theme }) => ({
  overflow: "hidden",
  color: "#fff",
  position: "relative",
  backgroundColor: "#1E88E5",
  borderRadius: "10px",
  "&>div": {
    position: "relative",
    zIndex: 5,
  },
  "&:after": {
    content: '""',
    position: "absolute",
    width: 210,
    height: 210,
    backgroundColor: "#1569BB",
    borderRadius: "50%",
    top: -85,
    right: -95,
    [theme.breakpoints.down("sm")]: {
      top: -105,
      right: -140,
    },
  },
  "&:before": {
    content: '""',
    position: "absolute",
    width: 210,
    height: 210,
    backgroundColor: "#1565C0",
    borderRadius: "50%",
    top: -125,
    right: -15,
    opacity: 0.5,
    [theme.breakpoints.down("sm")]: {
      top: -155,
      right: -70,
    },
  },
}));

const ColorlibStepIconRoot = styled("div")<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 35,
  height: 35,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",

  ...(ownerState.active && {
    backgroundImage:
      "linear-gradient( 136deg, #1E88E5 0%, #1E88E5 50%, #1E88E5 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
}));

function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <PersonIcon />,
    2: <FamilyRestroomIcon />,
    3: <SchoolIcon />,
    4: <MedicalServicesIcon />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

function StudentList(
  this: any,
  employeesList,
  studentsList,
  setUpdateParentPage: React.Dispatch<SetStateAction<boolean>>,
  updateParentPage: boolean,
  updateStudentPage: boolean,
  userName: string
) {
  const [loadingGrid, setLoadingGrid] = useState(true);
  const [search, setSearch] = useState(``);
  const [studentFilter, setStudentFilter] = useState("ACTIVE");
  const [staff, setStaff] = useState([]);
  const [activeStep, setStep] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const [list, setList] = useState([] as Student[]);
  const [originalList, setOriginalList] = useState([] as Student[]);
  const [bulkList, setBulkList] = useState([] as Student[]);

  const [selectedStudent, setStudent] = useState({} as Student);
  const [editingStudent, setEditingStudent] = useState({} as Student);
  const [addingStudent, setAddingStudent] = useState({} as Student);

  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [isBulkEditing, setIsBulkEditing] = useState(false);
  const [isViewingTable, setIsViewingTable] = useState(false);

  const [enrolmentIndex, setEnrolmentIndex] = useState(null);




  const [openInvoice, setopenInvoice] = useState(false);
  const [openReceipt, setopenReceipt] = useState(false);
  const [makePayment, setMakePayment] = useState(false);
  const [newPayment, setNewPayment] = useState(null);

  const [studentsFromAPP, setStudents] = useState({} as Student);

  const [count, setCount] = useState(0);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedFilterIndex, setSelectedFilterIndex] = React.useState(0);
  const open = Boolean(anchorEl);

  const navigate = useNavigate();

  const filterStudents = (students: Student[], searchTerm: string) => {
    return students.filter((student: Student) => {
      let fullName = (
        student.firstname +
        " " +
        student.lastname +
        " " +
        student.studentid
      ).toLowerCase();
      setLoadingGrid(false);
      return fullName.includes(searchTerm?.toLowerCase() ?? "");
    });
  };

  const getMostRecentStatus = (statuses: Status[]) => {
    return statuses?.sort(
      (a, b) =>
        new Date(b.statusdate).getTime() - new Date(a.statusdate).getTime()
    )[0];
  };

  const filterByStatus = (list: Student[], status: string) => {
    let students = [...list];
    if (status === "ALUMNI") {
      students = students.filter((emp) => emp.grade === "Alumni");
    } else if (status === "ACTIVE") {
      students = students.filter(
        (emp) =>
          emp.grade !== "Alumni" &&
          emp.transferredout !== "Yes" &&
          emp.droppedout !== "Yes"
      );
    } else if (status === "INACTIVE") {
      students = students.filter(
        (emp) => emp.transferredout === "Yes" || emp.droppedout === "Yes"
      );
    }
    return students;
  };

  useEffect(() => {

    if (studentsList.length > 0) {
      const newList = [...filterByStatus(studentsList, "ACTIVE")];
      setCount(1);
      setIsEditing(false);
      setIsAdding(false);
      setIsBulkEditing(false);
      setLoadingGrid(true);
      getAllStudents().then((students) => {
        setOriginalList(students);
        setStudent(students[0]);
        let avatarId = document.getElementById("avatar");
        if (avatarId) {
        } else {
          setStudent(students[0]);
        }
        let id = document.getElementById("searchbarid");
        if (id !== null) {
          if (id.value) {
            setList(filterStudents(students, id.value));
          } else {
            setList(filterStudents(students, search));
          }
        } else {
          setList(filterStudents(students, search));
        }
        getAllEmployees().then((employees) => {
          setStaff(employees);
        });
        setLoadingGrid(false);
        setStep(0);
        setSearch("");
      });
    }
  }, [updateStudentPage]);

  useEffect(() => {
    setCount(count + 1);
    setLoadingGrid(true);
    // getAllStudents().then((students) => {
    if (studentsList.length > 0) {
      const newList = [...filterByStatus(studentsList, "ACTIVE")];

      setOriginalList(newList);

      let avatarId = document.getElementById("avatar");
      if (avatarId) {
      } else {
        setStudent(newList[0]);
      }
      let id = document.getElementById("searchbarid");
      if (id !== null) {
        if (id.value) {
          setList(filterStudents(newList, id.value));
        } else {
          setList(filterStudents(newList, search));
        }
      } else {
        setList(filterStudents(newList, search));
      }
      // fetch("https://devschool.kozosms.com/api/api/employees")
      //   .then((resp) => resp.json())
      //   .then((resp) => {

      if (employeesList.length > 0) {
        setStaff(employeesList);
      }
      // });
      setLoadingGrid(false);
      // });
    }
  }, [studentsList, employeesList]);

  useEffect(() => {
    // if (search.length > 1) {
    // const newList = [...filterByStatus(studentsList, "ACTIVE")];
    setLoadingGrid(true);
    setList(filterStudents(originalList, search));

    // } else {
    // setLoadingGrid(true);
    // getAllStudents().then((students) => {
    //   if(search.length <= 1){
    //     setList(filterStudents(students, search));
    //   }
    //   setOriginalList(students)
    //   setLoadingGrid(false);
    // });

    // }

    if (employeesList.length > 0) {
      setStaff(employeesList);
    }
    //setLoadingGrid(false);
  }, [search, employeesList]);

  useEffect(() => {
    setCount(1);
    setIsEditing(false);
    setIsAdding(false);
    setIsBulkEditing(false);
    setLoadingGrid(true);
    // getAllStudents().then((students) => {

    if (studentsList.length > 0) {
      const newList = [...filterByStatus(studentsList, "ACTIVE")];
      setOriginalList(newList);

      let avatarId = document.getElementById("avatar");
      if (avatarId) {
      } else {
        setStudent(newList[0]);
      }
      let id = document.getElementById("searchbarid");
      if (id !== null) {
        if (id.value) {
          setList(filterStudents(newList, id.value));
        } else {
          setList(filterStudents(newList, search));
        }
      } else {
        setList(filterStudents(newList, search));
      }
      if (employeesList.length > 0) {
        setStaff(employeesList);
      }
      setLoadingGrid(false);
      setStep(0);
      setSearch("");
      // });
    }
  }, [studentsList, employeesList]);

  useEffect(() => {
    let index = findEnrolmentIndex(selectedStudent)
    setEnrolmentIndex(index)

  }, [selectedStudent]);


  function findEnrolmentIndex(student) {
    if (!student || !student.enrolments) {
      return null; // Return null if no student or no enrolments
    }

    for (let i = 0; i < student.enrolments.length; i++) {
      if (student.enrolments[i].year === getCurrentSchoolYear()) {
        return i; // Return the index if the year matches 2024
      }
    }

    return null; // Return null if no enrolment for 2024 is found
  }



  const addStudentClicked = () => {
    if (isAdding) {
      setIsAdding(false);
    } else {
      setIsAdding(true);
      setStep(0);
    }
  };

  const deleteHandler = (studentId: number) => {
    deleteStudent(studentId)
      .then((_emps) => {
        return getAllStudents();
      })
      .then((students) => {
        setList(students);
        setStudent({} as Student);
        setDeletePopUp(false);
        setSearch("");
        setOriginalList(students);
      });
  };

  const steps = [
    "Personal Information",
    "Parents & Enrolment Information",
    "School Information",
    "Medical Information",
  ];

  const handleProgress = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    param: number
  ) => {
    if (isEditing) {
      if (activeStep === 0) {
        if (
          !editingStudent.firstname ||
          !editingStudent.lastname ||
          !editingStudent.gender ||
          !editingStudent.dob ||
          !editingStudent.number
        ) {
          handleOpenRequiredFieldsPopUp();
        } else {
          setStep(param);
        }
      } else if (activeStep === 1) {
        setStep(param);
      } else if (activeStep === 2) {
        setStep(param);
      } else if (activeStep === 3) {
        setStep(param);
      }
    } else if (isAdding) {
      if (activeStep === 0) {
        if (
          !addingStudent.firstname ||
          !addingStudent.lastname ||
          !addingStudent.gender ||
          !addingStudent.dob ||
          !addingStudent.number
        ) {
          handleOpenRequiredFieldsPopUp();
        } else {
          setStep(param);
        }
      } else if (activeStep === 1) {
        setStep(param);
      } else if (activeStep === 2) {
        setStep(param);
      } else if (activeStep === 3) {
        setStep(param);
      }
    } else {
      setStep(param);
    }
  };

  function stringToColor(string: string) {
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    return color;
  }

  function stringAvatar(name: string) {
    return {
      sx: {
        bgcolor: stringToColor(name),
        width: 100,
        height: 100,
        marginRight: 0,
        marginTop: -1,
        fontSize: 35,
      },
      children: `${name.split(" ")[0][0] || ""}${name.split(" ")[1][0] || ""}`,
    };
  }

  function handleNext() {
    let listSize = list.length;

    let index = list.findIndex((object) => {
      return object.id === selectedStudent.id;
    });

    if (index + 1 === listSize) {
      setStudent(list[0]);
    } else {
      setStudent(list[index + 1]);
    }
  }

  function handleBack() {
    let listSize = list.length;

    let index = list.findIndex((object) => {
      return object.id === selectedStudent.id;
    });

    if (index === 0) {
      setStudent(list[listSize - 1]);
    } else {
      setStudent(list[index - 1]);
    }
  }

  function teacherName() {
    let index = staff.findIndex((object) => {
      return object.id === selectedStudent.teacher;
    });
    if (staff[index]?.firstname) {
      return staff[index]?.firstname + " " + staff[index]?.lastname;
    } else {
      return selectedStudent.teacher;
    }
  }

  countries.registerLocale(enLocale);
  const countriesObj = countries.getNames("en", { select: "alias" });

  const countriesArr = Object.entries(countriesObj).map(([key, value]) => {
    return {
      label: value,
      value: value,
      id: key,
    };
  });

  const handleParentChange = (guardians: Guardian[]) => {
    editingStudent.guardians = guardians;
  };

  const handleEnrolmentChange = (enrolments: Enrolment[]) => {
    editingStudent.enrolments = enrolments;
  };

  const addHandleParentChange = (guardians: Guardian[]) => {
    addingStudent.guardians = guardians;
  };

  function saveBulkEdit() {
    handleOpenLoadDialog();
    const requestOptions = {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(bulkList),
    };
    fetch("https://devschool.kozosms.com/api/api/students/", requestOptions)
      .then((response) => response.json())
      .then((_emps) => {
        return getAllStudents();
      })
      .then((students) => {
        setStudentFilter("ACTIVE");
        let newStudents = [...student];
        if (studentFilter === "ALL") {
          newStudents = students;
        } else if (studentFilter === "ACTIVE") {
          newStudents = newStudents.filter(
            (emp) =>
              emp.grade !== "Alumni" &&
              emp.transferredout !== "Yes" &&
              emp.droppedout !== "Yes"
          );
        } else if (studentFilter === "INACTIVE") {
          newStudents = newStudents.filter(
            (emp) => emp.transferredout === "Yes" || emp.droppedout === "Yes"
          );
        } else if (studentFilter === "ALUMNI") {
          newStudents = newStudents.filter((emp) => emp.grade === "Alumni");
        }

        setList(newStudents);

        // setList(students);
        closeLoadDialog();
        setSnackbarOpen(true);
        setIsBulkEditing(false);
        setSearch("");
        setOriginalList(students);
      });
  }

  function selectStudentFromBulkEdit(index: number) {
    if (isBulkEditing) {
      if (isViewingTable) {
        setIsViewingTable(false);
        setIsBulkEditing(false);
      } else {
        setIsBulkEditing(false);
        setCancelBulkPopUp(false);
      }
    } else {
      setIsBulkEditing(true);
    }
    const newList = [
      ...filterByStatus(studentsList, options[selectedFilterIndex]),
    ];
    setStudent(newList[index]);
  }

  function bulkEdit() {
    if (isBulkEditing) {
      if (isViewingTable) {
        setIsViewingTable(false);
        setIsBulkEditing(false);
      } else {
        setIsBulkEditing(false);
        setCancelBulkPopUp(false);
      }
    } else {
      setIsBulkEditing(true);
    }
  }

  function tableView() {
    setIsViewingTable(true);
    setIsBulkEditing(true);
  }

  function editStudent() {
    if (isEditing) {
      getAllStudents().then((students) => {
        let index = list.findIndex((object) => {
          return object.id === selectedStudent.id;
        });
        setList(students);
        setOriginalList(students);
        setStudent(students[index]);
        setIsEditing(false);
        setCancelEditPopUp(false);
      });
    } else {
      setEditingStudent(selectedStudent);
      setIsEditing(true);
    }
  }

  function addStudent() {
    if (isAdding) {
      setAddingStudent({} as Student);
      setIsAdding(false);
      setCancelAddPopUp(false);
    } else {
      addingStudent.nationality = "Zambia";
      setIsAdding(true);
    }
    setStep(0);
  }

  function today() {
    let date = new Date();
    return (
      date.getFullYear() +
      "-" +
      ("0" + (new Date().getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + (new Date().getDate() + 1)).slice(-2)
    ).toString();
  }

  let submitForm = (event: any) => {
    console.log("got ")
    event.preventDefault();
    handleOpenLoadDialog();
    editingStudent.updatedAt = new Date(Date.now()).toISOString();
    editingStudent.updatedBy = userName;
    console.log("got 2")

    const requestOptions = {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(editingStudent),
    };

    try {
      fetch(
        "https://devschool.kozosms.com/api/api/students/" + editingStudent.id,
        requestOptions
      )
        .then((response) => {
          console.log("ddd", response)
          return response.json()
        })
        .then((_emps) => {
          console.log("got all")
          return getAllStudents();
        })
        .then((students) => {
          console.log("got all done")
          setUpdateParentPage(!updateParentPage);
          setSearch("");
          setList(students);
          setOriginalList(students);
          closeLoadDialog();
          setSnackbarOpen(true);
          setIsEditing(false);
        }).catch(error => {
          console.error("Error during fetch operation:", error);
          closeLoadDialog();
        });
    } catch (e) {

      closeLoadDialog();
      console.log(e)
    }
  };

  function generateStudentID(index: number) {
    let currentDate = new Date();
    let year = currentDate.getFullYear();

    if (currentDate.getMonth() === 11) {
      year++;
    }

    let yearString = year.toString().slice(-2);

    return "101-" + yearString + "-" + index.toString().padStart(4, "0");
  }

  let addSubmitForm = (event: any) => {
    event.preventDefault();
    handleOpenLoadDialog();

    getAllStudents().then((students) => {
      addingStudent.id = uuidv4();

      var largest = 0;

      for (var i = 0; i < students.length; i++) {
        if (
          students[i].studentid.substr(students[i].studentid.length - 4) >
          largest
        ) {
          largest = students[i].studentid.substr(
            students[i].studentid.length - 4
          );
        }
      }

      addingStudent.studentid = generateStudentID(parseInt(largest) + 1);
      addingStudent.createdAt = new Date(Date.now()).toISOString();
      addingStudent.updatedAt = new Date(Date.now()).toISOString();
      addingStudent.updatedBy = userName;

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(addingStudent),
      };

      fetch("https://devschool.kozosms.com/api/api/students/", requestOptions)
        .then((_emps) => {
          return getAllStudents();
        })
        .then((students) => {
          setUpdateParentPage(!updateParentPage);
          setList(students);
          closeLoadDialog();
          setSnackbarOpen(true);
          setStudent(addingStudent);
          setAddingStudent({} as Student);
          setIsAdding(false);
          setSearch("");
          setOriginalList(students);
        });
    });
  };

  const handleCloseSnackbar = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const actionSnackbar = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSnackbar}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  let updateInput = (event: any) => {
    editingStudent[`${[event.target.name]}`] = event.target.value;
  };

  let updateAddInput = (event: any) => {
    addingStudent[`${[event.target.name]}`] = event.target.value;
  };

  const [CancelEditPopUp, setCancelEditPopUp] = React.useState(false);
  const handleCancelEdit = () => {
    setCancelEditPopUp(false);
  };
  const handleOpenEdit = () => {
    setCancelEditPopUp(true);
  };
  const [CancelAddPopUp, setCancelAddPopUp] = React.useState(false);
  const handleCancelAdd = () => {
    setCancelAddPopUp(false);
  };
  const handleOpenAdd = () => {
    setCancelAddPopUp(true);
  };
  const [CancelBulkPopUp, setCancelBulkPopUp] = React.useState(false);
  const handleCancelBulk = () => {
    setCancelBulkPopUp(false);
  };
  const handleOpenBulk = () => {
    setCancelBulkPopUp(true);
  };
  const [deletePopUp, setDeletePopUp] = React.useState(false);
  const handleCloseDelete = () => {
    setDeletePopUp(false);
  };
  const handleOpenDelete = () => {
    let canDeleteStudent = true;
    if (selectedStudent.guardians.length > 0) {
      canDeleteStudent = false;
      handleOpencantdeletePopUp();
    } else {
      getAllGuardians().then((parents) => {
        for (let i = 0; i < parents.length; i++) {
          if (parents[i].registration) {
            for (let j = 0; j < parents[i].registration.length; j++) {
              if (
                parents[i].registration[j].student_id === selectedStudent.id
              ) {
                handleOpencantdeletePopUp();
                canDeleteStudent = false;
              }
            }
          }
        }
        if (canDeleteStudent) {
          setDeletePopUp(true);
        }
      });
    }
  };
  const [requiredFieldsPopUp, setRequiredFieldsPopUp] = React.useState(false);
  const handleCloseRequiredFieldsPopUp = () => {
    setRequiredFieldsPopUp(false);
  };
  const handleOpenRequiredFieldsPopUp = () => {
    setRequiredFieldsPopUp(true);
  };
  const [openLoadDialog, setOpenLoadDialog] = React.useState(false);
  const closeLoadDialog = () => {
    setOpenLoadDialog(false);
  };
  const handleOpenLoadDialog = () => {
    setOpenLoadDialog(true);
  };
  const [cantdeletePopUp, setcantdeletePopUp] = React.useState(false);
  const handleClosecantdeletePopUp = () => {
    setcantdeletePopUp(false);
  };
  const handleOpencantdeletePopUp = () => {
    setcantdeletePopUp(true);
  };

  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLElement>,
    index: number
  ) => {
    setSelectedFilterIndex(index);
    setAnchorEl(null);
    newStudentFilter(options[index]);
  };

  const handleCloseFilterMenu = () => {
    setAnchorEl(null);
  };

  const newStudentFilter = (newFilter: string) => {
    setStudentFilter(newFilter);
    const getMostRecentStatus = (statuses) => {
      return statuses?.sort(
        (a, b) =>
          new Date(b.statusdate).getTime() - new Date(a.statusdate).getTime()
      )[0];
    };

    getAllStudents()
      .then((students) => {
        if (newFilter === "ALL") {
          return students;
        } else if (newFilter === "ALUMNI") {
          return (students = students.filter((emp) => emp.grade === "Alumni"));
        } else if (newFilter === "ACTIVE") {
          return students.filter(
            (emp) =>
              emp.grade !== "Alumni" &&
              emp.transferredout !== "Yes" &&
              emp.droppedout !== "Yes"
          );
        } else if (newFilter === "INACTIVE") {
          return students.filter(
            (emp) => emp.transferredout === "Yes" || emp.droppedout === "Yes"
          );
        }
      })
      .then((students) => {
        setList(filterStudents(students, search));
      });
  };

  function handleOpenNewPayment() {
    const payment = {
      id: uuidv4(), // Ensure this is unique
      amount: 0,
      status: 'Pending',
      type: '',
      discount: '',
      paymentproof: '',
      createdAt: new Date()
    };
    setNewPayment(payment)
    setMakePayment(true)
    console.log(makePayment)
  }

  function handleCloseNewPayment() {
    const payment = {
      id: uuidv4(), // Ensure this is unique
      amount: 0,
      status: 'Pending',
      type: '',
      discount: '',
      paymentproof: '',
      createdAt: new Date()
    };
    setNewPayment(null)
    setMakePayment(false)
    console.log(makePayment)
  }

  function getSumOfPayments(enrolment: Enrolment) {
    return enrolment?.payments?.reduce(
      (accumulator: number, e: EnrolmentPayment) => {
        return accumulator + Number(e.amount);
      },
      0
    );
  }

  function handleSavePayment(updatedPayment) {
    console.log("Updated payment:", updatedPayment);

    let index = findEnrolmentIndex(selectedStudent)
    // setEnrolmentIndex(index)

    // Ensure there is an enrolment to update
    const enrolmentToUpdate = selectedStudent?.enrolments[index];
    if (!enrolmentToUpdate) {
      console.error("No enrolment to update!");
      return;
    }

    console.log("enrolmentToUpdate payment:", enrolmentToUpdate);
    // Check if the payment exists and update it if so
    let found = false;
    const updatedPayments = enrolmentToUpdate.payments.map(p => {
      if (p.id === updatedPayment.id) {
        found = true;
        return updatedPayment; // Update the existing payment
      }
      return p; // Return the original payment
    });

    // If the payment doesn't exist, append it to the end of the array
    if (!found) {
      updatedPayments.push(updatedPayment);
    }

    // Update the payments on the enrolment
    enrolmentToUpdate.payments = updatedPayments;

    console.log("Updated enrolment:", enrolmentToUpdate);

    selectedStudent.enrolments[index] = enrolmentToUpdate;

    console.log("Updated student:", selectedStudent);


    // console.log("got ")
    // event.preventDefault();
    handleOpenLoadDialog();
    selectedStudent.updatedAt = new Date(Date.now()).toISOString();
    selectedStudent.updatedBy = userName;
    console.log("got 2")

    const requestOptions = {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(selectedStudent),
    };

    try {
      fetch(
        "https://devschool.kozosms.com/api/api/students/" + selectedStudent.id,
        requestOptions
      )
        .then((response) => {
          console.log("ddd", response)
          return response.json()
        })
        .then((_emps) => {
          console.log("got all")
          return getAllStudents();
        })
        .then((students) => {
          // If successful, find the updated student in the main array and update it
          const studentIndex = students.findIndex(s => s.id === selectedStudent.id);
          if (studentIndex > -1) {
            students[studentIndex] = selectedStudent;
          }
          console.log("got all done")
          console.log(selectedStudent)
          console.log(studentIndex)
          console.log(students[studentIndex])
          setUpdateParentPage(!updateParentPage);
          setSearch("");
          setList(students);
          setOriginalList(students);
          closeLoadDialog();
          setSnackbarOpen(true);
          setIsEditing(false);
          setStudent(students[studentIndex])
          console.log(activeStep)
          let step = activeStep
          if (activeStep === 1) {
            setStep(0);
          }
          //  else {
          //   setStep(1);
          //   setStep(step);
          // }

        }).catch(error => {
          console.error("Error during fetch operation:", error);
          closeLoadDialog();
        });
    } catch (e) {

      closeLoadDialog();
      console.log(e)
    }
  }

  let currentYear = getCurrentSchoolYear()

  const paymentUpdateHandler = (
    payments,
    enrolment_id
  ) => {
    const enrolmentToUpdate = enrolments.find(
      (e) => e.id === enrolment_id
    );
    if (enrolmentToUpdate && enrolmentToUpdate?.payments) {
      enrolmentToUpdate.payments = payments;
    }
    updateEnrolment(enrolmentToUpdate);
  };

  function getCurrentSchoolYear() {
    const date = new Date();
    let year = date.getFullYear();

    if (date.getMonth() === 11) {
      // Month in JavaScript is 0-indexed, so 11 is December
      year += 1;
    }
    return year.toString();
  }

  function updateEnrolment(enrolmentToUpdate) {
    console.log(enrolmentToUpdate);
    let indexToBeUpdated = -1;
    if (enrolmentToUpdate) {
      const discountAmount =
        (enrolmentToUpdate.totalAmount * parseInt(enrolmentToUpdate.discount)) /
        100 || 0;
      const sum = this.getSumOfPayments(enrolmentToUpdate) + discountAmount;
      enrolmentToUpdate.status = getEnrolmentStatus(
        sum,
        enrolmentToUpdate.totalAmount
      );
      let newEnrolments = [...this.state.enrolments];
      newEnrolments.map((e, index) => {
        if (e.id === enrolmentToUpdate.id) {
          indexToBeUpdated = index;
        }
      });
      if (indexToBeUpdated >= 0) {
        newEnrolments[indexToBeUpdated] = enrolmentToUpdate;
        this.setState((oldState, props) => ({
          ...oldState,
          enrolments: newEnrolments,
        }));
        onEnrolmentsUpdate(newEnrolments);
      }
    }
  }

  function getInvoiceData(selectedStudent: any) {
    if (selectedStudent?.enrolments?.length > 0) {

      console.log("enrolmentIndex", enrolmentIndex)

      let index = findEnrolmentIndex(selectedStudent)
      console.log("index", index)
      if (index !== null) {
        const currentDate = new Date().toISOString().slice(0, 10);
        const createdDate = new Date(selectedStudent?.enrolments[index]?.createdAt).toISOString().slice(0, 10);
        return {
          student: selectedStudent,
          id: selectedStudent?.enrolments[index]?.id,
          invoice_no: currentDate.replace(/-/g, "") + selectedStudent?.enrolments[index]?.id.slice(0, 4),
          company:
            selectedStudent.guardians[0]?.firstname +
            " " +
            selectedStudent.guardians[0]?.lastname,
          email: selectedStudent.guardians[0]?.email,
          phone: selectedStudent.guardians[0]?.phone,
          address: selectedStudent.guardians[0]?.address,
          trans_date: createdDate,
          items: [
            {
              item_num: 0,
              desc: "Enrolment fee for " + selectedStudent.grade,
              qty: 1,
              rate: selectedStudent?.enrolments[index]?.totalAmount,
              discount: selectedStudent?.enrolments[index]?.discount,
            },
          ],
        }
      };
    }
  }

  function getReceiptData(selectedStudent: any) {
    if (selectedStudent?.enrolments?.length > 0) {
      let index = findEnrolmentIndex(selectedStudent)

      if (index !== null) {
        const currentDate = new Date().toISOString().slice(0, 10);
        const createdDate = new Date(selectedStudent?.enrolments[index]?.createdAt).toISOString().slice(0, 10);
        return {
          student: selectedStudent,
          id: selectedStudent?.enrolments[index]?.id,
          invoice_no: currentDate.replace(/-/g, "") + selectedStudent?.enrolments[index]?.id.slice(0, 4),
          company:
            selectedStudent.guardians[0]?.firstname +
            " " +
            selectedStudent.guardians[0]?.lastname,
          email: selectedStudent.guardians[0]?.email,
          phone: selectedStudent.guardians[0]?.phone,
          address: selectedStudent.guardians[0]?.address,
          trans_date: createdDate,
          items: [
            {
              item_num: 0,
              desc: "Enrolment fee for " + selectedStudent.grade,
              qty: 1,
              rate: selectedStudent?.enrolments[index]?.totalAmount,
              discount: selectedStudent?.enrolments[index]?.discount,
            },
          ],
          payments: selectedStudent?.enrolments[index]?.payments,
        };
      }
    }
  }

  return (
    <Grid
      item
      container
      className="listbackground"
      direction="column"
      justifyContent="space-between"
      alignItems="stretch"
      rowSpacing={2}
      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
    >
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
        message="Student record has been saved."
        action={actionSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          sx={{ width: "100%" }}
        >
          Student record has been saved.
        </Alert>
      </Snackbar>
      <Dialog
        open={cantdeletePopUp}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClosecantdeletePopUp}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          {"Can not delete student because it is linked to a parent"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Unlink student in the parent section before deleting a student.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosecantdeletePopUp}>Ok</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openLoadDialog} keepMounted>
        <DialogContent>
          <CircularProgress></CircularProgress>
        </DialogContent>
      </Dialog>
      <Dialog
        open={CancelEditPopUp}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCancelEdit}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Do you want to cancel the changes?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            If you click Yes, all your changes will be lost. Click No to
            continue with your modifications.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelEdit}>No</Button>
          <Button onClick={editStudent}>Yes</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={CancelAddPopUp}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCancelAdd}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Do you want to cancel the changes?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            If you click Yes, all your changes will be lost. Click No to
            continue with your modifications.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelAdd}>No</Button>
          <Button onClick={addStudent}>Yes</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={CancelBulkPopUp}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCancelBulk}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Do you want to cancel the changes?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            If you click Yes, all your changes will be lost. Click No to
            continue with your modifications.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelBulk}>No</Button>
          <Button onClick={bulkEdit}>Yes</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={deletePopUp}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseDelete}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Are you sure you want to delete?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            If you click Yes, the record will be removed. Click No to cancel.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDelete}>No</Button>
          <Button onClick={() => deleteHandler(selectedStudent.id)}>Yes</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={requiredFieldsPopUp}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseRequiredFieldsPopUp}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Fill all required fields"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Required fields will appear with a *.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseRequiredFieldsPopUp}>Ok</Button>
        </DialogActions>
      </Dialog>
      <Grid container item xs={6} mb={2} alignItems="left">
        {isEditing || isAdding || isBulkEditing ? (
          <div
            style={{
              height: 25,
            }}
          ></div>
        ) : (
          <>
            {AddStudentButton(addStudentClicked)}
            <Button
              style={{
                marginLeft: 10,
                marginRight: 10,
              }}
              startIcon={<TableRowsIcon />}
              variant="contained"
              color="primary"
              size="small"
              onClick={() => tableView()}
            >
              Table View
            </Button>
            <Button
              style={{
                marginRight: 10,
              }}
              startIcon={<EditIcon />}
              variant="contained"
              color="primary"
              size="small"
              onClick={() => bulkEdit()}
            >
              Bulk Edit
            </Button>
          </>
        )}
        {isBulkEditing ? (
          <>
            {isViewingTable ? (
              <>
                <Button
                  startIcon={<ChevronLeftIcon />}
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => bulkEdit()}
                >
                  Back
                </Button>
              </>
            ) : (
              <>
                <Button
                  style={{
                    marginRight: 10,
                  }}
                  startIcon={<SaveAsIcon />}
                  variant="contained"
                  color="success"
                  form="bulk-edit-employee"
                  size="small"
                  onClick={() => saveBulkEdit()}
                >
                  Save Changes
                </Button>
                <Button
                  startIcon={<CancelIcon />}
                  variant="contained"
                  color="warning"
                  size="small"
                  onClick={handleOpenBulk}
                >
                  Cancel
                </Button>
              </>
            )}
          </>
        ) : (
          <></>
        )}
      </Grid>
      <Grid className="row">
        {isBulkEditing ? (
          <div>
            <Grid
              item
              container
              direction="row"
              justifyContent="start"
              alignItems="center"
              columnSpacing={0}
            >
              <Grid
                item
                style={{
                  width: "calc(30% - 60px)",
                }}
                paddingLeft={0}
              >
                {SearchBar(setSearch, search)}
              </Grid>
              <Grid
                item
                style={{
                  width: "60px",
                }}
                className="filterbutton"
              >
                {StudentFilter(
                  handleClickListItem,
                  selectedFilterIndex,
                  anchorEl,
                  open,
                  handleCloseFilterMenu,
                  handleMenuItemClick
                )}
              </Grid>
              <Grid
                item
                xs={6}
                style={{
                  width: "70%",
                }}
              >
                <div></div>
              </Grid>
            </Grid>
            {BulkStudentTable(
              selectStudentFromBulkEdit,
              setBulkList,
              deleteHandler,
              list,
              staff,
              isViewingTable,
              loadingGrid,
              userName
            )}
          </div>
        ) : (
          <></>
        )}
        <Grid item className="col" xs={3}>
          {isEditing || isAdding || isBulkEditing ? (
            <div></div>
          ) : (
            <div>
              <Grid
                item
                container
                direction="row"
                justifyContent="start"
                alignItems="center"
                columnSpacing={0}
              >
                <Grid
                  item
                  style={{
                    width: "calc(100% - 67px)",
                  }}
                  paddingLeft={0}
                >
                  {SearchBar(setSearch, search)}
                </Grid>
                <Grid
                  item
                  style={{
                    width: "60px",
                  }}
                  className="filterbutton"
                >
                  {StudentFilter(
                    handleClickListItem,
                    selectedFilterIndex,
                    anchorEl,
                    open,
                    handleCloseFilterMenu,
                    handleMenuItemClick
                  )}
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    width: "70%",
                  }}
                >
                  <div></div>
                </Grid>
              </Grid>
              <Grid item>
                <Paper>
                  {StudentTable(
                    navigate,
                    deleteHandler,
                    list,
                    setStudent,
                    loadingGrid
                  )}
                </Paper>
              </Grid>
            </div>
          )}
        </Grid>
        <Grid className="col pagebottom" item>
          {selectedStudent?.id || isAdding || isBulkEditing ? (
            <section className="left  view-contact">
              {isBulkEditing ? (
                <div></div>
              ) : (
                <div>
                  {isAdding ? (
                    <div>
                      <form id="add-student" onSubmit={addSubmitForm}>
                        <CardWrapper className="row  viewcardmain">
                          {/* <pre>{JSON.stringify(selectedStudent)}</pre>
                        <pre>{JSON.stringify(list)}</pre> */}
                          <div className="row">
                            <div className="col">
                              <Button
                                className="skipbtn"
                                style={{
                                  display: "none",
                                  float: "center",
                                  objectFit: "cover",
                                  objectPosition: "100%",
                                  width: 45,
                                  minWidth: 45,
                                  height: 45,
                                  minHeight: 45,
                                  marginLeft: 0,
                                  marginTop: 15,
                                  marginBottom: -10,
                                  boxShadow:
                                    "2px 4px 4px 2px rgba(0, 0, 0, 0.2)",
                                  zIndex: 15,
                                  backgroundColor: "#1565C0",
                                  color: "#fff",
                                  borderRadius: "50%",
                                }}
                              >
                                <ArrowBackIosNewIcon />
                              </Button>
                            </div>
                            <div className="col"></div>
                            <div className="col">
                              <div className="row"></div>
                              <div className="row"></div>
                            </div>
                            <div className="col">
                              <div
                                className="mb-2"
                                style={{
                                  width: 140,
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "right",
                                  justifyContent: "right",
                                  marginTop: 19,
                                }}
                              >
                                <Button
                                  startIcon={<SaveAsIcon />}
                                  variant="contained"
                                  color="success"
                                  form="add-student"
                                  size="small"
                                  type="submit"
                                >
                                  Add Student
                                </Button>
                              </div>
                              <div
                                className="mb-2"
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "right",
                                  justifyContent: "right",
                                }}
                              >
                                <Button
                                  startIcon={<CancelIcon />}
                                  variant="contained"
                                  color="warning"
                                  size="small"
                                  onClick={handleOpenAdd}
                                >
                                  Cancel
                                </Button>
                              </div>
                            </div>
                            <div className="col">
                              <Button
                                className="skipbtn"
                                style={{
                                  display: "none",
                                  float: "right",
                                  objectFit: "cover",
                                  objectPosition: "100%",
                                  width: 15,
                                  minWidth: 15,
                                  height: 45,
                                  minHeight: 45,
                                  marginLeft: 0,
                                  marginRight: 0,
                                  marginTop: 15,
                                  marginBottom: -10,
                                  boxShadow:
                                    "2px 4px 4px 2px rgba(0, 0, 0, 0.2)",
                                  zIndex: 15,
                                  backgroundColor: "#1565C0",
                                  color: "#fff",
                                  borderRadius: "50%",
                                }}
                              >
                                <ArrowForwardIosIcon />
                              </Button>
                            </div>
                          </div>
                        </CardWrapper>

                        <div className="viewcardbody">
                          <Stepper
                            alternativeLabel
                            activeStep={activeStep}
                            style={{ marginBottom: "5px" }}
                          >
                            {steps.map((label, index) => {
                              const stepProps: { completed?: boolean } = {};
                              const labelProps: {
                                optional?: React.ReactNode;
                              } = {};
                              return (
                                <Step key={label} {...stepProps}>
                                  <Button
                                    onClick={(event) =>
                                      handleProgress(event, index)
                                    }
                                  >
                                    <StepLabel
                                      key={label}
                                      StepIconComponent={ColorlibStepIcon}
                                      {...labelProps}
                                    >
                                      <div className="steplabel">{label}</div>
                                    </StepLabel>
                                  </Button>
                                </Step>
                              );
                            })}
                          </Stepper>

                          {activeStep === 0 && (
                            <div>
                              <div className="row input-heading-row">
                                <Typography
                                  style={{
                                    paddingLeft: 0,
                                    marginTop: "10px",
                                    fontSize: 16,
                                  }}
                                >
                                  Personal Information
                                </Typography>
                              </div>

                              <div
                                className="row"
                                style={{ marginTop: "18px" }}
                              >
                                <div className="col">
                                  <TextField
                                    size="small"
                                    required
                                    style={{
                                      marginTop: 5,
                                      display: "flex",
                                      minWidth: 100,
                                      flex: 1,
                                      marginRight: 9,
                                    }}
                                    label="First Name"
                                    name="firstname"
                                    onChange={updateAddInput}
                                    defaultValue={addingStudent.firstname}
                                  />
                                </div>
                                <div className="col">
                                  <TextField
                                    size="small"
                                    style={{
                                      marginTop: 5,
                                      display: "flex",
                                      minWidth: 100,
                                      flex: 1,
                                      marginRight: 9,
                                    }}
                                    label="Middle Name"
                                    name="middlename"
                                    onChange={updateAddInput}
                                    defaultValue={addingStudent.middlename}
                                  />
                                </div>
                                <div className="col">
                                  <TextField
                                    size="small"
                                    required
                                    style={{
                                      marginTop: 5,
                                      display: "flex",
                                      minWidth: 100,
                                      flex: 1,
                                      marginRight: 9,
                                    }}
                                    label="Last Name"
                                    name="lastname"
                                    onChange={updateAddInput}
                                    defaultValue={addingStudent.lastname}
                                  />
                                </div>
                                <div className="col">
                                  <FormControl
                                    required
                                    size="small"
                                    style={{
                                      marginTop: 5,
                                      display: "flex",
                                      minWidth: 100,
                                      flex: 1,
                                      marginRight: 9,
                                    }}
                                  >
                                    <InputLabel id="gender-label">
                                      Gender
                                    </InputLabel>
                                    <Select
                                      required
                                      name="gender"
                                      labelId="gender-label"
                                      id="gender"
                                      defaultValue={addingStudent.gender}
                                      onChange={updateAddInput}
                                      label="Gender"
                                    >
                                      <MenuItem value={"Male"}>Male</MenuItem>
                                      <MenuItem value={"Female"}>
                                        Female
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </div>
                              </div>

                              <div
                                className="row"
                                style={{ marginTop: "16px" }}
                              >
                                <div className="col">
                                  <TextField
                                    required
                                    size="small"
                                    style={{
                                      marginTop: 5,
                                      display: "flex",
                                      minWidth: 100,
                                      flex: 1,
                                      marginRight: 9,
                                    }}
                                    inputProps={{
                                      min: "1920-01-24",
                                      max: today(),
                                    }}
                                    type="date"
                                    label="Date of Birth"
                                    name="dob"
                                    onChange={updateAddInput}
                                    defaultValue={addingStudent.dob}
                                    InputLabelProps={{ shrink: true }}
                                  />
                                </div>
                                <div className="col">
                                  <TextField
                                    size="small"
                                    style={{
                                      marginTop: 5,
                                      display: "flex",
                                      minWidth: 100,
                                      flex: 1,
                                      marginRight: 9,
                                    }}
                                    label="Language"
                                    name="language"
                                    onChange={updateAddInput}
                                    defaultValue={addingStudent.language}
                                  />
                                </div>
                                <div className="col">
                                  <NumberFormat
                                    size="small"
                                    style={{
                                      marginTop: 5,
                                      display: "flex",
                                      minWidth: 100,
                                      flex: 1,
                                      marginRight: 9,
                                    }}
                                    label="Phone Number"
                                    className="form-control "
                                    value={addingStudent.number}
                                    name="number"
                                    onChange={updateAddInput}
                                    customInput={TextField}
                                    format="+260 ## ####-###"
                                    required={true}
                                  />
                                </div>
                                <div className="col">
                                  <TextField
                                    size="small"
                                    style={{
                                      marginTop: 5,
                                      display: "flex",
                                      minWidth: 100,
                                      flex: 1,
                                      marginRight: 9,
                                    }}
                                    type="email"
                                    label="Email"
                                    name="email"
                                    defaultValue={addingStudent.email}
                                    onChange={updateAddInput}
                                  />
                                </div>
                              </div>

                              <div
                                className="row"
                                style={{ marginTop: "16px" }}
                              >
                                <div className="col">
                                  <FormControl
                                    size="small"
                                    style={{
                                      marginTop: 5,
                                      display: "flex",
                                      minWidth: 100,
                                      flex: 1,
                                      marginRight: 9,
                                    }}
                                  >
                                    <InputLabel id="nationality-label">
                                      Nationality
                                    </InputLabel>
                                    <Select
                                      name="nationality"
                                      labelId="nationality-label"
                                      id="nationality"
                                      defaultValue={
                                        addingStudent.nationality || "Zambia"
                                      }
                                      onChange={updateAddInput}
                                      label="Nationality"
                                    >
                                      {!!countriesArr?.length &&
                                        countriesArr.map((countryObj) => (
                                          <MenuItem
                                            key={countryObj.value}
                                            value={countryObj.value}
                                          >
                                            {countryObj.label}{" "}
                                            <ReactCountryFlag
                                              style={{ marginLeft: 4 }}
                                              countryCode={countryObj.id}
                                              svg
                                            />
                                          </MenuItem>
                                        ))}
                                    </Select>
                                  </FormControl>
                                </div>
                                <div className="col">
                                  <TextField
                                    size="small"
                                    style={{
                                      marginTop: 5,
                                      display: "flex",
                                      minWidth: 100,
                                      flex: 1,
                                      marginRight: 9,
                                    }}
                                    type="text"
                                    label="Address"
                                    name="address"
                                    defaultValue={addingStudent.address}
                                    onChange={updateAddInput}
                                  />
                                </div>
                                <div className="col">
                                  <TextField
                                    size="small"
                                    style={{
                                      marginTop: 5,
                                      display: "flex",
                                      minWidth: 100,
                                      flex: 1,
                                      marginRight: 9,
                                    }}
                                    type="text"
                                    label="Town"
                                    name="town"
                                    defaultValue={addingStudent.town}
                                    onChange={updateAddInput}
                                    required
                                  />
                                </div>
                                <div className="col">
                                  <FormControl
                                    size="small"
                                    style={{
                                      marginTop: 5,
                                      display: "flex",
                                      minWidth: 100,
                                      flex: 1,
                                      marginRight: 9,
                                    }}
                                  >
                                    <InputLabel id="gender-label">
                                      Province
                                    </InputLabel>
                                    <Select
                                      name="province"
                                      labelId="province-label"
                                      id="province"
                                      defaultValue={addingStudent.province}
                                      onChange={updateAddInput}
                                      label="Province"
                                    >
                                      <MenuItem value={"Central"}>
                                        Central
                                      </MenuItem>
                                      <MenuItem value={"Copperbelt"}>
                                        Copperbelt
                                      </MenuItem>
                                      <MenuItem value={"Eastern"}>
                                        Eastern
                                      </MenuItem>
                                      <MenuItem value={"Luapula"}>
                                        Luapula
                                      </MenuItem>
                                      <MenuItem value={"Lusaka"}>
                                        Lusaka
                                      </MenuItem>
                                      <MenuItem value={"Muchinga"}>
                                        Muchinga
                                      </MenuItem>
                                      <MenuItem value={"Northern"}>
                                        Northern
                                      </MenuItem>
                                      <MenuItem value={"North Western"}>
                                        North Western
                                      </MenuItem>
                                      <MenuItem value={"Southern"}>
                                        Southern
                                      </MenuItem>
                                      <MenuItem value={"Western"}>
                                        Western
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </div>
                              </div>
                            </div>
                          )}

                          {activeStep === 1 && (
                            <div>
                              <div className="row input-heading-row">
                                <Typography
                                  style={{
                                    paddingLeft: 0,
                                    marginTop: "10px",
                                    fontSize: 16,
                                  }}
                                >
                                  Parent(s) / Guardian(s) Information
                                </Typography>
                              </div>

                              <div
                                className="row"
                                style={{ marginTop: "16px" }}
                              >
                                <div className="col">
                                  <FormControl
                                    size="small"
                                    style={{
                                      marginTop: 5,
                                      display: "flex",
                                      minWidth: 100,
                                      flex: 1,
                                      marginRight: 9,
                                    }}
                                  >
                                    <InputLabel id="gender-label" shrink={true}>
                                      Parent(s) are
                                    </InputLabel>
                                    <Select
                                      style={{
                                        marginTop: 2,
                                      }}
                                      name="parentsguardiansrelationship"
                                      labelId="parentsguardiansrelationship-label"
                                      id="parentsguardiansrelationship"
                                      defaultValue={
                                        addingStudent[
                                        `parentsguardiansrelationship`
                                        ]
                                      }
                                      onChange={updateAddInput}
                                      label="parentsguardiansrelationship"
                                    >
                                      <MenuItem value={"Married"}>
                                        Married
                                      </MenuItem>
                                      <MenuItem value={"Single"}>
                                        Single
                                      </MenuItem>
                                      <MenuItem value={"Separated"}>
                                        Separated
                                      </MenuItem>
                                      <MenuItem value={"Divorced"}>
                                        Divorced
                                      </MenuItem>
                                      <MenuItem value={"Widowed"}>
                                        Widowed
                                      </MenuItem>
                                      <MenuItem value={"Common-Law"}>
                                        Common-Law
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </div>
                                <div className="col">
                                  <FormControl
                                    size="small"
                                    style={{
                                      marginTop: 5,
                                      display: "flex",
                                      minWidth: 100,
                                      flex: 1,
                                      marginRight: 9,
                                    }}
                                  >
                                    <InputLabel id="gender-label">
                                      Custody
                                    </InputLabel>
                                    <Select
                                      style={{
                                        marginTop: 2,
                                      }}
                                      name="custody"
                                      labelId="custody-label"
                                      id="custody"
                                      defaultValue={addingStudent.custody}
                                      onChange={updateAddInput}
                                      label="custody"
                                    >
                                      <MenuItem value={"Joint"}>Joint</MenuItem>
                                      <MenuItem value={"Sole"}>Sole</MenuItem>
                                    </Select>
                                  </FormControl>
                                </div>
                                <div className="col">
                                  <FormControl
                                    size="small"
                                    style={{
                                      marginTop: 5,
                                      display: "flex",
                                      minWidth: 100,
                                      flex: 1,
                                      marginRight: 9,
                                    }}
                                  >
                                    <InputLabel id="gender-label">
                                      Student lives with
                                    </InputLabel>
                                    <Select
                                      style={{
                                        marginTop: 2,
                                      }}
                                      name="studentliveswith"
                                      labelId="studentliveswith-label"
                                      id="studentliveswith"
                                      defaultValue={
                                        addingStudent.studentliveswith
                                      }
                                      onChange={updateAddInput}
                                      label="studentliveswith"
                                    >
                                      <MenuItem value={"Both Parents"}>
                                        Both Parents
                                      </MenuItem>
                                      <MenuItem value={"Father"}>
                                        Father
                                      </MenuItem>
                                      <MenuItem value={"Mother"}>
                                        Mother
                                      </MenuItem>
                                      <MenuItem value={"Guardian"}>
                                        Guardian
                                      </MenuItem>
                                      <MenuItem value={"Other"}>Other</MenuItem>
                                    </Select>
                                  </FormControl>
                                </div>
                                <div className="col"></div>
                              </div>

                              <Divider
                                style={{ marginTop: 12, marginBottom: 24 }}
                              ></Divider>
                              <EditGuardian
                                onParentUpdate={addHandleParentChange}
                                parents={addingStudent.guardians ?? []}
                              />
                            </div>
                          )}

                          {activeStep === 2 && (
                            <div>
                              <div className="row input-heading-row">
                                <Typography
                                  style={{
                                    paddingLeft: 0,
                                    marginTop: "10px",
                                    fontSize: 16,
                                  }}
                                >
                                  School Details
                                </Typography>
                              </div>

                              <div
                                className="row"
                                style={{ marginTop: "16px" }}
                              >
                                <div className="col">
                                  <TextField
                                    size="small"
                                    style={{
                                      marginTop: 5,
                                      display: "flex",
                                      minWidth: 100,
                                      flex: 1,
                                      marginRight: 9,
                                    }}
                                    label="Class"
                                    name="class"
                                    onChange={updateAddInput}
                                    defaultValue={addingStudent.class}
                                  />
                                </div>
                                <div className="col">
                                  <FormControl
                                    size="small"
                                    style={{
                                      marginTop: 5,
                                      display: "flex",
                                      minWidth: 100,
                                      flex: 1,
                                      marginRight: 9,
                                    }}
                                  >
                                    <InputLabel id="gender-label">
                                      Grade
                                    </InputLabel>
                                    <Select
                                      name="grade"
                                      labelId="grade-label"
                                      id="grade"
                                      defaultValue={addingStudent.grade}
                                      onChange={updateAddInput}
                                      label="Grade"
                                    >
                                      <MenuItem value={"Baby Class"}>
                                        Baby Class
                                      </MenuItem>
                                      <MenuItem value={"Reception"}>
                                        Reception
                                      </MenuItem>
                                      <MenuItem value={"Grade 1"}>
                                        Grade 1
                                      </MenuItem>
                                      <MenuItem value={"Grade 2"}>
                                        Grade 2
                                      </MenuItem>
                                      <MenuItem value={"Grade 3"}>
                                        Grade 3
                                      </MenuItem>
                                      <MenuItem value={"Grade 4"}>
                                        Grade 4
                                      </MenuItem>
                                      <MenuItem value={"Grade 5"}>
                                        Grade 5
                                      </MenuItem>
                                      <MenuItem value={"Grade 6"}>
                                        Grade 6
                                      </MenuItem>
                                      <MenuItem value={"Grade 7"}>
                                        Grade 7
                                      </MenuItem>
                                      <MenuItem value={"Grade 8"}>
                                        Grade 8
                                      </MenuItem>
                                      <MenuItem value={"Grade 9"}>
                                        Grade 9
                                      </MenuItem>
                                      <MenuItem value={"Grade 10"}>
                                        Grade 10
                                      </MenuItem>
                                      <MenuItem value={"Grade 11"}>
                                        Grade 11
                                      </MenuItem>
                                      <MenuItem value={"Grade 12"}>
                                        Grade 12
                                      </MenuItem>
                                      <MenuItem value={"Alumni"}>
                                        Alumni
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </div>
                                <div className="col">
                                  <FormControl
                                    size="small"
                                    style={{
                                      marginTop: 5,
                                      display: "flex",
                                      minWidth: 100,
                                      flex: 1,
                                      marginRight: 9,
                                    }}
                                  >
                                    <InputLabel id="teacher-label">
                                      Teacher
                                    </InputLabel>
                                    <Select
                                      name="teacher"
                                      labelId="teacher-label"
                                      id="teacher"
                                      defaultValue={addingStudent.teacher}
                                      onChange={updateAddInput}
                                      label="Teacher"
                                    >
                                      {!!staff?.length &&
                                        staff.map((teacher) => (
                                          <MenuItem
                                            key={teacher.id}
                                            value={teacher.id}
                                          >
                                            {teacher.firstname +
                                              " " +
                                              teacher.lastname}
                                            <ReactCountryFlag
                                              style={{ marginLeft: 4 }}
                                              countryCode={teacher.id}
                                              svg
                                            />
                                          </MenuItem>
                                        ))}
                                    </Select>
                                  </FormControl>
                                </div>
                                <div className="col">
                                  <FormControl
                                    size="small"
                                    style={{
                                      marginTop: 5,
                                      display: "flex",
                                      minWidth: 100,
                                      flex: 1,
                                      marginRight: 9,
                                    }}
                                  >
                                    <InputLabel id="jobtitle-label">
                                      Campus
                                    </InputLabel>
                                    <Select
                                      labelId="campus-label"
                                      id="campus"
                                      name="campus"
                                      defaultValue={addingStudent.campus}
                                      onChange={updateAddInput}
                                      label="Campus"
                                    >
                                      <MenuItem value={"Main Campus"}>
                                        Main Campus
                                      </MenuItem>
                                      <MenuItem value={"Lubombo"}>
                                        Lubombo
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </div>
                              </div>

                              <div
                                className="row"
                                style={{
                                  marginTop: "16px",
                                  marginBottom: "10px",
                                }}
                              >
                                <div className="col"></div>
                                <div className="col">
                                  <FormControl
                                    size="small"
                                    style={{
                                      marginTop: 5,
                                      display: "flex",
                                      minWidth: 200,
                                      flex: 1,
                                      marginRight: 0,
                                    }}
                                  >
                                    <FormLabel
                                      id="health-radio-label"
                                      style={{
                                        fontSize: 10,
                                      }}
                                    >
                                      Was Student Transferred?
                                    </FormLabel>
                                    <RadioGroup
                                      row
                                      aria-labelledby="health-radio-label"
                                      name="transferredout"
                                      defaultValue={
                                        addingStudent.transferredout
                                      }
                                      onChange={updateAddInput}
                                    >
                                      <FormControlLabel
                                        style={{
                                          fontSize: 9,
                                        }}
                                        value="Yes"
                                        control={<Radio />}
                                        label="Yes"
                                      />
                                      <FormControlLabel
                                        style={{
                                          fontSize: 9,
                                        }}
                                        value="No"
                                        control={<Radio />}
                                        label="No"
                                      />
                                    </RadioGroup>
                                  </FormControl>
                                </div>
                                <div className="col">
                                  <FormControl
                                    size="small"
                                    style={{
                                      marginTop: 5,
                                      display: "flex",
                                      minWidth: 200,
                                      flex: 1,
                                      marginRight: 0,
                                    }}
                                  >
                                    <FormLabel
                                      id="health-radio-label"
                                      style={{
                                        fontSize: 10,
                                      }}
                                    >
                                      Did Student Dropout?
                                    </FormLabel>
                                    <RadioGroup
                                      row
                                      aria-labelledby="health-radio-label"
                                      name="droppedout"
                                      defaultValue={addingStudent.droppedout}
                                      onChange={updateAddInput}
                                    >
                                      <FormControlLabel
                                        style={{
                                          fontSize: 9,
                                        }}
                                        value="Yes"
                                        control={<Radio />}
                                        label="Yes"
                                      />
                                      <FormControlLabel
                                        style={{
                                          fontSize: 9,
                                        }}
                                        value="No"
                                        control={<Radio />}
                                        label="No"
                                      />
                                    </RadioGroup>
                                  </FormControl>
                                </div>
                                <div className="col"></div>
                                <div className="col"></div>
                              </div>

                              <div
                                className="row"
                                style={{
                                  marginTop: "16px",
                                  marginBottom: "10px",
                                }}
                              >
                                <div className="col">
                                  <TextField
                                    size="small"
                                    style={{
                                      marginTop: 5,
                                      display: "flex",
                                      minWidth: 100,
                                      flex: 1,
                                      marginRight: 9,
                                    }}
                                    type="text"
                                    className="form-control "
                                    label="Comment"
                                    name="leftstudentcomment"
                                    defaultValue={
                                      addingStudent.leftstudentcomment
                                    }
                                    onChange={updateAddInput}
                                  />
                                </div>
                              </div>

                              <div className="row input-heading-row">
                                <Typography
                                  style={{
                                    paddingLeft: 0,
                                    marginTop: "10px",
                                    fontSize: 16,
                                  }}
                                >
                                  School History
                                </Typography>
                              </div>

                              <div
                                className="row"
                                style={{ marginTop: "16px" }}
                              >
                                <div className="col">
                                  <TextField
                                    size="small"
                                    style={{
                                      marginTop: 5,
                                      display: "flex",
                                      minWidth: 100,
                                      flex: 1,
                                      marginRight: 9,
                                    }}
                                    label="Last School"
                                    name="lastschool"
                                    onChange={updateAddInput}
                                    defaultValue={addingStudent.lastschool}
                                  />
                                </div>
                                <div className="col"></div>
                                <div className="col"></div>
                                <div className="col"></div>
                              </div>

                              <div
                                className="row"
                                style={{ marginTop: "16px" }}
                              >
                                <div className="col">
                                  <TextField
                                    size="small"
                                    style={{
                                      marginTop: 5,
                                      display: "flex",
                                      minWidth: 100,
                                      flex: 1,
                                      marginRight: 9,
                                    }}
                                    type="text"
                                    label="Town"
                                    name="lastschoolcity"
                                    defaultValue={addingStudent.lastschoolcity}
                                    onChange={updateAddInput}
                                  />
                                </div>
                                <div className="col">
                                  <FormControl
                                    size="small"
                                    style={{
                                      marginTop: 5,
                                      display: "flex",
                                      minWidth: 100,
                                      flex: 1,
                                      marginRight: 9,
                                    }}
                                  >
                                    <InputLabel id="province-label">
                                      Province
                                    </InputLabel>
                                    <Select
                                      name="province"
                                      labelId="province-label"
                                      id="province"
                                      defaultValue={
                                        addingStudent.lastschoolprovince
                                      }
                                      onChange={updateAddInput}
                                      label="Province"
                                    >
                                      <MenuItem value={"Central"}>
                                        Central
                                      </MenuItem>
                                      <MenuItem value={"Copperbelt"}>
                                        Copperbelt
                                      </MenuItem>
                                      <MenuItem value={"Eastern"}>
                                        Eastern
                                      </MenuItem>
                                      <MenuItem value={"Luapula"}>
                                        Luapula
                                      </MenuItem>
                                      <MenuItem value={"Lusaka"}>
                                        Lusaka
                                      </MenuItem>
                                      <MenuItem value={"Muchinga"}>
                                        Muchinga
                                      </MenuItem>
                                      <MenuItem value={"Northern"}>
                                        Northern
                                      </MenuItem>
                                      <MenuItem value={"North Western"}>
                                        North Western
                                      </MenuItem>
                                      <MenuItem value={"Southern"}>
                                        Southern
                                      </MenuItem>
                                      <MenuItem value={"Western"}>
                                        Western
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </div>
                                <div className="col">
                                  <NumberFormat
                                    size="small"
                                    style={{
                                      marginTop: 5,
                                      display: "flex",
                                      minWidth: 100,
                                      flex: 1,
                                      marginRight: 9,
                                    }}
                                    label="Phone Number"
                                    className="form-control "
                                    value={addingStudent.lastschoolnumber}
                                    name="lastschoolnumber"
                                    onChange={updateAddInput}
                                    customInput={TextField}
                                    format="+260 ## ####-###"
                                  />
                                </div>
                                <div className="col">
                                  <TextField
                                    size="small"
                                    style={{
                                      marginTop: 5,
                                      display: "flex",
                                      minWidth: 100,
                                      flex: 1,
                                      marginRight: 9,
                                    }}
                                    type="text"
                                    label="Last Grade Completed"
                                    name="lastgradecompleted"
                                    defaultValue={
                                      addingStudent.lastgradecompleted
                                    }
                                    onChange={updateAddInput}
                                  />
                                </div>
                              </div>
                            </div>
                          )}

                          {activeStep === 3 && (
                            <div>
                              <div className="row input-heading-row">
                                <Typography
                                  style={{
                                    paddingLeft: 0,
                                    marginTop: "10px",
                                    fontSize: 16,
                                  }}
                                >
                                  Emergency Information
                                </Typography>
                              </div>

                              <div className="row">
                                <div className="col">
                                  <TextField
                                    size="small"
                                    style={{
                                      marginTop: 5,
                                      display: "flex",
                                      minWidth: 100,
                                      flex: 1,
                                      marginRight: 9,
                                    }}
                                    label="First Name"
                                    name="emergencyfirstname"
                                    onChange={updateAddInput}
                                    defaultValue={
                                      addingStudent.emergencyfirstname
                                    }
                                  />
                                </div>
                                <div className="col">
                                  <TextField
                                    size="small"
                                    style={{
                                      marginTop: 5,
                                      display: "flex",
                                      minWidth: 100,
                                      flex: 1,
                                      marginRight: 9,
                                    }}
                                    type="text"
                                    label="Last Name"
                                    name="emergencylastname"
                                    defaultValue={
                                      addingStudent.emergencylastname
                                    }
                                    onChange={updateAddInput}
                                  />
                                </div>
                                <div className="col">
                                  <TextField
                                    size="small"
                                    style={{
                                      marginTop: 5,
                                      display: "flex",
                                      minWidth: 100,
                                      flex: 1,
                                      marginRight: 9,
                                    }}
                                    type="text"
                                    label="Relationship"
                                    name="emergencyrelationship"
                                    defaultValue={
                                      addingStudent.emergencyrelationship
                                    }
                                    onChange={updateAddInput}
                                  />
                                </div>
                                <div className="col">
                                  <TextField
                                    size="small"
                                    style={{
                                      marginTop: 5,
                                      display: "flex",
                                      minWidth: 100,
                                      flex: 1,
                                      marginRight: 9,
                                    }}
                                    label="Address"
                                    name="emergencyaddress"
                                    onChange={updateAddInput}
                                    defaultValue={
                                      addingStudent.emergencyaddress
                                    }
                                  />
                                </div>
                              </div>

                              <div
                                className="row"
                                style={{ marginTop: "16px" }}
                              >
                                <div className="col">
                                  <TextField
                                    size="small"
                                    style={{
                                      marginTop: 5,
                                      display: "flex",
                                      minWidth: 100,
                                      flex: 1,
                                      marginRight: 9,
                                    }}
                                    type="text"
                                    label="Town"
                                    name="emergencytown"
                                    defaultValue={addingStudent.emergencytown}
                                    onChange={updateAddInput}
                                  />
                                </div>
                                <div className="col">
                                  <NumberFormat
                                    size="small"
                                    style={{
                                      marginTop: 5,
                                      display: "flex",
                                      minWidth: 100,
                                      flex: 1,
                                      marginRight: 9,
                                    }}
                                    label="Phone Number"
                                    className="form-control "
                                    defaultValue={addingStudent.emergencynumber}
                                    name="emergencynumber"
                                    onChange={updateAddInput}
                                    customInput={TextField}
                                    format="+260 ## ####-###"
                                  />
                                </div>
                                <div className="col"></div>
                                <div className="col"></div>
                              </div>

                              <div className="row input-heading-row">
                                <Typography
                                  style={{
                                    paddingLeft: 0,
                                    marginTop: "10px",
                                    fontSize: 16,
                                  }}
                                >
                                  Medical Information
                                </Typography>
                              </div>

                              <div
                                className="row"
                                style={{ marginTop: "16px" }}
                              >
                                <div className="col">
                                  <TextField
                                    size="small"
                                    style={{
                                      marginTop: 5,
                                      display: "flex",
                                      minWidth: 100,
                                      flex: 1,
                                      marginRight: 9,
                                    }}
                                    label="Medical Condition"
                                    name="medicalconditions"
                                    onChange={updateAddInput}
                                    defaultValue={
                                      addingStudent.medicalconditions
                                    }
                                  />
                                </div>
                                <div className="col">
                                  <TextField
                                    size="small"
                                    style={{
                                      marginTop: 5,
                                      display: "flex",
                                      minWidth: 100,
                                      flex: 1,
                                      marginRight: 9,
                                    }}
                                    type="text"
                                    label="Allergies"
                                    name="medicalallergies"
                                    defaultValue={
                                      addingStudent.medicalallergies
                                    }
                                    onChange={updateAddInput}
                                  />
                                </div>
                                <div className="col">
                                  <TextField
                                    size="small"
                                    style={{
                                      marginTop: 5,
                                      display: "flex",
                                      minWidth: 100,
                                      flex: 1,
                                      marginRight: 9,
                                    }}
                                    type="text"
                                    label="Medications"
                                    name="medicalmedications"
                                    defaultValue={
                                      addingStudent.medicalmedications
                                    }
                                    onChange={updateAddInput}
                                  />
                                </div>
                                <div className="col">
                                  <TextField
                                    size="small"
                                    style={{
                                      marginTop: 5,
                                      display: "flex",
                                      minWidth: 100,
                                      flex: 1,
                                      marginRight: 9,
                                    }}
                                    label="Other"
                                    name="medicalother"
                                    onChange={updateAddInput}
                                    defaultValue={addingStudent.medicalother}
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </form>
                    </div>
                  ) : (
                    <div>
                      {isEditing ? (
                        <div>
                          <form id="edit-student" onSubmit={submitForm}>
                            <CardWrapper className="row  viewcardmain">
                              {/* <pre>{JSON.stringify(selectedStudent)}</pre>
                        <pre>{JSON.stringify(list)}</pre> */}
                              <div className="row">
                                <div className="col">
                                  <Button
                                    className="skipbtn"
                                    style={{
                                      display: "none",
                                      float: "center",
                                      objectFit: "cover",
                                      objectPosition: "100%",
                                      width: 45,
                                      minWidth: 45,
                                      height: 45,
                                      minHeight: 45,
                                      marginLeft: 0,
                                      marginTop: 15,
                                      marginBottom: -10,
                                      boxShadow:
                                        "2px 4px 4px 2px rgba(0, 0, 0, 0.2)",
                                      zIndex: 15,
                                      backgroundColor: "#1565C0",
                                      color: "#fff",
                                      borderRadius: "50%",
                                    }}
                                  >
                                    <ArrowBackIosNewIcon />
                                  </Button>
                                </div>
                                <div className="col">
                                  <Avatar
                                    id="avatar"
                                    style={{
                                      float: "left",
                                      objectFit: "cover",
                                      objectPosition: "100%",
                                      width: 90,
                                      height: 90,
                                      marginLeft: 0,
                                      marginTop: 0,
                                      marginBottom: -20,
                                      boxShadow:
                                        "2px 4px 4px 2px rgba(0, 0, 0, 0.2)",
                                      zIndex: 15,
                                    }}
                                    {...stringAvatar(
                                      editingStudent.firstname +
                                      " " +
                                      editingStudent.lastname
                                    )}
                                  />
                                </div>
                                <div className="col">
                                  <Typography
                                    variant="h5"
                                    style={{
                                      fontSize: 17,
                                      display: "flex",
                                      minWidth: 180,
                                      maxWidth: 180,
                                      flex: 1,
                                      marginTop: 12,
                                      marginLeft: 10,
                                    }}
                                  >
                                    {editingStudent.firstname +
                                      " " +
                                      editingStudent.lastname}
                                  </Typography>
                                  <div className="row">
                                    <SchoolIcon
                                      style={{
                                        marginTop: 0,
                                        padding: 0,
                                        height: 17,
                                        width: 17,
                                        maxWidth: 17,
                                        flex: 1,
                                        marginLeft: 20,
                                        color: "#fff",
                                      }}
                                    />
                                    <Typography
                                      style={{
                                        fontSize: 13,
                                        padding: 2,
                                        minWidth: 135,
                                        maxWidth: 135,
                                        flex: 1,
                                        marginLeft: 0,
                                        textAlign: "left",
                                      }}
                                    >
                                      {editingStudent.grade}
                                    </Typography>
                                  </div>
                                  <div className="row">
                                    <ClassIcon
                                      style={{
                                        marginTop: -3,
                                        padding: 0,
                                        height: 17,
                                        width: 17,
                                        maxWidth: 17,
                                        flex: 1,
                                        marginLeft: 20,
                                        color: "#fff",
                                      }}
                                    />
                                    <Typography
                                      style={{
                                        marginTop: -5,
                                        fontSize: 13,
                                        padding: 2,
                                        minWidth: 135,
                                        maxWidth: 135,
                                        flex: 1,
                                        marginLeft: 0,
                                        textAlign: "left",
                                      }}
                                    >
                                      {"Class: " + editingStudent.class}
                                    </Typography>
                                  </div>
                                  <div className="row">
                                    <NumbersIcon
                                      style={{
                                        marginTop: -3,
                                        padding: 0,
                                        height: 17,
                                        width: 17,
                                        maxWidth: 17,
                                        flex: 1,
                                        marginLeft: 20,
                                        color: "#fff",
                                      }}
                                    />
                                    <Typography
                                      style={{
                                        marginTop: -5,
                                        fontSize: 13,
                                        padding: 2,
                                        minWidth: 165,
                                        maxWidth: 165,
                                        flex: 1,
                                        marginLeft: 0,
                                        textAlign: "left",
                                      }}
                                    >
                                      {"SID: " + editingStudent.studentid}
                                    </Typography>
                                  </div>
                                </div>
                                <div className="col">
                                  <div
                                    className="mb-2"
                                    style={{
                                      width: 140,
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "right",
                                      justifyContent: "right",
                                      marginTop: 19,
                                    }}
                                  >
                                    <Button
                                      startIcon={<SaveAsIcon />}
                                      variant="contained"
                                      color="success"
                                      form="edit-student"
                                      size="small"
                                      type="submit"
                                    >
                                      Save Changes
                                    </Button>
                                  </div>
                                  <div
                                    className="mb-2"
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "right",
                                      justifyContent: "right",
                                    }}
                                  >
                                    <Button
                                      startIcon={<CancelIcon />}
                                      variant="contained"
                                      color="warning"
                                      size="small"
                                      onClick={handleOpenEdit}
                                    >
                                      Cancel
                                    </Button>
                                  </div>
                                </div>
                                <div className="col">
                                  <Button
                                    className="skipbtn"
                                    style={{
                                      display: "none",
                                      float: "right",
                                      objectFit: "cover",
                                      objectPosition: "100%",
                                      width: 15,
                                      minWidth: 15,
                                      height: 45,
                                      minHeight: 45,
                                      marginLeft: 0,
                                      marginRight: 0,
                                      marginTop: 15,
                                      marginBottom: -10,
                                      boxShadow:
                                        "2px 4px 4px 2px rgba(0, 0, 0, 0.2)",
                                      zIndex: 15,
                                      backgroundColor: "#1565C0",
                                      color: "#fff",
                                      borderRadius: "50%",
                                    }}
                                  >
                                    <ArrowForwardIosIcon />
                                  </Button>
                                </div>
                              </div>
                            </CardWrapper>

                            <div className="viewcardbody">
                              <Stepper
                                alternativeLabel
                                activeStep={activeStep}
                                style={{ marginBottom: "5px" }}
                              >
                                {steps.map((label, index) => {
                                  const stepProps: { completed?: boolean } = {};
                                  const labelProps: {
                                    optional?: React.ReactNode;
                                  } = {};
                                  return (
                                    <Step key={label} {...stepProps}>
                                      <Button
                                        onClick={(event) =>
                                          handleProgress(event, index)
                                        }
                                      >
                                        <StepLabel
                                          key={label}
                                          StepIconComponent={ColorlibStepIcon}
                                          {...labelProps}
                                        >
                                          <div className="steplabel">
                                            {label}
                                          </div>
                                        </StepLabel>
                                      </Button>
                                    </Step>
                                  );
                                })}
                              </Stepper>

                              {activeStep === 0 && (
                                <div>
                                  <div className="row input-heading-row">
                                    <Typography
                                      style={{
                                        paddingLeft: 0,
                                        marginTop: "10px",
                                        fontSize: 16,
                                      }}
                                    >
                                      Personal Information
                                    </Typography>
                                  </div>

                                  <div
                                    className="row"
                                    style={{ marginTop: "18px" }}
                                  >
                                    <div className="col">
                                      <TextField
                                        size="small"
                                        required
                                        style={{
                                          marginTop: 5,
                                          display: "flex",
                                          minWidth: 100,
                                          flex: 1,
                                          marginRight: 9,
                                        }}
                                        label="First Name"
                                        name="firstname"
                                        onChange={updateInput}
                                        defaultValue={editingStudent.firstname}
                                      />
                                    </div>
                                    <div className="col">
                                      <TextField
                                        size="small"
                                        style={{
                                          marginTop: 5,
                                          display: "flex",
                                          minWidth: 100,
                                          flex: 1,
                                          marginRight: 9,
                                        }}
                                        label="Middle Name"
                                        name="middlename"
                                        onChange={updateInput}
                                        defaultValue={editingStudent.middlename}
                                      />
                                    </div>
                                    <div className="col">
                                      <TextField
                                        size="small"
                                        required
                                        style={{
                                          marginTop: 5,
                                          display: "flex",
                                          minWidth: 100,
                                          flex: 1,
                                          marginRight: 9,
                                        }}
                                        label="Last Name"
                                        name="lastname"
                                        onChange={updateInput}
                                        defaultValue={editingStudent.lastname}
                                      />
                                    </div>
                                    <div className="col">
                                      <FormControl
                                        required
                                        size="small"
                                        style={{
                                          marginTop: 5,
                                          display: "flex",
                                          minWidth: 100,
                                          flex: 1,
                                          marginRight: 9,
                                        }}
                                      >
                                        <InputLabel id="gender-label">
                                          Gender
                                        </InputLabel>
                                        <Select
                                          required
                                          name="gender"
                                          labelId="gender-label"
                                          id="gender"
                                          defaultValue={editingStudent.gender}
                                          onChange={updateInput}
                                          label="Gender"
                                        >
                                          <MenuItem value={"Male"}>
                                            Male
                                          </MenuItem>
                                          <MenuItem value={"Female"}>
                                            Female
                                          </MenuItem>
                                        </Select>
                                      </FormControl>
                                    </div>
                                  </div>

                                  <div
                                    className="row"
                                    style={{ marginTop: "16px" }}
                                  >
                                    <div className="col">
                                      <TextField
                                        required
                                        size="small"
                                        style={{
                                          marginTop: 5,
                                          display: "flex",
                                          minWidth: 100,
                                          flex: 1,
                                          marginRight: 9,
                                        }}
                                        inputProps={{
                                          min: "1920-01-24",
                                          max: today(),
                                        }}
                                        type="date"
                                        label="Date of Birth"
                                        name="dob"
                                        onChange={updateInput}
                                        defaultValue={editingStudent.dob}
                                        InputLabelProps={{ shrink: true }}
                                      />
                                    </div>
                                    <div className="col">
                                      <TextField
                                        size="small"
                                        style={{
                                          marginTop: 5,
                                          display: "flex",
                                          minWidth: 100,
                                          flex: 1,
                                          marginRight: 9,
                                        }}
                                        label="Language"
                                        name="language"
                                        onChange={updateInput}
                                        defaultValue={editingStudent.language}
                                      />
                                    </div>
                                    <div className="col">
                                      <NumberFormat
                                        size="small"
                                        style={{
                                          marginTop: 5,
                                          display: "flex",
                                          minWidth: 100,
                                          flex: 1,
                                          marginRight: 9,
                                        }}
                                        label="Phone Number"
                                        className="form-control "
                                        value={editingStudent.number}
                                        name="number"
                                        onChange={updateInput}
                                        customInput={TextField}
                                        format="+260 ## ####-###"
                                        required={true}
                                      />
                                    </div>
                                    <div className="col">
                                      <TextField
                                        size="small"
                                        style={{
                                          marginTop: 5,
                                          display: "flex",
                                          minWidth: 100,
                                          flex: 1,
                                          marginRight: 9,
                                        }}
                                        // type="email"
                                        label="Email"
                                        name="email"
                                        defaultValue={editingStudent.email}
                                        onChange={updateInput}
                                      />
                                    </div>
                                  </div>

                                  <div
                                    className="row"
                                    style={{ marginTop: "16px" }}
                                  >
                                    <div className="col">
                                      <FormControl
                                        size="small"
                                        style={{
                                          marginTop: 5,
                                          display: "flex",
                                          minWidth: 100,
                                          flex: 1,
                                          marginRight: 9,
                                        }}
                                      >
                                        <InputLabel id="nationality-label">
                                          Nationality
                                        </InputLabel>
                                        <Select
                                          name="nationality"
                                          labelId="nationality-label"
                                          id="nationality"
                                          defaultValue={
                                            editingStudent.nationality ||
                                            "Zambia"
                                          }
                                          onChange={updateInput}
                                          label="Nationality"
                                        >
                                          {!!countriesArr?.length &&
                                            countriesArr.map((countryObj) => (
                                              <MenuItem
                                                key={countryObj.value}
                                                value={countryObj.value}
                                              >
                                                {countryObj.label}{" "}
                                                <ReactCountryFlag
                                                  style={{ marginLeft: 4 }}
                                                  countryCode={countryObj.id}
                                                  svg
                                                />
                                              </MenuItem>
                                            ))}
                                        </Select>
                                      </FormControl>
                                    </div>
                                    <div className="col">
                                      <TextField
                                        size="small"
                                        style={{
                                          marginTop: 5,
                                          display: "flex",
                                          minWidth: 100,
                                          flex: 1,
                                          marginRight: 9,
                                        }}
                                        type="text"
                                        label="Address"
                                        name="address"
                                        defaultValue={editingStudent.address}
                                        onChange={updateInput}
                                      />
                                    </div>
                                    <div className="col">
                                      <TextField
                                        size="small"
                                        style={{
                                          marginTop: 5,
                                          display: "flex",
                                          minWidth: 100,
                                          flex: 1,
                                          marginRight: 9,
                                        }}
                                        type="text"
                                        label="Town"
                                        name="town"
                                        defaultValue={editingStudent.town}
                                        onChange={updateInput}
                                        required
                                      />
                                    </div>
                                    <div className="col">
                                      <FormControl
                                        size="small"
                                        style={{
                                          marginTop: 5,
                                          display: "flex",
                                          minWidth: 100,
                                          flex: 1,
                                          marginRight: 9,
                                        }}
                                      >
                                        <InputLabel id="gender-label">
                                          Province
                                        </InputLabel>
                                        <Select
                                          name="province"
                                          labelId="province-label"
                                          id="province"
                                          defaultValue={editingStudent.province}
                                          onChange={updateInput}
                                          label="Province"
                                        >
                                          <MenuItem value={"Central"}>
                                            Central
                                          </MenuItem>
                                          <MenuItem value={"Copperbelt"}>
                                            Copperbelt
                                          </MenuItem>
                                          <MenuItem value={"Eastern"}>
                                            Eastern
                                          </MenuItem>
                                          <MenuItem value={"Luapula"}>
                                            Luapula
                                          </MenuItem>
                                          <MenuItem value={"Lusaka"}>
                                            Lusaka
                                          </MenuItem>
                                          <MenuItem value={"Muchinga"}>
                                            Muchinga
                                          </MenuItem>
                                          <MenuItem value={"Northern"}>
                                            Northern
                                          </MenuItem>
                                          <MenuItem value={"North Western"}>
                                            North Western
                                          </MenuItem>
                                          <MenuItem value={"Southern"}>
                                            Southern
                                          </MenuItem>
                                          <MenuItem value={"Western"}>
                                            Western
                                          </MenuItem>
                                        </Select>
                                      </FormControl>
                                    </div>
                                  </div>
                                </div>
                              )}

                              {activeStep === 1 && (
                                <div>
                                  <div className="row input-heading-row">
                                    <Typography
                                      style={{
                                        paddingLeft: 0,
                                        marginTop: "10px",
                                        fontSize: 16,
                                      }}
                                    >
                                      Parent(s) / Guardian(s) Information
                                    </Typography>
                                  </div>

                                  <div
                                    className="row"
                                    style={{ marginTop: "16px" }}
                                  >
                                    <div className="col">
                                      <FormControl
                                        size="small"
                                        style={{
                                          marginTop: 5,
                                          display: "flex",
                                          minWidth: 100,
                                          flex: 1,
                                          marginRight: 9,
                                        }}
                                      >
                                        <InputLabel
                                          id="gender-label"
                                          shrink={true}
                                        >
                                          Parent(s) are
                                        </InputLabel>
                                        <Select
                                          style={{
                                            marginTop: 2,
                                          }}
                                          name="parentsguardiansrelationship"
                                          labelId="parentsguardiansrelationship-label"
                                          id="parentsguardiansrelationship"
                                          defaultValue={
                                            editingStudent[
                                            `parentsguardiansrelationship`
                                            ]
                                          }
                                          onChange={updateInput}
                                          label="parentsguardiansrelationship"
                                        >
                                          <MenuItem value={"Married"}>
                                            Married
                                          </MenuItem>
                                          <MenuItem value={"Single"}>
                                            Single
                                          </MenuItem>
                                          <MenuItem value={"Separated"}>
                                            Separated
                                          </MenuItem>
                                          <MenuItem value={"Divorced"}>
                                            Divorced
                                          </MenuItem>
                                          <MenuItem value={"Widowed"}>
                                            Widowed
                                          </MenuItem>
                                          <MenuItem value={"Common-Law"}>
                                            Common-Law
                                          </MenuItem>
                                        </Select>
                                      </FormControl>
                                    </div>
                                    <div className="col">
                                      <FormControl
                                        size="small"
                                        style={{
                                          marginTop: 5,
                                          display: "flex",
                                          minWidth: 100,
                                          flex: 1,
                                          marginRight: 9,
                                        }}
                                      >
                                        <InputLabel
                                          id="gender-label"
                                          shrink={true}
                                        >
                                          Custody
                                        </InputLabel>
                                        <Select
                                          name="custody"
                                          labelId="custody-label"
                                          id="custody"
                                          defaultValue={editingStudent.custody}
                                          onChange={updateInput}
                                          label="custody"
                                          style={{
                                            marginTop: 2,
                                          }}
                                        >
                                          <MenuItem value={"Joint"}>
                                            Joint
                                          </MenuItem>
                                          <MenuItem value={"Sole"}>
                                            Sole
                                          </MenuItem>
                                        </Select>
                                      </FormControl>
                                    </div>
                                    <div className="col">
                                      <FormControl
                                        size="small"
                                        style={{
                                          marginTop: 5,
                                          display: "flex",
                                          minWidth: 100,
                                          flex: 1,
                                          marginRight: 9,
                                        }}
                                      >
                                        <InputLabel
                                          id="gender-label"
                                          shrink={true}
                                        >
                                          Student lives with
                                        </InputLabel>
                                        <Select
                                          name="studentliveswith"
                                          labelId="studentliveswith-label"
                                          id="studentliveswith"
                                          defaultValue={
                                            editingStudent.studentliveswith
                                          }
                                          onChange={updateInput}
                                          label="studentliveswith"
                                          style={{
                                            marginTop: 2,
                                          }}
                                        >
                                          <MenuItem value={"Both Parents"}>
                                            Both Parents
                                          </MenuItem>
                                          <MenuItem value={"Father"}>
                                            Father
                                          </MenuItem>
                                          <MenuItem value={"Mother"}>
                                            Mother
                                          </MenuItem>
                                          <MenuItem value={"Guardian"}>
                                            Guardian
                                          </MenuItem>
                                          <MenuItem value={"Other"}>
                                            Other
                                          </MenuItem>
                                        </Select>
                                      </FormControl>
                                    </div>
                                    <div className="col"></div>
                                  </div>

                                  <Divider
                                    style={{ marginTop: 12, marginBottom: 24 }}
                                  ></Divider>
                                  <EditGuardian
                                    onParentUpdate={handleParentChange}
                                    parents={editingStudent.guardians ?? []}
                                  />
                                  <div key={editingStudent.id}>
                                    <EditEnrolments
                                      key={updateStudentPage}
                                      student={editingStudent}
                                      registrations={getRegistrationsForStudent(
                                        editingStudent
                                      )}
                                      onEnrolmentsUpdate={handleEnrolmentChange}
                                    />
                                  </div>
                                </div>
                              )}

                              {activeStep === 2 && (
                                <div>
                                  <div className="row input-heading-row">
                                    <Typography
                                      style={{
                                        paddingLeft: 0,
                                        marginTop: "10px",
                                        fontSize: 16,
                                      }}
                                    >
                                      School Details
                                    </Typography>
                                  </div>

                                  <div
                                    className="row"
                                    style={{ marginTop: "16px" }}
                                  >
                                    <div className="col">
                                      <TextField
                                        size="small"
                                        style={{
                                          marginTop: 5,
                                          display: "flex",
                                          minWidth: 100,
                                          flex: 1,
                                          marginRight: 9,
                                        }}
                                        label="Class"
                                        name="class"
                                        onChange={updateInput}
                                        defaultValue={editingStudent.class}
                                      />
                                    </div>
                                    <div className="col">
                                      <FormControl
                                        size="small"
                                        style={{
                                          marginTop: 5,
                                          display: "flex",
                                          minWidth: 100,
                                          flex: 1,
                                          marginRight: 9,
                                        }}
                                      >
                                        <InputLabel id="gender-label">
                                          Grade
                                        </InputLabel>
                                        <Select
                                          name="grade"
                                          labelId="grade-label"
                                          id="grade"
                                          defaultValue={editingStudent.grade}
                                          onChange={updateInput}
                                          label="Grade"
                                        >
                                          <MenuItem value={"Baby Class"}>
                                            Baby Class
                                          </MenuItem>
                                          <MenuItem value={"Reception"}>
                                            Reception
                                          </MenuItem>
                                          <MenuItem value={"Grade 1"}>
                                            Grade 1
                                          </MenuItem>
                                          <MenuItem value={"Grade 2"}>
                                            Grade 2
                                          </MenuItem>
                                          <MenuItem value={"Grade 3"}>
                                            Grade 3
                                          </MenuItem>
                                          <MenuItem value={"Grade 4"}>
                                            Grade 4
                                          </MenuItem>
                                          <MenuItem value={"Grade 5"}>
                                            Grade 5
                                          </MenuItem>
                                          <MenuItem value={"Grade 6"}>
                                            Grade 6
                                          </MenuItem>
                                          <MenuItem value={"Grade 7"}>
                                            Grade 7
                                          </MenuItem>
                                          <MenuItem value={"Grade 8"}>
                                            Grade 8
                                          </MenuItem>
                                          <MenuItem value={"Grade 9"}>
                                            Grade 9
                                          </MenuItem>
                                          <MenuItem value={"Grade 10"}>
                                            Grade 10
                                          </MenuItem>
                                          <MenuItem value={"Grade 11"}>
                                            Grade 11
                                          </MenuItem>
                                          <MenuItem value={"Grade 12"}>
                                            Grade 12
                                          </MenuItem>
                                          <MenuItem value={"Alumni"}>
                                            Alumni
                                          </MenuItem>
                                        </Select>
                                      </FormControl>
                                    </div>
                                    <div className="col">
                                      <FormControl
                                        size="small"
                                        style={{
                                          marginTop: 5,
                                          display: "flex",
                                          minWidth: 100,
                                          flex: 1,
                                          marginRight: 9,
                                        }}
                                      >
                                        <InputLabel id="teacher-label">
                                          Teacher
                                        </InputLabel>
                                        <Select
                                          name="teacher"
                                          labelId="teacher-label"
                                          id="teacher"
                                          defaultValue={editingStudent.teacher}
                                          onChange={updateInput}
                                          label="Teacher"
                                        >
                                          {!!staff?.length &&
                                            staff.map((teacher) => (
                                              <MenuItem
                                                key={teacher.id}
                                                value={teacher.id}
                                              >
                                                {teacher.firstname +
                                                  " " +
                                                  teacher.lastname}
                                                <ReactCountryFlag
                                                  style={{ marginLeft: 4 }}
                                                  countryCode={teacher.id}
                                                  svg
                                                />
                                              </MenuItem>
                                            ))}
                                        </Select>
                                      </FormControl>
                                    </div>
                                    <div className="col">
                                      <FormControl
                                        size="small"
                                        style={{
                                          marginTop: 5,
                                          display: "flex",
                                          minWidth: 100,
                                          flex: 1,
                                          marginRight: 9,
                                        }}
                                      >
                                        <InputLabel id="jobtitle-label">
                                          Campus
                                        </InputLabel>
                                        <Select
                                          labelId="campus-label"
                                          id="campus"
                                          name="campus"
                                          defaultValue={editingStudent.campus}
                                          onChange={updateInput}
                                          label="Campus"
                                        >
                                          <MenuItem value={"Main Campus"}>
                                            Main Campus
                                          </MenuItem>
                                          <MenuItem value={"Lubombo"}>
                                            Lubombo
                                          </MenuItem>
                                        </Select>
                                      </FormControl>
                                    </div>
                                  </div>

                                  <div
                                    className="row"
                                    style={{
                                      marginTop: "16px",
                                      marginBottom: "10px",
                                    }}
                                  >
                                    <div className="col"></div>
                                    <div className="col">
                                      <FormControl
                                        size="small"
                                        style={{
                                          marginTop: 5,
                                          display: "flex",
                                          minWidth: 200,
                                          flex: 1,
                                          marginRight: 0,
                                        }}
                                      >
                                        <FormLabel
                                          id="health-radio-label"
                                          style={{
                                            fontSize: 10,
                                          }}
                                        >
                                          Was Student Transferred?
                                        </FormLabel>
                                        <RadioGroup
                                          row
                                          aria-labelledby="health-radio-label"
                                          name="transferredout"
                                          defaultValue={
                                            editingStudent.transferredout
                                          }
                                          onChange={updateInput}
                                        >
                                          <FormControlLabel
                                            style={{
                                              fontSize: 9,
                                            }}
                                            value="Yes"
                                            control={<Radio />}
                                            label="Yes"
                                          />
                                          <FormControlLabel
                                            style={{
                                              fontSize: 9,
                                            }}
                                            value="No"
                                            control={<Radio />}
                                            label="No"
                                          />
                                        </RadioGroup>
                                      </FormControl>
                                    </div>
                                    <div className="col">
                                      <FormControl
                                        size="small"
                                        style={{
                                          marginTop: 5,
                                          display: "flex",
                                          minWidth: 200,
                                          flex: 1,
                                          marginRight: 0,
                                        }}
                                      >
                                        <FormLabel
                                          id="health-radio-label"
                                          style={{
                                            fontSize: 10,
                                          }}
                                        >
                                          Did Student Dropout?
                                        </FormLabel>
                                        <RadioGroup
                                          row
                                          aria-labelledby="health-radio-label"
                                          name="droppedout"
                                          defaultValue={
                                            editingStudent.droppedout
                                          }
                                          onChange={updateInput}
                                        >
                                          <FormControlLabel
                                            style={{
                                              fontSize: 9,
                                            }}
                                            value="Yes"
                                            control={<Radio />}
                                            label="Yes"
                                          />
                                          <FormControlLabel
                                            style={{
                                              fontSize: 9,
                                            }}
                                            value="No"
                                            control={<Radio />}
                                            label="No"
                                          />
                                        </RadioGroup>
                                      </FormControl>
                                    </div>
                                    <div className="col"></div>
                                    <div className="col"></div>
                                  </div>

                                  <div
                                    className="row"
                                    style={{
                                      marginTop: "16px",
                                      marginBottom: "10px",
                                    }}
                                  >
                                    <div className="col">
                                      <TextField
                                        size="small"
                                        style={{
                                          marginTop: 5,
                                          display: "flex",
                                          minWidth: 100,
                                          flex: 1,
                                          marginRight: 9,
                                        }}
                                        type="text"
                                        className="form-control "
                                        label="Comment"
                                        name="leftstudentcomment"
                                        defaultValue={
                                          editingStudent.leftstudentcomment
                                        }
                                        onChange={updateInput}
                                      />
                                    </div>
                                  </div>

                                  <div className="row input-heading-row">
                                    <Typography
                                      style={{
                                        paddingLeft: 0,
                                        marginTop: "10px",
                                        fontSize: 16,
                                      }}
                                    >
                                      School History
                                    </Typography>
                                  </div>

                                  <div
                                    className="row"
                                    style={{ marginTop: "16px" }}
                                  >
                                    <div className="col">
                                      <TextField
                                        size="small"
                                        style={{
                                          marginTop: 5,
                                          display: "flex",
                                          minWidth: 100,
                                          flex: 1,
                                          marginRight: 9,
                                        }}
                                        label="Last School"
                                        name="lastschool"
                                        onChange={updateInput}
                                        defaultValue={editingStudent.lastschool}
                                      />
                                    </div>
                                    <div className="col"></div>
                                    <div className="col"></div>
                                    <div className="col"></div>
                                  </div>

                                  <div
                                    className="row"
                                    style={{ marginTop: "16px" }}
                                  >
                                    <div className="col">
                                      <TextField
                                        size="small"
                                        style={{
                                          marginTop: 5,
                                          display: "flex",
                                          minWidth: 100,
                                          flex: 1,
                                          marginRight: 9,
                                        }}
                                        type="text"
                                        label="Town"
                                        name="lastschoolcity"
                                        defaultValue={
                                          editingStudent.lastschoolcity
                                        }
                                        onChange={updateInput}
                                      />
                                    </div>
                                    <div className="col">
                                      <FormControl
                                        size="small"
                                        style={{
                                          marginTop: 5,
                                          display: "flex",
                                          minWidth: 100,
                                          flex: 1,
                                          marginRight: 9,
                                        }}
                                      >
                                        <InputLabel id="gender-label">
                                          Province
                                        </InputLabel>
                                        <Select
                                          name="lastschoolprovince"
                                          labelId="province-label"
                                          id="province"
                                          defaultValue={
                                            editingStudent.lastschoolprovince
                                          }
                                          onChange={updateInput}
                                          label="Province"
                                        >
                                          <MenuItem value={"Central"}>
                                            Central
                                          </MenuItem>
                                          <MenuItem value={"Copperbelt"}>
                                            Copperbelt
                                          </MenuItem>
                                          <MenuItem value={"Eastern"}>
                                            Eastern
                                          </MenuItem>
                                          <MenuItem value={"Luapula"}>
                                            Luapula
                                          </MenuItem>
                                          <MenuItem value={"Lusaka"}>
                                            Lusaka
                                          </MenuItem>
                                          <MenuItem value={"Muchinga"}>
                                            Muchinga
                                          </MenuItem>
                                          <MenuItem value={"Northern"}>
                                            Northern
                                          </MenuItem>
                                          <MenuItem value={"North Western"}>
                                            North Western
                                          </MenuItem>
                                          <MenuItem value={"Southern"}>
                                            Southern
                                          </MenuItem>
                                          <MenuItem value={"Western"}>
                                            Western
                                          </MenuItem>
                                        </Select>
                                      </FormControl>
                                    </div>
                                    <div className="col">
                                      <NumberFormat
                                        size="small"
                                        style={{
                                          marginTop: 5,
                                          display: "flex",
                                          minWidth: 100,
                                          flex: 1,
                                          marginRight: 9,
                                        }}
                                        label="Phone Number"
                                        className="form-control "
                                        value={editingStudent.lastschoolnumber}
                                        name="lastschoolnumber"
                                        onChange={updateInput}
                                        customInput={TextField}
                                        format="+260 ## ####-###"
                                      />
                                    </div>
                                    <div className="col">
                                      <TextField
                                        size="small"
                                        style={{
                                          marginTop: 5,
                                          display: "flex",
                                          minWidth: 100,
                                          flex: 1,
                                          marginRight: 9,
                                        }}
                                        type="text"
                                        label="Last Grade Completed"
                                        name="lastgradecompleted"
                                        defaultValue={
                                          editingStudent.lastgradecompleted
                                        }
                                        onChange={updateInput}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}

                              {activeStep === 3 && (
                                <div>
                                  <div className="row input-heading-row">
                                    <Typography
                                      style={{
                                        paddingLeft: 0,
                                        marginTop: "10px",
                                        fontSize: 16,
                                      }}
                                    >
                                      Emergency Information
                                    </Typography>
                                  </div>

                                  <div className="row">
                                    <div className="col">
                                      <TextField
                                        size="small"
                                        style={{
                                          marginTop: 5,
                                          display: "flex",
                                          minWidth: 100,
                                          flex: 1,
                                          marginRight: 9,
                                        }}
                                        label="First Name"
                                        name="emergencyfirstname"
                                        onChange={updateInput}
                                        defaultValue={
                                          editingStudent.emergencyfirstname
                                        }
                                      />
                                    </div>
                                    <div className="col">
                                      <TextField
                                        size="small"
                                        style={{
                                          marginTop: 5,
                                          display: "flex",
                                          minWidth: 100,
                                          flex: 1,
                                          marginRight: 9,
                                        }}
                                        type="text"
                                        label="Last Name"
                                        name="emergencylastname"
                                        defaultValue={
                                          editingStudent.emergencylastname
                                        }
                                        onChange={updateInput}
                                      />
                                    </div>
                                    <div className="col">
                                      <TextField
                                        size="small"
                                        style={{
                                          marginTop: 5,
                                          display: "flex",
                                          minWidth: 100,
                                          flex: 1,
                                          marginRight: 9,
                                        }}
                                        type="text"
                                        label="Relationship"
                                        name="emergencyrelationship"
                                        defaultValue={
                                          editingStudent.emergencyrelationship
                                        }
                                        onChange={updateInput}
                                      />
                                    </div>
                                    <div className="col">
                                      <TextField
                                        size="small"
                                        style={{
                                          marginTop: 5,
                                          display: "flex",
                                          minWidth: 100,
                                          flex: 1,
                                          marginRight: 9,
                                        }}
                                        label="Address"
                                        name="emergencyaddress"
                                        onChange={updateInput}
                                        defaultValue={
                                          editingStudent.emergencyaddress
                                        }
                                      />
                                    </div>
                                  </div>

                                  <div
                                    className="row"
                                    style={{ marginTop: "16px" }}
                                  >
                                    <div className="col">
                                      <TextField
                                        size="small"
                                        style={{
                                          marginTop: 5,
                                          display: "flex",
                                          minWidth: 100,
                                          flex: 1,
                                          marginRight: 9,
                                        }}
                                        type="text"
                                        label="Town"
                                        name="emergencytown"
                                        defaultValue={
                                          editingStudent.emergencytown
                                        }
                                        onChange={updateInput}
                                      />
                                    </div>
                                    <div className="col">
                                      <NumberFormat
                                        size="small"
                                        style={{
                                          marginTop: 5,
                                          display: "flex",
                                          minWidth: 100,
                                          flex: 1,
                                          marginRight: 9,
                                        }}
                                        label="Phone Number"
                                        className="form-control "
                                        defaultValue={
                                          editingStudent.emergencynumber
                                        }
                                        name="emergencynumber"
                                        onChange={updateInput}
                                        customInput={TextField}
                                        format="+260 ## ####-###"
                                      />
                                    </div>
                                    <div className="col"></div>
                                    <div className="col"></div>
                                  </div>

                                  <div className="row input-heading-row">
                                    <Typography
                                      style={{
                                        paddingLeft: 0,
                                        marginTop: "10px",
                                        fontSize: 16,
                                      }}
                                    >
                                      Medical Information
                                    </Typography>
                                  </div>

                                  <div
                                    className="row"
                                    style={{ marginTop: "16px" }}
                                  >
                                    <div className="col">
                                      <TextField
                                        size="small"
                                        style={{
                                          marginTop: 5,
                                          display: "flex",
                                          minWidth: 100,
                                          flex: 1,
                                          marginRight: 9,
                                        }}
                                        label="Medical Condition"
                                        name="medicalconditions"
                                        onChange={updateInput}
                                        defaultValue={
                                          editingStudent.medicalconditions
                                        }
                                      />
                                    </div>
                                    <div className="col">
                                      <TextField
                                        size="small"
                                        style={{
                                          marginTop: 5,
                                          display: "flex",
                                          minWidth: 100,
                                          flex: 1,
                                          marginRight: 9,
                                        }}
                                        type="text"
                                        label="Allergies"
                                        name="medicalallergies"
                                        defaultValue={
                                          editingStudent.medicalallergies
                                        }
                                        onChange={updateInput}
                                      />
                                    </div>
                                    <div className="col">
                                      <TextField
                                        size="small"
                                        style={{
                                          marginTop: 5,
                                          display: "flex",
                                          minWidth: 100,
                                          flex: 1,
                                          marginRight: 9,
                                        }}
                                        type="text"
                                        label="Medications"
                                        name="medicalmedications"
                                        defaultValue={
                                          editingStudent.medicalmedications
                                        }
                                        onChange={updateInput}
                                      />
                                    </div>
                                    <div className="col">
                                      <TextField
                                        size="small"
                                        style={{
                                          marginTop: 5,
                                          display: "flex",
                                          minWidth: 100,
                                          flex: 1,
                                          marginRight: 9,
                                        }}
                                        label="Other"
                                        name="medicalother"
                                        onChange={updateInput}
                                        defaultValue={
                                          editingStudent.medicalother
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </form>
                        </div>
                      ) : (
                        <div>
                          <CardWrapper className="row  viewcardmain">
                            {/* <pre>{JSON.stringify(selectedStudent)}</pre>
                          <pre>{JSON.stringify(list)}</pre> */}
                            <div className="row">
                              <div className="col">
                                <Button
                                  className="skipbtn"
                                  style={{
                                    objectFit: "cover",
                                    objectPosition: "100%",
                                    width: 45,
                                    minWidth: 45,
                                    height: 45,
                                    minHeight: 45,
                                    marginLeft: 0,
                                    marginTop: 15,
                                    marginBottom: -10,
                                    boxShadow:
                                      "2px 4px 4px 2px rgba(0, 0, 0, 0.2)",
                                    zIndex: 15,
                                    backgroundColor: "#1565C0",
                                    color: "#fff",
                                    borderRadius: "50%",
                                  }}
                                  onClick={() => {
                                    handleBack();
                                  }}
                                >
                                  <ArrowBackIosNewIcon />
                                </Button>
                              </div>
                              <div className="col">
                                <Avatar
                                  id="avatar"
                                  style={{
                                    float: "left",
                                    objectFit: "cover",
                                    objectPosition: "100%",
                                    width: 90,
                                    height: 90,
                                    marginLeft: 0,
                                    marginTop: 0,
                                    marginBottom: -20,
                                    boxShadow:
                                      "2px 4px 4px 2px rgba(0, 0, 0, 0.2)",
                                    zIndex: 15,
                                  }}
                                  {...stringAvatar(
                                    selectedStudent.firstname +
                                    " " +
                                    selectedStudent.lastname
                                  )}
                                />
                              </div>
                              <div className="col">
                                <Typography
                                  variant="h5"
                                  style={{
                                    fontSize: 17,
                                    display: "flex",
                                    minWidth: 180,
                                    maxWidth: 180,
                                    flex: 1,
                                    marginTop: 12,
                                    marginLeft: 10,
                                  }}
                                >
                                  {selectedStudent.firstname +
                                    " " +
                                    selectedStudent.lastname}
                                </Typography>
                                <div className="row">
                                  <SchoolIcon
                                    style={{
                                      marginTop: 0,
                                      padding: 0,
                                      height: 17,
                                      width: 17,
                                      maxWidth: 17,
                                      flex: 1,
                                      marginLeft: 20,
                                      color: "#fff",
                                    }}
                                  />
                                  <Typography
                                    style={{
                                      fontSize: 13,
                                      padding: 2,
                                      minWidth: 135,
                                      maxWidth: 135,
                                      flex: 1,
                                      marginLeft: 0,
                                      textAlign: "left",
                                    }}
                                  >
                                    {selectedStudent.grade}
                                  </Typography>
                                </div>
                                <div className="row">
                                  <ClassIcon
                                    style={{
                                      marginTop: -3,
                                      padding: 0,
                                      height: 17,
                                      width: 17,
                                      maxWidth: 17,
                                      flex: 1,
                                      marginLeft: 20,
                                      color: "#fff",
                                    }}
                                  />
                                  <Typography
                                    style={{
                                      marginTop: -5,
                                      fontSize: 13,
                                      padding: 2,
                                      minWidth: 135,
                                      maxWidth: 135,
                                      flex: 1,
                                      marginLeft: 0,
                                      textAlign: "left",
                                    }}
                                  >
                                    {"Class: " + selectedStudent.class}
                                  </Typography>
                                </div>
                                <div className="row">
                                  <NumbersIcon
                                    style={{
                                      marginTop: -3,
                                      padding: 0,
                                      height: 17,
                                      width: 17,
                                      maxWidth: 17,
                                      flex: 1,
                                      marginLeft: 20,
                                      color: "#fff",
                                    }}
                                  />
                                  <Typography
                                    style={{
                                      marginTop: -5,
                                      fontSize: 13,
                                      padding: 2,
                                      minWidth: 165,
                                      maxWidth: 165,
                                      flex: 1,
                                      marginLeft: 0,
                                      textAlign: "left",
                                    }}
                                  >
                                    {"SID: " + selectedStudent.studentid}
                                  </Typography>
                                </div>
                              </div>
                              <div className="col">
                                <div
                                  className="mb-2"
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "right",
                                    justifyContent: "right",
                                    marginTop: 19,
                                  }}
                                >
                                  <Button
                                    startIcon={<EditIcon />}
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    onClick={() => {
                                      editStudent();
                                    }}
                                  >
                                    Edit
                                  </Button>
                                </div>
                                <div
                                  className="mb-2"
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "right",
                                    justifyContent: "right",
                                  }}
                                >
                                  <PermissionsGate scopes={[SCOPES.canDelete]}>
                                    <Button
                                      startIcon={<DeleteIcon />}
                                      variant="contained"
                                      color="warning"
                                      size="small"
                                      onClick={handleOpenDelete}
                                    >
                                      Delete
                                    </Button>
                                  </PermissionsGate>
                                </div>
                              </div>
                              <div className="col">
                                <Button
                                  className="skipbtn"
                                  style={{
                                    float: "right",
                                    objectFit: "cover",
                                    objectPosition: "100%",
                                    width: 45,
                                    minWidth: 45,
                                    height: 45,
                                    minHeight: 45,
                                    marginLeft: 0,
                                    marginRight: 0,
                                    marginTop: 15,
                                    marginBottom: -10,
                                    boxShadow:
                                      "2px 4px 4px 2px rgba(0, 0, 0, 0.2)",
                                    zIndex: 15,
                                    backgroundColor: "#1565C0",
                                    color: "#fff",
                                    borderRadius: "50%",
                                  }}
                                  onClick={() => {
                                    handleNext();
                                  }}
                                >
                                  <ArrowForwardIosIcon />
                                </Button>
                              </div>
                            </div>
                          </CardWrapper>

                          <div className="viewcardbody">
                            <Stepper
                              alternativeLabel
                              activeStep={activeStep}
                              style={{ marginBottom: "5px" }}
                            >
                              {steps.map((label, index) => {
                                const stepProps: { completed?: boolean } = {};
                                const labelProps: {
                                  optional?: React.ReactNode;
                                } = {};
                                return (
                                  <Step key={label} {...stepProps}>
                                    <Button
                                      onClick={(event) =>
                                        handleProgress(event, index)
                                      }
                                    >
                                      <StepLabel
                                        key={label}
                                        StepIconComponent={ColorlibStepIcon}
                                        {...labelProps}
                                      >
                                        <div className="steplabel">{label}</div>
                                      </StepLabel>
                                    </Button>
                                  </Step>
                                );
                              })}
                            </Stepper>

                            {activeStep === 0 && (
                              <div>
                                <div className="row input-heading-row">
                                  <Typography
                                    style={{
                                      paddingLeft: 0,
                                      marginTop: "10px",
                                      fontSize: 16,
                                    }}
                                  >
                                    Personal Information
                                  </Typography>
                                </div>

                                <div className="row">
                                  <div className="col">
                                    <Typography
                                      style={{
                                        paddingLeft: 0,
                                        fontSize: 10,
                                        color: "#878787",
                                      }}
                                    >
                                      First Name
                                    </Typography>
                                    <Typography
                                      style={{ paddingLeft: 0, fontSize: 13 }}
                                    >
                                      {selectedStudent.firstname}
                                    </Typography>
                                  </div>
                                  <div className="col">
                                    <Typography
                                      style={{
                                        paddingLeft: 0,
                                        fontSize: 10,
                                        color: "#878787",
                                      }}
                                    >
                                      Middle Name
                                    </Typography>
                                    <Typography
                                      style={{ paddingLeft: 0, fontSize: 13 }}
                                    >
                                      {selectedStudent.middlename}
                                    </Typography>
                                  </div>
                                  <div className="col">
                                    <Typography
                                      style={{
                                        paddingLeft: 0,
                                        fontSize: 10,
                                        color: "#878787",
                                      }}
                                    >
                                      Last Name
                                    </Typography>
                                    <Typography
                                      style={{ paddingLeft: 0, fontSize: 13 }}
                                    >
                                      {selectedStudent.lastname}
                                    </Typography>
                                  </div>
                                  <div className="col">
                                    <Typography
                                      style={{
                                        paddingLeft: 0,
                                        fontSize: 10,
                                        color: "#878787",
                                      }}
                                    >
                                      Gender
                                    </Typography>
                                    <Typography
                                      style={{ paddingLeft: 0, fontSize: 13 }}
                                    >
                                      {selectedStudent.gender}
                                    </Typography>
                                  </div>
                                </div>

                                <div
                                  className="row"
                                  style={{ marginTop: "10px" }}
                                >
                                  <div className="col">
                                    <Typography
                                      style={{
                                        paddingLeft: 0,
                                        fontSize: 10,
                                        color: "#878787",
                                      }}
                                    >
                                      Date of Birth
                                    </Typography>
                                    <Typography
                                      style={{ paddingLeft: 0, fontSize: 13 }}
                                    >
                                      {selectedStudent.dob}
                                    </Typography>
                                  </div>
                                  <div className="col">
                                    <Typography
                                      style={{
                                        paddingLeft: 0,
                                        fontSize: 10,
                                        color: "#878787",
                                      }}
                                    >
                                      Language
                                    </Typography>
                                    <Typography
                                      style={{ paddingLeft: 0, fontSize: 13 }}
                                    >
                                      {selectedStudent.language}
                                    </Typography>
                                  </div>
                                  <div className="col">
                                    <Typography
                                      style={{
                                        paddingLeft: 0,
                                        fontSize: 10,
                                        color: "#878787",
                                      }}
                                    >
                                      Phone Number
                                    </Typography>
                                    <Typography
                                      style={{ paddingLeft: 0, fontSize: 13 }}
                                    >
                                      {selectedStudent.number}
                                    </Typography>
                                  </div>
                                  <div className="col">
                                    <Typography
                                      style={{
                                        paddingLeft: 0,
                                        fontSize: 10,
                                        color: "#878787",
                                      }}
                                    >
                                      Email
                                    </Typography>
                                    <Typography
                                      style={{ paddingLeft: 0, fontSize: 13 }}
                                    >
                                      {selectedStudent.email}
                                    </Typography>
                                  </div>
                                </div>

                                <div
                                  className="row"
                                  style={{ marginTop: "10px" }}
                                >
                                  <div className="col">
                                    <Typography
                                      style={{
                                        paddingLeft: 0,
                                        fontSize: 10,
                                        color: "#878787",
                                      }}
                                    >
                                      Nationality
                                    </Typography>
                                    <Typography
                                      style={{ paddingLeft: 0, fontSize: 13 }}
                                    >
                                      {selectedStudent.nationality || "Zambia"}
                                    </Typography>
                                  </div>
                                  <div className="col">
                                    <Typography
                                      style={{
                                        paddingLeft: 0,
                                        fontSize: 10,
                                        color: "#878787",
                                      }}
                                    >
                                      Address
                                    </Typography>
                                    <Typography
                                      style={{ paddingLeft: 0, fontSize: 13 }}
                                    >
                                      {selectedStudent.address}
                                    </Typography>
                                  </div>
                                  <div className="col">
                                    <Typography
                                      style={{
                                        paddingLeft: 0,
                                        fontSize: 10,
                                        color: "#878787",
                                      }}
                                    >
                                      Town
                                    </Typography>
                                    <Typography
                                      style={{ paddingLeft: 0, fontSize: 13 }}
                                    >
                                      {selectedStudent.town}
                                    </Typography>
                                  </div>
                                  <div className="col">
                                    <Typography
                                      style={{
                                        paddingLeft: 0,
                                        fontSize: 10,
                                        color: "#878787",
                                      }}
                                    >
                                      Province
                                    </Typography>
                                    <Typography
                                      style={{ paddingLeft: 0, fontSize: 13 }}
                                    >
                                      {selectedStudent.province}
                                    </Typography>
                                  </div>
                                </div>
                              </div>
                            )}

                            {activeStep === 1 && (
                              <div>
                                <div className="row input-heading-row">
                                  <Typography
                                    style={{
                                      paddingLeft: 0,
                                      marginTop: "10px",
                                      fontSize: 16,
                                    }}
                                  >
                                    Parent(s) / Guardian(s) Information
                                  </Typography>
                                </div>

                                <div className="row">
                                  <div className="col">
                                    <Typography
                                      style={{
                                        paddingLeft: 0,
                                        fontSize: 10,
                                        color: "#878787",
                                      }}
                                    >
                                      Parent(s) are
                                    </Typography>
                                    <Typography
                                      style={{ paddingLeft: 0, fontSize: 13 }}
                                    >
                                      {
                                        selectedStudent[
                                        `parentsguardiansrelationship`
                                        ]
                                      }
                                    </Typography>
                                  </div>
                                  <div className="col">
                                    <Typography
                                      style={{
                                        paddingLeft: 0,
                                        fontSize: 10,
                                        color: "#878787",
                                      }}
                                    >
                                      Custody
                                    </Typography>
                                    <Typography
                                      style={{ paddingLeft: 0, fontSize: 13 }}
                                    >
                                      {selectedStudent.custody}
                                    </Typography>
                                  </div>
                                  <div className="col">
                                    <Typography
                                      style={{
                                        paddingLeft: 0,
                                        fontSize: 10,
                                        color: "#878787",
                                      }}
                                    >
                                      Student lives with
                                    </Typography>
                                    <Typography
                                      style={{ paddingLeft: 0, fontSize: 13 }}
                                    >
                                      {selectedStudent.studentliveswith}
                                    </Typography>
                                  </div>
                                  <div className="col"></div>
                                </div>

                                {selectedStudent.guardians?.map(
                                  (parent: Guardian, i) => (
                                    <div>
                                      <div
                                        className="row"
                                        style={{ marginTop: "10px" }}
                                      >
                                        <div className="col">
                                          <Typography
                                            style={{
                                              paddingLeft: 0,
                                              fontSize: 10,
                                              color: "#878787",
                                            }}
                                          >
                                            First Name
                                          </Typography>
                                          <Typography
                                            style={{
                                              paddingLeft: 0,
                                              fontSize: 13,
                                            }}
                                          >
                                            {parent.firstname}
                                          </Typography>
                                        </div>
                                        <div className="col">
                                          <Typography
                                            style={{
                                              paddingLeft: 0,
                                              fontSize: 10,
                                              color: "#878787",
                                            }}
                                          >
                                            Last Name
                                          </Typography>
                                          <Typography
                                            style={{
                                              paddingLeft: 0,
                                              fontSize: 13,
                                            }}
                                          >
                                            {parent.lastname}
                                          </Typography>
                                        </div>
                                        <div className="col">
                                          <Typography
                                            style={{
                                              paddingLeft: 0,
                                              fontSize: 10,
                                              color: "#878787",
                                            }}
                                          >
                                            Paternity
                                          </Typography>
                                          <Typography
                                            style={{
                                              paddingLeft: 0,
                                              fontSize: 13,
                                            }}
                                          >
                                            {parent.fathermother}
                                          </Typography>
                                        </div>
                                        <div className="col">
                                          <Typography
                                            style={{
                                              paddingLeft: 0,
                                              fontSize: 10,
                                              color: "#878787",
                                            }}
                                          >
                                            Status
                                          </Typography>
                                          <Typography
                                            style={{
                                              paddingLeft: 0,
                                              fontSize: 13,
                                            }}
                                          >
                                            {parent.parentlegalguardian}
                                          </Typography>
                                        </div>
                                      </div>

                                      <div
                                        className="row"
                                        style={{ marginTop: "10px" }}
                                      >
                                        <div className="col">
                                          <Typography
                                            style={{
                                              paddingLeft: 0,
                                              fontSize: 10,
                                              color: "#878787",
                                            }}
                                          >
                                            The student is
                                          </Typography>
                                          <Typography
                                            style={{
                                              paddingLeft: 0,
                                              fontSize: 13,
                                            }}
                                          >
                                            {parent.biologicaladoptedfoster}
                                          </Typography>
                                        </div>
                                        <div className="col">
                                          <Typography
                                            style={{
                                              paddingLeft: 0,
                                              fontSize: 10,
                                              color: "#878787",
                                            }}
                                          >
                                            Relationship
                                          </Typography>
                                          <Typography
                                            style={{
                                              paddingLeft: 0,
                                              fontSize: 13,
                                            }}
                                          >
                                            {parent.relationship}
                                          </Typography>
                                        </div>
                                        <div className="col"></div>
                                        <div className="col"></div>
                                      </div>
                                    </div>
                                  )
                                )}

                                <ViewEnrolments
                                  student={selectedStudent}
                                  registrations={
                                    getRegistrationsForStudent(
                                      selectedStudent
                                    ) ?? []
                                  }
                                />
                              </div>
                            )}

                            {activeStep === 2 && (
                              <div>
                                <div className="row input-heading-row">
                                  <Typography
                                    style={{
                                      paddingLeft: 0,
                                      marginTop: "10px",
                                      fontSize: 16,
                                    }}
                                  >
                                    School Details
                                  </Typography>
                                </div>

                                <div className="row">
                                  <div className="col">
                                    <Typography
                                      style={{
                                        paddingLeft: 0,
                                        fontSize: 10,
                                        color: "#878787",
                                      }}
                                    >
                                      Class
                                    </Typography>
                                    <Typography
                                      style={{ paddingLeft: 0, fontSize: 13 }}
                                    >
                                      {selectedStudent.class}
                                    </Typography>
                                  </div>
                                  <div className="col">
                                    <Typography
                                      style={{
                                        paddingLeft: 0,
                                        fontSize: 10,
                                        color: "#878787",
                                      }}
                                    >
                                      Grade
                                    </Typography>
                                    <Typography
                                      style={{ paddingLeft: 0, fontSize: 13 }}
                                    >
                                      {selectedStudent.grade}
                                    </Typography>
                                  </div>
                                  <div className="col">
                                    <Typography
                                      style={{
                                        paddingLeft: 0,
                                        fontSize: 10,
                                        color: "#878787",
                                      }}
                                    >
                                      Teacher
                                    </Typography>
                                    <Typography
                                      style={{ paddingLeft: 0, fontSize: 13 }}
                                    >
                                      {teacherName()}
                                    </Typography>
                                  </div>
                                  <div className="col">
                                    <Typography
                                      style={{
                                        paddingLeft: 0,
                                        fontSize: 10,
                                        color: "#878787",
                                      }}
                                    >
                                      Campus
                                    </Typography>
                                    <Typography
                                      style={{ paddingLeft: 0, fontSize: 13 }}
                                    >
                                      {selectedStudent.campus}
                                    </Typography>
                                  </div>
                                </div>

                                <div
                                  className="row"
                                  style={{
                                    marginTop: "16px",
                                    marginBottom: "10px",
                                  }}
                                >
                                  <div className="col"></div>

                                  <div className="col">
                                    <Typography
                                      style={{
                                        paddingLeft: 0,
                                        fontSize: 10,
                                        color: "#878787",
                                      }}
                                    >
                                      Was Student Transferred?
                                    </Typography>
                                    <Typography
                                      style={{ paddingLeft: 0, fontSize: 13 }}
                                    >
                                      {selectedStudent.transferredout}
                                    </Typography>
                                  </div>
                                  <div className="col">
                                    <Typography
                                      style={{
                                        paddingLeft: 0,
                                        fontSize: 10,
                                        color: "#878787",
                                      }}
                                    >
                                      Did Student Dropout?
                                    </Typography>
                                    <Typography
                                      style={{ paddingLeft: 0, fontSize: 13 }}
                                    >
                                      {selectedStudent.droppedout}
                                    </Typography>
                                  </div>
                                  <div className="col"></div>
                                </div>

                                <div className="row">
                                  <div className="col">
                                    <Typography
                                      style={{
                                        paddingLeft: 0,
                                        fontSize: 10,
                                        color: "#878787",
                                      }}
                                    >
                                      Comment
                                    </Typography>
                                    <Typography
                                      style={{ paddingLeft: 0, fontSize: 13 }}
                                    >
                                      {selectedStudent.leftstudentcomment}
                                    </Typography>
                                  </div>
                                </div>

                                <div className="row input-heading-row">
                                  <Typography
                                    style={{
                                      paddingLeft: 0,
                                      marginTop: "10px",
                                      fontSize: 16,
                                    }}
                                  >
                                    School History
                                  </Typography>
                                </div>

                                <div className="row">
                                  <div className="col">
                                    <Typography
                                      style={{
                                        paddingLeft: 0,
                                        fontSize: 10,
                                        color: "#878787",
                                      }}
                                    >
                                      Last School
                                    </Typography>
                                    <Typography
                                      style={{ paddingLeft: 0, fontSize: 13 }}
                                    >
                                      {selectedStudent.lastschool}
                                    </Typography>
                                  </div>
                                  <div className="col"></div>
                                  <div className="col"></div>
                                </div>

                                <div className="row">
                                  <div className="col">
                                    <Typography
                                      style={{
                                        paddingLeft: 0,
                                        fontSize: 10,
                                        color: "#878787",
                                      }}
                                    >
                                      Town
                                    </Typography>
                                    <Typography
                                      style={{ paddingLeft: 0, fontSize: 13 }}
                                    >
                                      {selectedStudent.lastschoolcity}
                                    </Typography>
                                  </div>
                                  <div className="col">
                                    <Typography
                                      style={{
                                        paddingLeft: 0,
                                        fontSize: 10,
                                        color: "#878787",
                                      }}
                                    >
                                      Province
                                    </Typography>
                                    <Typography
                                      style={{ paddingLeft: 0, fontSize: 13 }}
                                    >
                                      {selectedStudent.lastschoolprovince}
                                    </Typography>
                                  </div>
                                  <div className="col">
                                    <Typography
                                      style={{
                                        paddingLeft: 0,
                                        fontSize: 10,
                                        color: "#878787",
                                      }}
                                    >
                                      Phone Number
                                    </Typography>
                                    <Typography
                                      style={{ paddingLeft: 0, fontSize: 13 }}
                                    >
                                      {selectedStudent.lastschoolnumber}
                                    </Typography>
                                  </div>
                                  <div className="col">
                                    <Typography
                                      style={{
                                        paddingLeft: 0,
                                        fontSize: 10,
                                        color: "#878787",
                                      }}
                                    >
                                      Last grade completed
                                    </Typography>
                                    <Typography
                                      style={{ paddingLeft: 0, fontSize: 13 }}
                                    >
                                      {selectedStudent.lastgradecompleted}
                                    </Typography>
                                  </div>
                                </div>
                              </div>
                            )}

                            {activeStep === 3 && (
                              <div>
                                <div className="row input-heading-row">
                                  <Typography
                                    style={{
                                      paddingLeft: 0,
                                      marginTop: "10px",
                                      fontSize: 16,
                                    }}
                                  >
                                    Emergency Information
                                  </Typography>
                                </div>

                                <div className="row">
                                  <div className="col">
                                    <Typography
                                      style={{
                                        paddingLeft: 0,
                                        fontSize: 10,
                                        color: "#878787",
                                      }}
                                    >
                                      First Name
                                    </Typography>
                                    <Typography
                                      style={{ paddingLeft: 0, fontSize: 13 }}
                                    >
                                      {selectedStudent.emergencyfirstname}
                                    </Typography>
                                  </div>
                                  <div className="col">
                                    <Typography
                                      style={{
                                        paddingLeft: 0,
                                        fontSize: 10,
                                        color: "#878787",
                                      }}
                                    >
                                      Last Name
                                    </Typography>
                                    <Typography
                                      style={{ paddingLeft: 0, fontSize: 13 }}
                                    >
                                      {selectedStudent.emergencylastname}
                                    </Typography>
                                  </div>
                                  <div className="col">
                                    <Typography
                                      style={{
                                        paddingLeft: 0,
                                        fontSize: 10,
                                        color: "#878787",
                                      }}
                                    >
                                      Relationship
                                    </Typography>
                                    <Typography
                                      style={{ paddingLeft: 0, fontSize: 13 }}
                                    >
                                      {selectedStudent.emergencyrelationship}
                                    </Typography>
                                  </div>
                                  <div className="col">
                                    <Typography
                                      style={{
                                        paddingLeft: 0,
                                        fontSize: 10,
                                        color: "#878787",
                                      }}
                                    >
                                      Address
                                    </Typography>
                                    <Typography
                                      style={{ paddingLeft: 0, fontSize: 13 }}
                                    >
                                      {selectedStudent.emergencyaddress}
                                    </Typography>
                                  </div>
                                </div>

                                <div
                                  className="row"
                                  style={{ marginTop: "10px" }}
                                >
                                  <div className="col">
                                    <Typography
                                      style={{
                                        paddingLeft: 0,
                                        fontSize: 10,
                                        color: "#878787",
                                      }}
                                    >
                                      Town
                                    </Typography>
                                    <Typography
                                      style={{ paddingLeft: 0, fontSize: 13 }}
                                    >
                                      {selectedStudent.emergencytown}
                                    </Typography>
                                  </div>
                                  <div className="col">
                                    <Typography
                                      style={{
                                        paddingLeft: 0,
                                        fontSize: 10,
                                        color: "#878787",
                                      }}
                                    >
                                      Number
                                    </Typography>
                                    <Typography
                                      style={{ paddingLeft: 0, fontSize: 13 }}
                                    >
                                      {selectedStudent.emergencynumber}
                                    </Typography>
                                  </div>
                                  <div className="col"></div>
                                  <div className="col"></div>
                                </div>

                                <div className="row input-heading-row">
                                  <Typography
                                    style={{
                                      paddingLeft: 0,
                                      marginTop: "10px",
                                      fontSize: 16,
                                    }}
                                  >
                                    Medical Information
                                  </Typography>
                                </div>

                                <div className="row">
                                  <div className="col">
                                    <Typography
                                      style={{
                                        paddingLeft: 0,
                                        fontSize: 10,
                                        color: "#878787",
                                      }}
                                    >
                                      Medical condition
                                    </Typography>
                                    <Typography
                                      style={{ paddingLeft: 0, fontSize: 13 }}
                                    >
                                      {selectedStudent.medicalconditions}
                                    </Typography>
                                  </div>
                                  <div className="col">
                                    <Typography
                                      style={{
                                        paddingLeft: 0,
                                        fontSize: 10,
                                        color: "#878787",
                                      }}
                                    >
                                      Allergies
                                    </Typography>
                                    <Typography
                                      style={{ paddingLeft: 0, fontSize: 13 }}
                                    >
                                      {selectedStudent.medicalallergies}
                                    </Typography>
                                  </div>
                                  <div className="col">
                                    <Typography
                                      style={{
                                        paddingLeft: 0,
                                        fontSize: 10,
                                        color: "#878787",
                                      }}
                                    >
                                      Medications
                                    </Typography>
                                    <Typography
                                      style={{ paddingLeft: 0, fontSize: 13 }}
                                    >
                                      {selectedStudent.medicalmedications}
                                    </Typography>
                                  </div>
                                  <div className="col">
                                    <Typography
                                      style={{
                                        paddingLeft: 0,
                                        fontSize: 10,
                                        color: "#878787",
                                      }}
                                    >
                                      Other
                                    </Typography>
                                    <Typography
                                      style={{ paddingLeft: 0, fontSize: 13 }}
                                    >
                                      {selectedStudent.medicalother}
                                    </Typography>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </section>
          ) : (
            <div></div>

          )}
        </Grid>
        <Grid className="col" xs={3} style={{ marginTop: 137, marginLeft: "-15%" }} item>
          {!loadingGrid && !isBulkEditing && !isViewingTable && (
            <>
              {console.log("Selected Student :", selectedStudent?.lastname)}
              {console.log("Selected :", enrolmentIndex)}
              {console.log("Selected Student Year:", selectedStudent?.enrolments?.[enrolmentIndex]?.year)}
              {console.log("Current Year:", currentYear)}
              {console.log("Equal Check:", selectedStudent?.enrolments?.[enrolmentIndex]?.year.toString() === currentYear.toString())}

              {selectedStudent?.enrolments?.[enrolmentIndex]?.year.toString() === currentYear.toString() && (
                <>
                  <Grid item xs={12} alignItems="start">
                    <Button
                      style={{ width: 200 }}
                      variant="contained"
                      size="large"
                      startIcon={<SummarizeIcon />}
                      onClick={() => setopenInvoice(true)}
                    >
                      View Invoice
                    </Button>
                  </Grid>

                  <Grid item xs={12} alignItems="start">
                    <Button
                      style={{ marginTop: 10, width: 200 }}
                      variant="contained"
                      size="large"
                      startIcon={<SummarizeIcon />}
                      onClick={() => setopenReceipt(true)}
                    >
                      View Receipt
                    </Button>
                  </Grid>

                  <Grid item xs={12} alignItems="start">
                    <Button
                      style={{ marginTop: 10, width: 200 }}
                      variant="contained"
                      size="large"
                      startIcon={<SummarizeIcon />}
                      onClick={handleOpenNewPayment}
                    >
                      Make Payment
                    </Button>
                  </Grid>
                </>
              )}
            </>
          )}
        </Grid>

        {selectedStudent &&
          <>
            <InvoiceModal
              open={openInvoice}
              onClose={() => setopenInvoice(false)}
              invoiceData={getInvoiceData(selectedStudent)}
            />
            <ReceiptModal
              open={openReceipt}
              onClose={() => setopenReceipt(false)}
              receiptData={getReceiptData(selectedStudent)}
            />
            {makePayment && (
              <EnrolmentPaymentDialog
                open={true}
                onClose={handleCloseNewPayment}
                payment={newPayment}
                onUpdatePayment={handleSavePayment}
              // onDocumentUpdate={handleProofOfPaymentUpload}
              />
            )}
          </>
        }
      </Grid>
    </Grid>
  );
}





export default StudentList;

function StudentFilter(
  handleClickListItem: MouseEventHandler<HTMLButtonElement>,
  selectedFilterIndex: number,
  anchorEl: HTMLElement | null,
  open: boolean,
  handleCloseFilterMenu:
    | ((event: {}, reason: "backdropClick" | "escapeKeyDown") => void)
    | undefined,
  handleMenuItemClick: (
    arg0: React.MouseEvent<HTMLLIElement, MouseEvent>,
    arg1: number
  ) => void
) {
  return (
    <>
      <List component="nav" disablePadding>
        <Button
          id="filter-button"
          variant="outlined"
          color="primary"
          size="small"
          onClick={handleClickListItem}
        >
          <ListItemText
            primary="Viewing"
            primaryTypographyProps={{ fontSize: 8 }}
            secondary={options[selectedFilterIndex]}
            secondaryTypographyProps={{ fontSize: 10 }}
          />
        </Button>
      </List>
      <Menu
        id="filter-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseFilterMenu}
        MenuListProps={{
          role: "listbox",
        }}
      >
        {options.map((option, index) => (
          <MenuItem
            style={{
              justifyContent: "center",
              alignItems: "center",
              fontSize: 14,
            }}
            key={option}
            selected={index === selectedFilterIndex}
            onClick={(event) => handleMenuItemClick(event, index)}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

function StudentTable(
  _navigate: NavigateFunction,
  _clickDelete: Function,
  students: Student[],
  setStudent: Dispatch<SetStateAction<Student>>,
  loadingGrid: boolean
) {
  const cols: GridColDef[] = [
    {
      field: "fullName",
      headerName: "Student Full name",
      width: 140,
      minWidth: 140,
      flex: 1,
      renderHeader: (_params: GridColumnHeaderParams) => (
        <strong>{"Student name"}</strong>
      ),
    },
    {
      field: "grade",
      type: "singleSelect",
      valueOptions: Object.values(Grades),
      headerName: "Grade",
      width: 60,
      maxWidth: 100,
      minWidth: 60,
      flex: 1,
      renderHeader: (_params: GridColumnHeaderParams) => (
        <strong>{"Grade"}</strong>
      ),
    },
  ];

  const rows: any[] = students.map((s) => ({
    id: s.id,
    fullName: s.firstname + " " + s.lastname,
    grade: s.grade,
    class: s.class,
    student: s,
  }));

  const onRowClickEvent: GridEventListener<"rowClick"> = (
    params: GridRowParams, // GridRowParams
    _event: MuiEvent<React.MouseEvent<HTMLElement>>,
    _details: GridCallbackDetails
  ) => {
    setStudent(params.row.student);
  };
  const dataTable = (
    <div
      style={{
        height: 615,
        width: "100%",
        backgroundColor: "white",
        fontSize: 3,
      }}
    >
      <DataGrid
        // apiRef={apiRef}
        headerHeight={40}
        rowHeight={40}
        rows={rows}
        columns={cols}
        pageSize={13}
        rowsPerPageOptions={[13]}
        checkboxSelection={false}
        onRowClick={onRowClickEvent}
        loading={loadingGrid}
      />
    </div>
  );
  return dataTable;
}

function SearchBar(
  setSearch: React.Dispatch<SetStateAction<string>>,
  search: string
) {
  return (
    <form>
      <div style={{ fontSize: 3, height: 50 }}>
        <TextField
          className="searchbarbackground regularTextField"
          id="searchbarid"
          variant="outlined"
          fullWidth
          label="Search"
          defaultValue={search}
          onChange={(e) => setSearch(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </div>
    </form>
  );
}

function teachers(staff) {
  var teachersId = staff.map(function (o) {
    return o.firstname + " " + o.lastname;
  });

  return teachersId;
}

function BulkStudentTable(
  selectStudentFromBulkEdit: Function,
  setBulkList: Function,
  _clickDelete: Function,
  students: Student[],
  staff: [],
  isViewingTable: boolean,
  loadingGrid: boolean,
  userName
) {
  function UpdateObject(e) {
    Object.keys(students).forEach((el) => {
      if (students[el].id === e.id) {
        if (e.field === "teacher") {
          let teacherFirstName = e.value.split(" ")[0];
          let index = staff.findIndex((object) => {
            return object.firstname === teacherFirstName;
          });
          students[el][`${[e.field]}`] = staff[index].id;
        } else {
          students[el][`${[e.field]}`] = e.value;
        }
        students[el].updatedAt = new Date(Date.now()).toISOString();
        students[el].updatedBy = userName;
      }
    });
    setBulkList(students);
  }

  const cols: GridColDef[] = [
    {
      field: "firstname",
      headerName: "First Name",
      width: 100,
      minWidth: 100,
      flex: 1,
      renderHeader: (_params: GridColumnHeaderParams) => (
        <strong>{"First Name"}</strong>
      ),
      renderCell: (params: GridRenderCellParams) => (
        <div
          style={{
            backgroundColor: "#e8e8e8",
            width: "200%",
            height: "100%",
            paddingTop: 11.5,
          }}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: "lastname",
      headerName: "Last Name",
      width: 100,
      minWidth: 100,
      flex: 1,
      renderHeader: (_params: GridColumnHeaderParams) => (
        <strong>{"Last Name"}</strong>
      ),
      renderCell: (params: GridRenderCellParams) => (
        <div
          style={{
            backgroundColor: "#e8e8e8",
            width: "100%",
            height: "100%",
            paddingTop: 11.5,
          }}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: "dob",
      headerName: "Date of Birth",
      width: 90,
      minWidth: 90,
      flex: 1,
      renderHeader: (_params: GridColumnHeaderParams) => (
        <strong>{"Date of Birth"}</strong>
      ),
      renderCell: (params: GridRenderCellParams) => (
        <>
          {isViewingTable ? (
            <>
              <div
                style={{
                  backgroundColor: "#e8e8e8",
                  width: "100%",
                  height: "100%",
                  paddingTop: 11.5,
                }}
              >
                {params.value}
              </div>
            </>
          ) : (
            <div
              style={{
                marginLeft: 3,
              }}
            >
              {params.value}{" "}
            </div>
          )}
        </>
      ),
      editable: !isViewingTable,
      type: "date",
    },
    {
      field: "grade",
      type: "singleSelect",
      valueOptions: Object.values(Grades),
      headerName: "Grade",
      width: 50,
      minWidth: 50,
      flex: 1,
      renderHeader: (_params: GridColumnHeaderParams) => (
        <strong>{"Grade"}</strong>
      ),
      renderCell: (params: GridRenderCellParams) => (
        <>
          {isViewingTable ? (
            <>
              <div
                style={{
                  backgroundColor: "#e8e8e8",
                  width: "100%",
                  height: "100%",
                  paddingTop: 11.5,
                }}
              >
                {params.value}
              </div>
            </>
          ) : (
            <>{params.value}</>
          )}
        </>
      ),
      editable: !isViewingTable,
    },
    {
      field: "class",
      headerName: "Class",
      width: 50,
      minWidth: 50,
      flex: 1,
      renderHeader: (_params: GridColumnHeaderParams) => (
        <strong>{"Class"}</strong>
      ),
      renderCell: (params: GridRenderCellParams) => (
        <>
          {isViewingTable ? (
            <>
              <div
                style={{
                  backgroundColor: "#e8e8e8",
                  width: "100%",
                  height: "100%",
                  paddingTop: 11.5,
                }}
              >
                {params.value}
              </div>
            </>
          ) : (
            <>{params.value}</>
          )}
        </>
      ),
      editable: !isViewingTable,
    },

    {
      field: "number",
      headerName: "Phone NUmber",
      width: 120,
      minWidth: 120,
      flex: 1,
      renderHeader: (_params: GridColumnHeaderParams) => (
        <strong>{"Phone Number"}</strong>
      ),
      renderCell: (params: GridRenderCellParams) => (
        <div
          style={{
            backgroundColor: "#e8e8e8",
            width: "200px",
            height: "100%",
            paddingTop: 11.5,
          }}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: "teacher",
      type: "singleSelect",
      valueOptions: Object.values(teachers(staff)),
      headerName: "Teacher",
      width: 140,
      minWidth: 140,
      flex: 1,
      renderHeader: (_params: GridColumnHeaderParams) => (
        <strong>{"Teacher"}</strong>
      ),
      renderCell: (params: GridRenderCellParams) => (
        <div
          style={{
            backgroundColor: "#e8e8e8",
            width: "200px",
            height: "100%",
            paddingTop: 11.5,
          }}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: "lastenrolment",
      headerName: "Last Enrolment",
      width: 120,
      minWidth: 120,
      flex: 1,
      renderHeader: (_params: GridColumnHeaderParams) => (
        <strong>{"Last Enrolment"}</strong>
      ),
      renderCell: (params: GridRenderCellParams) => (
        <span
          style={{
            backgroundColor: "#e8e8e8",
            width: "100%",
            height: "100%",
            paddingTop: 11.5,
          }}
        >
          {params.value}
        </span>
      ),
      type: "date",
    },
    {
      field: "parentname",
      headerName: "Parent Name",
      width: 120,
      minWidth: 120,
      flex: 1,
      renderHeader: (_params: GridColumnHeaderParams) => (
        <strong>{"Parent Name"}</strong>
      ),
      renderCell: (params: GridRenderCellParams) => (
        <div
          style={{
            backgroundColor: "#e8e8e8",
            width: "100%",
            height: "100%",
            paddingTop: 11.5,
          }}
        >
          {params.value}
        </div>
      ),
    },

    {
      field: "paymentstatus",
      headerName: "Payment Status",
      width: 120,
      minWidth: 120,
      flex: 1,
      renderHeader: (_params: GridColumnHeaderParams) => (
        <strong>{"Payment Status"}</strong>
      ),
      renderCell: (params: GridRenderCellParams) => (
        <div
          style={{
            backgroundColor: "#e8e8e8",
            width: "100%",
            height: "100%",
            paddingTop: 11.5,
          }}
        >
          <Chip
            label={params.value}
            color={statusToColor[params.value] as colorType}
            size="small"
            style={{ display: "flex" }}
          />
          {params.value}
        </div>
      ),
    },
    {
      field: "invoicedamount",
      headerName: "Invoiced Amount",
      width: 120,
      minWidth: 120,
      flex: 1,
      renderHeader: (_params: GridColumnHeaderParams) => (
        <strong>{"Invoiced Paid"}</strong>
      ),
      renderCell: (params: GridRenderCellParams) => (
        <div
          style={{
            backgroundColor: "#e8e8e8",
            width: "100%",
            height: "100%",
            paddingTop: 11.5,
          }}
        >
          <Chip
            label={params.value}
            color={statusToColor[params.value] as colorType}
            size="small"
            style={{ display: "flex" }}
          />
          {params.value}
        </div>
      ),
    },
    {
      field: "paymentamount",
      headerName: "Payment Amount",
      width: 120,
      minWidth: 120,
      flex: 1,
      renderHeader: (_params: GridColumnHeaderParams) => (
        <strong>{"Amount Paid"}</strong>
      ),
      renderCell: (params: GridRenderCellParams) => (
        <div
          style={{
            backgroundColor: "#e8e8e8",
            width: "100%",
            height: "100%",
            paddingTop: 11.5,
          }}
        >
          <Chip
            label={params.value}
            color={statusToColor[params.value] as colorType}
            size="small"
            style={{ display: "flex" }}
          />
          {params.value}
        </div>
      ),
    }
  ];
  // 54c9a70c-7804-4bcd-b246-38a831290564

  function teacherName(teacherId: string) {
    let index = staff.findIndex((object) => {
      return object.id === teacherId;
    });
    return staff[index]
      ? staff[index]?.firstname + " " + staff[index]?.lastname
      : "";
  }

  function getLastEnrolment(s: Student) {
    let result = "";
    if (s.enrolments.length > 0) {
      result = new Date(s.enrolments[s.enrolments.length - 1].createdAt)
        .toISOString()
        .split("T")[0];
    }

    return result;
  }

  function getSumOfPayments(enrolment: Enrolment) {
    return enrolment?.payments?.reduce(
      (accumulator: number, e: EnrolmentPayment) => {
        return accumulator + Number(e.amount);
      },
      0
    );
  }

  const rows: any[] = students.map((s) => ({
    id: s.id,
    firstname: s.firstname,
    lastname: s.lastname,
    gender: s.gender,
    dob: s.dob,
    number: s.number,
    email: s.email,
    address: s.address,
    town: s.town,
    province: s.province,
    grade: s.grade,
    class: s.class,
    teacher: s.teacher,
    campus: s.campus,
    student: s,
    lastenrolment: getLastEnrolment(s),
    parentname: s.guardians[0]?.firstname + " " + s.guardians[0]?.lastname,
    parent2firstname: s.guardians[1]?.firstname,
    parent2lastname: s.guardians[1]?.lastname,
    paymentstatus: s.enrolments[enrolmentIndex]?.status,
    invoicedamount: s.enrolments[enrolmentIndex] ? "K" +
      ((s.enrolments[enrolmentIndex]?.totalAmount - s.enrolments[enrolmentIndex]
        ?.totalAmount * ((parseInt(s.enrolments[enrolmentIndex]?.discount) || 0) / 100))).toFixed(2) : "",
    paymentamount: getSumOfPayments(s.enrolments[enrolmentIndex])?.toFixed(2) ?
      "K" + getSumOfPayments(s.enrolments[enrolmentIndex])?.toFixed(2) : "",
  }));

  const onRowClickEvent: GridEventListener<"rowClick"> = (
    params: GridRowParams, // GridRowParams
    _event: MuiEvent<React.MouseEvent<HTMLElement>>,
    _details: GridCallbackDetails
  ) => {
    if (isViewingTable) {
      let index = students.findIndex((object) => {
        return object.id === params.row.id;
      });
      selectStudentFromBulkEdit(index);
    }
  };
  const dataTable = (
    <div
      style={{
        height: 615,
        width: "100%",
        backgroundColor: "white",
        fontSize: 3,
      }}
    >
      <DataGrid
        headerHeight={40}
        rowHeight={40}
        rows={rows}
        columns={cols}
        pageSize={13}
        rowsPerPageOptions={[13]}
        checkboxSelection={false}
        onRowClick={onRowClickEvent}
        onCellEditCommit={UpdateObject}
        loading={loadingGrid}
      />
    </div>
  );
  return dataTable;
}

function AddStudentButton(
  addStudentClicked: MouseEventHandler<HTMLButtonElement>
) {
  return (
    <Button
      startIcon={<PersonAddIcon />}
      variant="contained"
      color="success"
      size="small"
      onClick={addStudentClicked}
    >
      Add Student
    </Button>
  );
}

function getRegistrationsForStudent(student: Student): Registration[] {
  const list: Registration[][] = [];
  student.guardians?.forEach((g) => {
    if (g.registration) {
      list.push(g.registration);
    }
  });
  const registrations: Registration[] = list.flat();
  return registrations;
}

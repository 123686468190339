import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Avatar,
  Alert,
  Snackbar,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogTitle,
  DialogContent,
  CircularProgress,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import {
  DataGrid,
  GridCallbackDetails,
  GridColDef,
  GridColumnHeaderParams,
  GridEventListener,
  GridRenderCellParams,
  GridRowParams,
  MuiEvent,
} from "@mui/x-data-grid";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { NavigateFunction, useNavigate } from "react-router";
import { MouseEventHandler, SetStateAction, useEffect, useState } from "react";
import { Guardian, Student, Title } from "../../util/data-models";
import {
  deleteGuardian,
  getAllGuardians,
  getAllStudents,
} from "../../util/api-calls";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import PermissionsGate from "../../util/perminssion-gate";
import { SCOPES } from "../../util/permission-map";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { StepIconProps } from "@mui/material/StepIcon";
import PersonIcon from "@mui/icons-material/Person";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import EmailIcon from "@mui/icons-material/Email";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import MainCard from "../dashboard/ui-component/cards/MainCard";
import { styled } from "@mui/material/styles";
import { Registration } from "../../util/data-models";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import React from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import CloseIcon from "@mui/icons-material/Close";
import NumberFormat from "react-number-format";
import NewEditRegistration from "../editcontact/neweditregistration";
import TableRowsIcon from "@mui/icons-material/TableRows";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { v4 as uuidv4 } from "uuid";
import NewViewRegistration from "../editcontact/newviewregistration";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CardWrapper = styled(MainCard)(({ theme }) => ({
  overflow: "hidden",
  color: "#fff",
  position: "relative",
  backgroundColor: "#5cb85c",
  borderRadius: "10px",
  "&>div": {
    position: "relative",
    zIndex: 5,
  },
  "&:after": {
    content: '""',
    position: "absolute",
    width: 210,
    height: 210,
    backgroundColor: "#4e9c4e",
    borderRadius: "50%",
    top: -85,
    right: -95,
    [theme.breakpoints.down("sm")]: {
      top: -105,
      right: -140,
    },
  },
  "&:before": {
    content: '""',
    position: "absolute",
    width: 210,
    height: 210,
    backgroundColor: "#489448",
    borderRadius: "50%",
    top: -125,
    right: -15,
    opacity: 0.5,
    [theme.breakpoints.down("sm")]: {
      top: -155,
      right: -70,
    },
  },
}));

const ColorlibStepIconRoot = styled("div")<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 35,
  height: 35,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",

  ...(ownerState.active && {
    backgroundImage:
      "linear-gradient( 136deg, #5cb85c 0%, #5cb85c 50%, #5cb85c 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
}));

function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <PersonIcon />,
    2: <HowToRegIcon />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

function GuardianList(
  parentsList,
  studentsList,
  setUpdateStudentPage: React.Dispatch<SetStateAction<boolean>>,
  updateStudentPage: boolean,
  updateParentPage: boolean,
  userName: string
) {
  const [loadingGrid, setLoadingGrid] = useState(true);
  const [search, setSearch] = useState(``);
  const [activeStep, setStep] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const [list, setList] = useState([] as Guardian[]);
  const [bulkList, setBulkList] = useState([] as Student[]);
  const [studentList, setStudentList] = useState([] as Student[]);
  const [selectedParent, setParent] = useState({} as Guardian);
  const [editingParent, setEditingParent] = useState({} as Guardian);
  const [addingParent, setAddingParent] = useState({} as Guardian);

  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [isBulkEditing, setIsBulkEditing] = useState(false);
  const [isViewingTable, setIsViewingTable] = useState(false);

  const navigate = useNavigate();

  const filterGuardians = (guardians: Guardian[], searchTerm: string) => {
    return guardians.filter((guardian: Guardian) => {
      let fullName = (
        guardian.firstname +
        " " +
        guardian.lastname
      ).toLowerCase();
      return fullName.includes(searchTerm?.toLowerCase() ?? "");
    });
  };

  useEffect(() => {
    if (parentsList.length > 0) {
      setLoadingGrid(true);
      setIsAdding(false);
      setIsBulkEditing(false);
      setIsEditing(false);
      getAllGuardians().then((guardians) => {
        setList(filterGuardians(guardians, search));
        setLoadingGrid(false);
        setStep(0);
        setSearch("");
        setParent(guardians[0]);
      });
      // }
      fetch(
        "https://devschool.kozosms.com/api/api/fees/c892344d-4d67-46ed-8de8-c2c1415bd929"
      )
        .then((resp) => resp.json())
        .then((resp) => {
          setRegistration(resp);
        });
      getAllStudents().then((customers) => {
        // if (studentsList.length > 0) {
        setStudentList(customers);
      });
    }
  }, [updateParentPage]);

  useEffect(() => {
    setLoadingGrid(true);
    // getAllGuardians().then((guardians) => {
    if (parentsList.length > 0) {
      setList(filterGuardians(parentsList, search));
      setLoadingGrid(false);
      setParent(parentsList[0]);
      // });
    }
    // fetch("https://devschool.kozosms.com/api/api/fees/c892344d-4d67-46ed-8de8-c2c1415bd929")
    //   .then((resp) => resp.json())
    //   .then((resp) => {
    //     setRegistration(resp);
    //   });
    // getAllStudents().then((customers) => {

    if (studentsList.length > 0) {
      setStudentList(studentsList);
      // });
    }
  }, [studentsList, parentsList]);

  useEffect(() => {
    setLoadingGrid(true);
    // getAllGuardians().then((guardians) => {

    if (parentsList.length > 0) {
      setList(filterGuardians(parentsList, search));
      setLoadingGrid(false);
      // });
    }
    // fetch("https://devschool.kozosms.com/api/api/fees/c892344d-4d67-46ed-8de8-c2c1415bd929")
    //   .then((resp) => resp.json())
    //   .then((resp) => {
    //     setRegistration(resp);
    //   });
    // getAllStudents().then((customers) => {
    if (studentsList.length > 0) {
      setStudentList(studentsList);
      // });
    }
  }, [search, studentsList, parentsList]);

  useEffect(() => {
    setLoadingGrid(true);
    setIsAdding(false);
    setIsBulkEditing(false);
    setIsEditing(false);

    // getAllGuardians().then((guardians) => {
    if (parentsList.length > 0) {
      setList(filterGuardians(parentsList, search));
      setLoadingGrid(false);
      setStep(0);
      setSearch("");
      setParent(parentsList[0]);
      // });
    }
    // fetch("https://devschool.kozosms.com/api/api/fees/c892344d-4d67-46ed-8de8-c2c1415bd929")
    //   .then((resp) => resp.json())
    //   .then((resp) => {
    //     setRegistration(resp);
    //   });
    // getAllStudents().then((customers) => {
    if (studentsList.length > 0) {
      setStudentList(studentsList);
      // });
    }
  }, [studentsList, parentsList]);

  const addGuardianClicked = () => {
    if (isAdding) {
      setIsAdding(false);
    } else {
      setIsAdding(true);
      setStep(0);
    }
  };

  const deleteHandler = (guardianId: number) => {
    deleteGuardian(guardianId)
      .then((emps) => {
        return getAllGuardians();
      })
      .then((guardians) => {
        setList(guardians);
        setParent({} as Guardian);
        setDeletePopUp(false);
      });
  };

  const steps = ["Personal Information", "Registrations"];

  const handleProgress = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    param: number
  ) => {
    if (isEditing) {
      if (activeStep === 0) {
        if (
          !editingParent.firstname ||
          !editingParent.lastname ||
          !editingParent.title ||
          !editingParent.employer ||
          !editingParent.town
        ) {
          handleOpenRequiredFieldsPopUp();
        } else {
          setStep(param);
        }
      } else if (activeStep === 1) {
        setStep(param);
      } else if (activeStep === 2) {
        setStep(param);
      } else if (activeStep === 3) {
        setStep(param);
      }
    } else if (isAdding) {
      if (activeStep === 0) {
        if (
          !addingParent.firstname ||
          !addingParent.lastname ||
          !addingParent.title ||
          !addingParent.employer ||
          !addingParent.town
        ) {
          handleOpenRequiredFieldsPopUp();
        } else {
          setStep(param);
        }
      } else if (activeStep === 1) {
        setStep(param);
      } else if (activeStep === 2) {
        setStep(param);
      } else if (activeStep === 3) {
        setStep(param);
      }
    } else {
      setStep(param);
    }
  };

  function stringToColor(string: string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    return color;
  }

  function stringAvatar(name: string) {
    return {
      sx: {
        bgcolor: stringToColor(name),
        width: 100,
        height: 100,
        marginRight: 0,
        marginTop: -1,
        fontSize: 35,
      },
      children: `${name.split(" ")[0][0] || ""}${name.split(" ")[1][0] || ""}`,
    };
  }

  function handleNext() {
    let listSize = list.length;

    let index = list.findIndex((object) => {
      return object.id === selectedParent[`id`];
    });

    if (index + 1 === listSize) {
      setParent(list[0]);
      setStep(activeStep);
    } else {
      setParent(list[index + 1]);
      setStep(activeStep);
    }
  }

  function handleBack() {
    let listSize = list.length;

    let index = list.findIndex((object) => {
      return object.id === selectedParent[`id`];
    });

    if (index === 0) {
      setParent(list[listSize - 1]);
      setStep(activeStep);
    } else {
      setParent(list[index - 1]);
      setStep(activeStep);
    }
  }

  function editParent() {
    if (isEditing) {
      getAllGuardians().then((parents) => {
        let index = list.findIndex((object) => {
          return object.id === selectedParent.id;
        });
        setList(parents);
        setParent(parents[index]);
        setIsEditing(false);
        setCancelEditPopUp(false);
      });
    } else {
      setEditingParent(selectedParent);
      setIsEditing(true);
    }
  }

  function addParent() {
    if (isAdding) {
      setAddingParent({} as Guardian);
      setIsAdding(false);
      setCancelAddPopUp(false);
    } else {
    }
    setStep(0);
  }

  let submitForm = (event: any) => {
    event.preventDefault();
    handleOpenLoadDialog();

    editingParent.updatedAt = new Date(Date.now()).toISOString();

    const requestOptions = {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(editingParent),
    };

    fetch(
      "https://devschool.kozosms.com/api/api/guardians/" + editingParent.id,
      requestOptions
    )
      .then((response) => response.json())
      .then((_emps) => {
        return getAllGuardians();
      })
      .then((guardians) => {
        setUpdateStudentPage(!updateStudentPage);
        setList(guardians);
        closeLoadDialog();
        setSnackbarOpen(true);
        setIsEditing(false);
      });
  };

  let addSubmitForm = (event: any) => {
    event.preventDefault();
    handleOpenLoadDialog();

    addingParent.id = uuidv4();
    addingParent.createdAt = new Date(Date.now()).toISOString();
    addingParent.updatedAt = new Date(Date.now()).toISOString();
    addingParent.students = [];

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(addingParent),
    };

    fetch("https://devschool.kozosms.com/api/api/guardians/", requestOptions)
      .then((_emps) => {
        return getAllGuardians();
      })
      .then((guardians) => {
        setUpdateStudentPage(!updateStudentPage);
        setList(guardians);
        closeLoadDialog();
        setSnackbarOpen(true);
        setParent(addingParent);
        setAddingParent({} as Guardian);
        setIsAdding(false);
      });
  };

  const handleCloseSnackbar = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const actionSnackbar = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSnackbar}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const newRegistrationHandler = (registrations: Registration[]) => {
    if (isEditing) {
      editingParent.registration = registrations;
    } else if (isAdding) {
      addingParent.registration = registrations;
    }
  };

  const newStudentHandler = (students: Student[]) => {
    if (isEditing) {
      editingParent.students = students;
    } else if (isAdding) {
      addingParent.students = students;
    }
  };

  let updateInput = (event: any) => {
    editingParent[`${[event.target.name]}`] = event.target.value;
  };

  let updateAddInput = (event: any) => {
    addingParent[`${[event.target.name]}`] = event.target.value;
  };

  function saveBulkEdit() {
    handleOpenLoadDialog();
    const requestOptions = {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(bulkList),
    };

    fetch("https://devschool.kozosms.com/api/api/guardians/", requestOptions)
      .then((response) => response.json())
      .then((_emps) => {
        return getAllGuardians();
      })
      .then((guardians) => {
        setList(guardians);
        closeLoadDialog();
        setSnackbarOpen(true);
        setIsBulkEditing(false);
      });
  }

  function selectParentFromBulkEdit(index: number) {
    if (isBulkEditing) {
      if (isViewingTable) {
        setIsViewingTable(false);
        setIsBulkEditing(false);
      } else {
        setIsBulkEditing(false);
        setCancelBulkPopUp(false);
      }
    } else {
      setIsBulkEditing(true);
    }
    setParent(parentsList[index]);
  }

  function bulkEdit() {
    if (isBulkEditing) {
      if (isViewingTable) {
        setIsViewingTable(false);
        setIsBulkEditing(false);
      } else {
        setCancelBulkPopUp(false);
        setIsBulkEditing(false);
      }
    } else {
      setIsBulkEditing(true);
    }
  }

  function tableView() {
    setIsViewingTable(true);
    setIsBulkEditing(true);
  }

  const [CancelEditPopUp, setCancelEditPopUp] = React.useState(false);
  const handleCancelEdit = () => {
    setCancelEditPopUp(false);
  };
  const handleOpenEdit = () => {
    setCancelEditPopUp(true);
  };
  const [CancelAddPopUp, setCancelAddPopUp] = React.useState(false);
  const handleCancelAdd = () => {
    setCancelAddPopUp(false);
  };
  const handleOpenAdd = () => {
    setCancelAddPopUp(true);
  };
  const [CancelBulkPopUp, setCancelBulkPopUp] = React.useState(false);
  const handleCancelBulk = () => {
    setCancelBulkPopUp(false);
  };
  const handleOpenBulk = () => {
    setCancelBulkPopUp(true);
  };
  const [deletePopUp, setDeletePopUp] = React.useState(false);
  const handleCloseDelete = () => {
    setDeletePopUp(false);
  };
  const handleOpenDelete = () => {
    setDeletePopUp(true);
  };
  const [requiredFieldsPopUp, setRequiredFieldsPopUp] = React.useState(false);
  const handleCloseRequiredFieldsPopUp = () => {
    setRequiredFieldsPopUp(false);
  };
  const handleOpenRequiredFieldsPopUp = () => {
    setRequiredFieldsPopUp(true);
  };
  const [openLoadDialog, setOpenLoadDialog] = React.useState(false);
  const closeLoadDialog = () => {
    setOpenLoadDialog(false);
  };
  const handleOpenLoadDialog = () => {
    setOpenLoadDialog(true);
  };

  return (
    <Grid
      item
      container
      className="listbackground"
      direction="column"
      justifyContent="space-between"
      alignItems="stretch"
      rowSpacing={2}
      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
    >
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
        message="student saved"
        action={actionSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          sx={{ width: "100%" }}
        >
          Parent Saved
        </Alert>
      </Snackbar>
      <Dialog open={openLoadDialog} keepMounted>
        <DialogContent>
          <CircularProgress></CircularProgress>
        </DialogContent>
      </Dialog>
      <Dialog
        open={CancelEditPopUp}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCancelEdit}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Do you want to cancel the changes?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            If you click Yes, all your changes will be lost. Click No to
            continue with your modifications.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelEdit}>No</Button>
          <Button onClick={editParent}>Yes</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={CancelAddPopUp}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCancelAdd}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Do you want to cancel the changes?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            If you click Yes, all your changes will be lost. Click No to
            continue with your modifications.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelAdd}>No</Button>
          <Button onClick={addParent}>Yes</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={CancelBulkPopUp}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCancelBulk}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Do you want to cancel the changes?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            If you click Yes, all your changes will be lost. Click No to
            continue with your modifications.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelBulk}>No</Button>
          <Button onClick={bulkEdit}>Yes</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={deletePopUp}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseDelete}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Are you sure you want to delete?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            If you click Yes, the record will be removed. Click No to cancel.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDelete}>No</Button>
          <Button onClick={() => deleteHandler(selectedParent.id)}>Yes</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={requiredFieldsPopUp}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseRequiredFieldsPopUp}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Fill all required fields"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Required fields will appear with a *.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseRequiredFieldsPopUp}>Ok</Button>
        </DialogActions>
      </Dialog>
      <Grid container item xs={6} mb={2} alignItems="left">
        {isEditing || isAdding || isBulkEditing ? (
          <div
            style={{
              height: 25,
            }}
          ></div>
        ) : (
          <>
            {AddGuardianButton(addGuardianClicked)}
            <Button
              style={{
                marginLeft: 10,
                marginRight: 10,
              }}
              startIcon={<TableRowsIcon />}
              variant="contained"
              color="primary"
              size="small"
              onClick={() => tableView()}
            >
              Table View
            </Button>
            <Button
              style={{
                marginRight: 10,
              }}
              startIcon={<EditIcon />}
              variant="contained"
              color="primary"
              size="small"
              onClick={() => bulkEdit()}
            >
              Bulk Edit
            </Button>
          </>
        )}
        {isBulkEditing ? (
          <>
            {isViewingTable ? (
              <>
                <Button
                  startIcon={<ChevronLeftIcon />}
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => bulkEdit()}
                >
                  Back
                </Button>
              </>
            ) : (
              <>
                <Button
                  style={{
                    marginRight: 10,
                  }}
                  startIcon={<SaveAsIcon />}
                  variant="contained"
                  color="success"
                  form="bulk-edit-employee"
                  size="small"
                  onClick={() => saveBulkEdit()}
                >
                  Save Changes
                </Button>
                <Button
                  startIcon={<CancelIcon />}
                  variant="contained"
                  color="warning"
                  size="small"
                  onClick={() => handleOpenBulk()}
                >
                  Cancel
                </Button>
              </>
            )}
          </>
        ) : (
          <></>
        )}
      </Grid>
      <Grid className="row">
        {isBulkEditing ? (
          <div>
            <Grid item>{SearchBar(setSearch, search)}</Grid>
            {BulkParentTable(
              selectParentFromBulkEdit,
              setBulkList,
              deleteHandler,
              list,
              isViewingTable,
              loadingGrid,
              userName
            )}
          </div>
        ) : (
          <></>
        )}
        <Grid item className="col" xs={3}>
          {isEditing || isAdding || isBulkEditing ? (
            <div></div>
          ) : (
            <div>
              <Grid item>{SearchBar(setSearch, search)}</Grid>
              <Grid item>
                <Paper>
                  {GuardianTable(
                    navigate,
                    deleteHandler,
                    list,
                    setParent,
                    loadingGrid
                  )}
                </Paper>
              </Grid>
            </div>
          )}
        </Grid>

        <Grid className="col pagebottom" item>
          {selectedParent.id || isAdding || isBulkEditing ? (
            <section className="left  view-contact">
              {isBulkEditing ? (
                <div></div>
              ) : (
                <>
                  {isAdding ? (
                    <>
                      <form id="add-parent" onSubmit={addSubmitForm}>
                        <CardWrapper className="row  viewcardmain">
                          <div className="row">
                            <div className="col">
                              <Button
                                className="skipbtn"
                                style={{
                                  display: "none",
                                  float: "center",
                                  objectFit: "cover",
                                  objectPosition: "100%",
                                  width: 45,
                                  minWidth: 45,
                                  height: 45,
                                  minHeight: 45,
                                  marginLeft: 0,
                                  marginTop: 15,
                                  marginBottom: -10,
                                  boxShadow:
                                    "2px 4px 4px 2px rgba(0, 0, 0, 0.2)",
                                  zIndex: 15,
                                  backgroundColor: "#1565C0",
                                  color: "#fff",
                                  borderRadius: "50%",
                                }}
                              >
                                <ArrowBackIosNewIcon />
                              </Button>
                            </div>
                            <div className="col"></div>
                            <div className="col">
                              <div className="row"></div>
                              <div className="row"></div>
                            </div>
                            <div className="col">
                              <div
                                className="mb-2"
                                style={{
                                  width: 140,
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "right",
                                  justifyContent: "right",
                                  marginTop: 19,
                                }}
                              >
                                <Button
                                  startIcon={<SaveAsIcon />}
                                  variant="contained"
                                  color="success"
                                  form="add-parent"
                                  size="small"
                                  type="submit"
                                >
                                  Add Parent
                                </Button>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "right",
                                  justifyContent: "right",
                                }}
                              >
                                <Button
                                  startIcon={<CancelIcon />}
                                  variant="contained"
                                  color="warning"
                                  size="small"
                                  onClick={() => handleOpenAdd()}
                                >
                                  Cancel
                                </Button>
                              </div>
                            </div>
                            <div className="col">
                              <Button
                                className="skipbtn"
                                style={{
                                  display: "none",
                                  float: "right",
                                  objectFit: "cover",
                                  objectPosition: "100%",
                                  width: 15,
                                  minWidth: 15,
                                  height: 45,
                                  minHeight: 45,
                                  marginLeft: 0,
                                  marginRight: 0,
                                  marginTop: 15,
                                  marginBottom: -10,
                                  boxShadow:
                                    "2px 4px 4px 2px rgba(0, 0, 0, 0.2)",
                                  zIndex: 15,
                                  backgroundColor: "#1565C0",
                                  color: "#fff",
                                  borderRadius: "50%",
                                }}
                              >
                                <ArrowForwardIosIcon />
                              </Button>
                            </div>
                          </div>
                        </CardWrapper>
                        <div className="viewcardbody">
                          <Stepper
                            alternativeLabel
                            activeStep={activeStep}
                            style={{ marginBottom: "5px" }}
                          >
                            {steps.map((label, index) => {
                              const stepProps: { completed?: boolean } = {};
                              const labelProps: {
                                optional?: React.ReactNode;
                              } = {};
                              return (
                                <Step key={label} {...stepProps}>
                                  <Button
                                    onClick={(event) =>
                                      handleProgress(event, index)
                                    }
                                  >
                                    <StepLabel
                                      key={label}
                                      StepIconComponent={ColorlibStepIcon}
                                      {...labelProps}
                                    >
                                      <div className="steplabel">{label}</div>
                                    </StepLabel>
                                  </Button>
                                </Step>
                              );
                            })}
                          </Stepper>
                          {activeStep === 0 && (
                            <div>
                              <div className="row input-heading-row">
                                <Typography
                                  style={{
                                    paddingLeft: 0,
                                    marginTop: "10px",
                                    fontSize: 16,
                                  }}
                                >
                                  Personal Information
                                </Typography>
                              </div>

                              <div
                                className="row"
                                style={{ marginTop: "18px" }}
                              >
                                <div className="col">
                                  <TextField
                                    required
                                    size="small"
                                    style={{
                                      marginTop: 5,
                                      display: "flex",
                                      minWidth: 100,
                                      flex: 1,
                                      marginRight: 9,
                                    }}
                                    label="First Name"
                                    name="firstname"
                                    onChange={updateAddInput}
                                    defaultValue={addingParent.firstname}
                                  />
                                </div>
                                <div className="col">
                                  <TextField
                                    size="small"
                                    style={{
                                      marginTop: 5,
                                      display: "flex",
                                      minWidth: 100,
                                      flex: 1,
                                      marginRight: 9,
                                    }}
                                    label="Middle Name"
                                    name="middlename"
                                    onChange={updateAddInput}
                                    defaultValue={addingParent.middlename}
                                  />
                                </div>
                                <div className="col">
                                  <TextField
                                    required
                                    size="small"
                                    style={{
                                      marginTop: 5,
                                      display: "flex",
                                      minWidth: 100,
                                      flex: 1,
                                      marginRight: 9,
                                    }}
                                    label="Last Name"
                                    name="lastname"
                                    onChange={updateAddInput}
                                    defaultValue={addingParent.lastname}
                                  />
                                </div>
                                <div className="col">
                                  <FormControl
                                    required
                                    size="small"
                                    style={{
                                      marginTop: 5,
                                      display: "flex",
                                      minWidth: 100,
                                      flex: 1,
                                      marginRight: 9,
                                    }}
                                  >
                                    <InputLabel id="gender-label">
                                      Title
                                    </InputLabel>
                                    <Select
                                      name="title"
                                      labelId="title-label"
                                      id="title"
                                      defaultValue={addingParent.title}
                                      onChange={updateAddInput}
                                      label="Title"
                                      required
                                      // native
                                    >
                                      <MenuItem value={"Dr"}>Dr</MenuItem>
                                      <MenuItem value={"Mr"}>Mr</MenuItem>
                                      <MenuItem value={"Mrs"}>Mrs</MenuItem>
                                      <MenuItem value={"Ms"}>Ms</MenuItem>
                                    </Select>
                                  </FormControl>
                                </div>
                              </div>

                              <div
                                className="row"
                                style={{ marginTop: "18px" }}
                              >
                                <div className="col">
                                  <TextField
                                    size="small"
                                    style={{
                                      marginTop: 5,
                                      display: "flex",
                                      minWidth: 100,
                                      flex: 1,
                                      marginRight: 9,
                                    }}
                                    type="text"
                                    label="Employer"
                                    name="employer"
                                    defaultValue={addingParent.employer ?? ""}
                                    onChange={updateAddInput}
                                    required
                                    native
                                  />
                                </div>
                                <div className="col">
                                  <TextField
                                    size="small"
                                    style={{
                                      marginTop: 5,
                                      display: "flex",
                                      minWidth: 100,
                                      flex: 1,
                                      marginRight: 9,
                                    }}
                                    type="text"
                                    label="Business"
                                    name="business"
                                    defaultValue={addingParent.business}
                                    onChange={updateAddInput}
                                  />
                                </div>
                                <div className="col">
                                  <NumberFormat
                                    size="small"
                                    style={{
                                      marginTop: 5,
                                      display: "flex",
                                      minWidth: 100,
                                      flex: 1,
                                      marginRight: 9,
                                    }}
                                    label="Phone Number"
                                    className="form-control "
                                    value={addingParent.number}
                                    name="number"
                                    onChange={updateAddInput}
                                    customInput={TextField}
                                    format="+260 ## ####-###"
                                    required
                                  />
                                </div>
                                <div className="col">
                                  <TextField
                                    size="small"
                                    style={{
                                      marginTop: 5,
                                      display: "flex",
                                      minWidth: 100,
                                      flex: 1,
                                      marginRight: 9,
                                    }}
                                    type="email"
                                    label="Email"
                                    name="email"
                                    defaultValue={addingParent.email}
                                    onChange={updateAddInput}
                                  />
                                </div>
                              </div>

                              <div
                                className="row"
                                style={{ marginTop: "18px" }}
                              >
                                <div className="col">
                                  <TextField
                                    style={{
                                      marginTop: 5,
                                      display: "flex",
                                      minWidth: 318.5,
                                      flex: 1,
                                      marginRight: 9,
                                    }}
                                    multiline
                                    type="text"
                                    label="Address"
                                    name="address"
                                    defaultValue={addingParent.address}
                                    onChange={updateAddInput}
                                    InputLabelProps={{ shrink: true }}
                                  />
                                </div>
                                <div className="col">
                                  <TextField
                                    size="small"
                                    style={{
                                      marginTop: 5,
                                      display: "flex",
                                      minWidth: 100,
                                      flex: 1,
                                      marginRight: 9,
                                    }}
                                    type="text"
                                    label="Town"
                                    name="town"
                                    defaultValue={addingParent.town}
                                    onChange={updateAddInput}
                                    required
                                  />
                                </div>
                                <div className="col">
                                  <FormControl
                                    size="small"
                                    style={{
                                      marginTop: 5,
                                      display: "flex",
                                      minWidth: 100,
                                      flex: 1,
                                      marginRight: 9,
                                    }}
                                  >
                                    <InputLabel id="province-label">
                                      Province
                                    </InputLabel>
                                    <Select
                                      name="province"
                                      labelId="province-label"
                                      id="province"
                                      defaultValue={addingParent.province}
                                      onChange={updateAddInput}
                                      label="Province"
                                    >
                                      <MenuItem value={"Central"}>
                                        Central
                                      </MenuItem>
                                      <MenuItem value={"Copperbelt"}>
                                        Copperbelt
                                      </MenuItem>
                                      <MenuItem value={"Eastern"}>
                                        Eastern
                                      </MenuItem>
                                      <MenuItem value={"Luapula"}>
                                        Luapula
                                      </MenuItem>
                                      <MenuItem value={"Lusaka"}>
                                        Lusaka
                                      </MenuItem>
                                      <MenuItem value={"Muchinga"}>
                                        Muchinga
                                      </MenuItem>
                                      <MenuItem value={"Northern"}>
                                        Northern
                                      </MenuItem>
                                      <MenuItem value={"North Western"}>
                                        North Western
                                      </MenuItem>
                                      <MenuItem value={"Southern"}>
                                        Southern
                                      </MenuItem>
                                      <MenuItem value={"Western"}>
                                        Western
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </div>
                                <div className="col"></div>
                              </div>
                            </div>
                          )}

                          {activeStep === 1 && (
                            <div>
                              <div className="row input-heading-row">
                                <Typography
                                  style={{
                                    paddingLeft: 0,
                                    marginTop: "10px",
                                    fontSize: 16,
                                  }}
                                >
                                  Registrations
                                </Typography>
                              </div>

                              <NewEditRegistration
                                onNewStudentsUpdate={newStudentHandler}
                                onRegistrationsUpdate={newRegistrationHandler}
                                guardian={addingParent}
                                students={studentList}
                              />
                            </div>
                          )}
                        </div>
                      </form>
                    </>
                  ) : (
                    <>
                      {isEditing ? (
                        <>
                          <form id="edit-parent" onSubmit={submitForm}>
                            <CardWrapper className="row  viewcardmain">
                              <div className="row">
                                <div className="col">
                                  <Button
                                    className="skipbtn"
                                    style={{
                                      display: "none",
                                      float: "center",
                                      objectFit: "cover",
                                      objectPosition: "100%",
                                      width: 45,
                                      minWidth: 45,
                                      height: 45,
                                      minHeight: 45,
                                      marginLeft: 0,
                                      marginTop: 15,
                                      marginBottom: -10,
                                      boxShadow:
                                        "2px 4px 4px 2px rgba(0, 0, 0, 0.2)",
                                      zIndex: 15,
                                      backgroundColor: "#1565C0",
                                      color: "#fff",
                                      borderRadius: "50%",
                                    }}
                                  >
                                    <ArrowBackIosNewIcon />
                                  </Button>
                                </div>
                                <div className="col">
                                  <Avatar
                                    id="avatar"
                                    style={{
                                      float: "left",
                                      objectFit: "cover",
                                      objectPosition: "100%",
                                      width: 90,
                                      height: 90,
                                      marginLeft: 0,
                                      marginTop: 0,
                                      marginBottom: -20,
                                      boxShadow:
                                        "2px 4px 4px 2px rgba(0, 0, 0, 0.2)",
                                      zIndex: 15,
                                    }}
                                    {...stringAvatar(
                                      editingParent.firstname +
                                        " " +
                                        editingParent.lastname
                                    )}
                                  />
                                </div>
                                <div className="col">
                                  <Typography
                                    variant="h5"
                                    style={{
                                      fontSize: 17,
                                      display: "flex",
                                      minWidth: 180,
                                      maxWidth: 180,
                                      flex: 1,
                                      marginTop: 12,
                                      marginLeft: 10,
                                    }}
                                  >
                                    {editingParent.firstname +
                                      " " +
                                      editingParent.lastname}
                                  </Typography>
                                  <div className="row">
                                    <LocalPhoneIcon
                                      style={{
                                        marginTop: 0,
                                        padding: 0,
                                        height: 17,
                                        width: 17,
                                        maxWidth: 17,
                                        flex: 1,
                                        marginLeft: 20,
                                        color: "#fff",
                                      }}
                                    />
                                    <Typography
                                      style={{
                                        fontSize: 13,
                                        padding: 2,
                                        minWidth: 135,
                                        maxWidth: 135,
                                        flex: 1,
                                        marginLeft: 0,
                                        textAlign: "left",
                                      }}
                                    >
                                      {editingParent.number}
                                    </Typography>
                                  </div>
                                  <div className="row">
                                    <EmailIcon
                                      style={{
                                        marginTop: -3,
                                        padding: 0,
                                        height: 17,
                                        width: 17,
                                        maxWidth: 17,
                                        flex: 1,
                                        marginLeft: 20,
                                        color: "#fff",
                                      }}
                                    />
                                    <Typography
                                      style={{
                                        marginTop: -5,
                                        fontSize: 13,
                                        padding: 2,
                                        minWidth: 135,
                                        maxWidth: 135,
                                        flex: 1,
                                        marginLeft: 0,
                                        textAlign: "left",
                                      }}
                                    >
                                      {editingParent.email}
                                    </Typography>
                                  </div>
                                </div>
                                <div className="col">
                                  <div
                                    className="mb-2"
                                    style={{
                                      width: 140,
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "right",
                                      justifyContent: "right",
                                      marginTop: 19,
                                    }}
                                  >
                                    <Button
                                      startIcon={<SaveAsIcon />}
                                      variant="contained"
                                      color="success"
                                      form="edit-parent"
                                      size="small"
                                      type="submit"
                                    >
                                      Save Changes
                                    </Button>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "right",
                                      justifyContent: "right",
                                    }}
                                  >
                                    <Button
                                      startIcon={<CancelIcon />}
                                      variant="contained"
                                      color="warning"
                                      size="small"
                                      onClick={() => handleOpenEdit()}
                                    >
                                      Cancel
                                    </Button>
                                  </div>
                                </div>
                                <div className="col">
                                  <Button
                                    className="skipbtn"
                                    style={{
                                      display: "none",
                                      float: "right",
                                      objectFit: "cover",
                                      objectPosition: "100%",
                                      width: 15,
                                      minWidth: 15,
                                      height: 45,
                                      minHeight: 45,
                                      marginLeft: 0,
                                      marginRight: 0,
                                      marginTop: 15,
                                      marginBottom: -10,
                                      boxShadow:
                                        "2px 4px 4px 2px rgba(0, 0, 0, 0.2)",
                                      zIndex: 15,
                                      backgroundColor: "#1565C0",
                                      color: "#fff",
                                      borderRadius: "50%",
                                    }}
                                  >
                                    <ArrowForwardIosIcon />
                                  </Button>
                                </div>
                              </div>
                            </CardWrapper>
                            <div className="viewcardbody">
                              <Stepper
                                alternativeLabel
                                activeStep={activeStep}
                                style={{ marginBottom: "5px" }}
                              >
                                {steps.map((label, index) => {
                                  const stepProps: { completed?: boolean } = {};
                                  const labelProps: {
                                    optional?: React.ReactNode;
                                  } = {};
                                  return (
                                    <Step key={label} {...stepProps}>
                                      <Button
                                        onClick={(event) =>
                                          handleProgress(event, index)
                                        }
                                      >
                                        <StepLabel
                                          key={label}
                                          StepIconComponent={ColorlibStepIcon}
                                          {...labelProps}
                                        >
                                          <div className="steplabel">
                                            {label}
                                          </div>
                                        </StepLabel>
                                      </Button>
                                    </Step>
                                  );
                                })}
                              </Stepper>
                              {activeStep === 0 && (
                                <div>
                                  <div className="row input-heading-row">
                                    <Typography
                                      style={{
                                        paddingLeft: 0,
                                        marginTop: "10px",
                                        fontSize: 16,
                                      }}
                                    >
                                      Personal Information
                                    </Typography>
                                  </div>

                                  <div
                                    className="row"
                                    style={{ marginTop: "18px" }}
                                  >
                                    <div className="col">
                                      <TextField
                                        required
                                        size="small"
                                        style={{
                                          marginTop: 5,
                                          display: "flex",
                                          minWidth: 100,
                                          flex: 1,
                                          marginRight: 9,
                                        }}
                                        label="First Name"
                                        name="firstname"
                                        onChange={updateInput}
                                        defaultValue={editingParent.firstname}
                                      />
                                    </div>
                                    <div className="col">
                                      <TextField
                                        size="small"
                                        style={{
                                          marginTop: 5,
                                          display: "flex",
                                          minWidth: 100,
                                          flex: 1,
                                          marginRight: 9,
                                        }}
                                        label="Middle Name"
                                        name="middlename"
                                        onChange={updateInput}
                                        defaultValue={editingParent.middlename}
                                      />
                                    </div>
                                    <div className="col">
                                      <TextField
                                        required
                                        size="small"
                                        style={{
                                          marginTop: 5,
                                          display: "flex",
                                          minWidth: 100,
                                          flex: 1,
                                          marginRight: 9,
                                        }}
                                        label="Last Name"
                                        name="lastname"
                                        onChange={updateInput}
                                        defaultValue={editingParent.lastname}
                                      />
                                    </div>
                                    <div className="col">
                                      <FormControl
                                        required
                                        size="small"
                                        style={{
                                          marginTop: 5,
                                          display: "flex",
                                          minWidth: 100,
                                          flex: 1,
                                          marginRight: 9,
                                        }}
                                      >
                                        <InputLabel id="gender-label">
                                          Title
                                        </InputLabel>
                                        <Select
                                          name="title"
                                          labelId="gender-label"
                                          id="title"
                                          defaultValue={editingParent.title}
                                          onChange={updateInput}
                                          label="Title"
                                          required
                                        >
                                          <MenuItem value={"Dr"}>Dr</MenuItem>
                                          <MenuItem value={"Mr"}>Mr</MenuItem>
                                          <MenuItem value={"Mrs"}>Mrs</MenuItem>
                                          <MenuItem value={"Ms"}>Ms</MenuItem>
                                        </Select>
                                      </FormControl>
                                    </div>
                                  </div>

                                  <div
                                    className="row"
                                    style={{ marginTop: "18px" }}
                                  >
                                    <div className="col">
                                      <TextField
                                        size="small"
                                        style={{
                                          marginTop: 5,
                                          display: "flex",
                                          minWidth: 100,
                                          flex: 1,
                                          marginRight: 9,
                                        }}
                                        type="text"
                                        label="Employer"
                                        name="employer"
                                        defaultValue={
                                          editingParent.employer ?? ""
                                        }
                                        onChange={updateInput}
                                        required
                                        native
                                      />
                                    </div>
                                    <div className="col">
                                      <TextField
                                        size="small"
                                        style={{
                                          marginTop: 5,
                                          display: "flex",
                                          minWidth: 100,
                                          flex: 1,
                                          marginRight: 9,
                                        }}
                                        type="text"
                                        label="Business"
                                        name="business"
                                        defaultValue={editingParent.business}
                                        onChange={updateInput}
                                      />
                                    </div>
                                    <div className="col">
                                      <NumberFormat
                                        size="small"
                                        style={{
                                          marginTop: 5,
                                          display: "flex",
                                          minWidth: 100,
                                          flex: 1,
                                          marginRight: 9,
                                        }}
                                        label="Phone Number"
                                        className="form-control "
                                        value={editingParent.number}
                                        name="number"
                                        onChange={updateInput}
                                        customInput={TextField}
                                        format="+260 ## ####-###"
                                        required
                                      />
                                    </div>
                                    <div className="col">
                                      <TextField
                                        size="small"
                                        style={{
                                          marginTop: 5,
                                          display: "flex",
                                          minWidth: 100,
                                          flex: 1,
                                          marginRight: 9,
                                        }}
                                        type="email"
                                        label="Email"
                                        name="email"
                                        defaultValue={editingParent.email}
                                        onChange={updateInput}
                                      />
                                    </div>
                                  </div>

                                  <div
                                    className="row"
                                    style={{ marginTop: "18px" }}
                                  >
                                    <div className="col">
                                      <TextField
                                        size="small"
                                        style={{
                                          marginTop: 5,
                                          display: "flex",
                                          minWidth: 318.5,
                                          flex: 1,
                                          marginRight: 9,
                                        }}
                                        multiline
                                        type="text"
                                        label="Address"
                                        name="address"
                                        defaultValue={editingParent.address}
                                        onChange={updateInput}
                                        InputLabelProps={{ shrink: true }}
                                      />
                                    </div>
                                    <div className="col">
                                      <TextField
                                        size="small"
                                        style={{
                                          marginTop: 5,
                                          display: "flex",
                                          minWidth: 100,
                                          flex: 1,
                                          marginRight: 9,
                                        }}
                                        type="text"
                                        label="Town"
                                        name="town"
                                        defaultValue={editingParent.town}
                                        onChange={updateInput}
                                        required
                                      />
                                    </div>
                                    <div className="col">
                                      <FormControl
                                        size="small"
                                        style={{
                                          marginTop: 5,
                                          display: "flex",
                                          minWidth: 100,
                                          flex: 1,
                                          marginRight: 9,
                                        }}
                                      >
                                        <InputLabel id="gender-label">
                                          Province
                                        </InputLabel>
                                        <Select
                                          name="province"
                                          labelId="province-label"
                                          id="province"
                                          defaultValue={editingParent.province}
                                          onChange={updateInput}
                                          label="Province"
                                        >
                                          <MenuItem value={"Central"}>
                                            Central
                                          </MenuItem>
                                          <MenuItem value={"Copperbelt"}>
                                            Copperbelt
                                          </MenuItem>
                                          <MenuItem value={"Eastern"}>
                                            Eastern
                                          </MenuItem>
                                          <MenuItem value={"Luapula"}>
                                            Luapula
                                          </MenuItem>
                                          <MenuItem value={"Lusaka"}>
                                            Lusaka
                                          </MenuItem>
                                          <MenuItem value={"Muchinga"}>
                                            Muchinga
                                          </MenuItem>
                                          <MenuItem value={"Northern"}>
                                            Northern
                                          </MenuItem>
                                          <MenuItem value={"North Western"}>
                                            North Western
                                          </MenuItem>
                                          <MenuItem value={"Southern"}>
                                            Southern
                                          </MenuItem>
                                          <MenuItem value={"Western"}>
                                            Western
                                          </MenuItem>
                                        </Select>
                                      </FormControl>
                                    </div>
                                  </div>
                                </div>
                              )}

                              {activeStep === 1 && (
                                <div>
                                  <div className="row input-heading-row">
                                    <Typography
                                      style={{
                                        paddingLeft: 0,
                                        marginTop: "10px",
                                        fontSize: 16,
                                      }}
                                    >
                                      Registrations
                                    </Typography>
                                  </div>

                                  <NewEditRegistration
                                    key={studentsList}
                                    onNewStudentsUpdate={newStudentHandler}
                                    onRegistrationsUpdate={
                                      newRegistrationHandler
                                    }
                                    guardian={editingParent}
                                    students={studentList}
                                  />
                                </div>
                              )}
                            </div>
                          </form>
                        </>
                      ) : (
                        <>
                          <CardWrapper className="row  viewcardmain">
                            <div className="row">
                              <div className="col">
                                <Button
                                  className="skipbtn"
                                  style={{
                                    float: "center",
                                    objectFit: "cover",
                                    objectPosition: "100%",
                                    width: 45,
                                    minWidth: 45,
                                    height: 45,
                                    minHeight: 45,
                                    marginLeft: 0,
                                    marginTop: 15,
                                    marginBottom: -10,
                                    boxShadow:
                                      "2px 4px 4px 2px rgba(0, 0, 0, 0.2)",
                                    zIndex: 15,
                                    backgroundColor: "#4e9c4e",
                                    color: "#fff",
                                    borderRadius: "50%",
                                  }}
                                  onClick={() => {
                                    handleBack();
                                  }}
                                >
                                  <ArrowBackIosNewIcon />
                                </Button>
                              </div>
                              <div className="col">
                                <Avatar
                                  id="avatar"
                                  style={{
                                    float: "left",
                                    objectFit: "cover",
                                    objectPosition: "100%",
                                    width: 90,
                                    height: 90,
                                    marginLeft: 0,
                                    marginTop: 0,
                                    marginBottom: -20,
                                    boxShadow:
                                      "2px 4px 4px 2px rgba(0, 0, 0, 0.2)",
                                    zIndex: 15,
                                  }}
                                  {...stringAvatar(
                                    selectedParent[`firstname`] +
                                      " " +
                                      selectedParent[`lastname`]
                                  )}
                                />
                              </div>
                              <div className="col">
                                <Typography
                                  variant="h5"
                                  style={{
                                    fontSize: 17,
                                    display: "flex",
                                    minWidth: 180,
                                    maxWidth: 180,
                                    flex: 1,
                                    marginTop: 12,
                                    marginLeft: 10,
                                  }}
                                >
                                  {selectedParent[`firstname`] +
                                    " " +
                                    selectedParent[`lastname`]}
                                </Typography>
                                <div className="row">
                                  <LocalPhoneIcon
                                    style={{
                                      marginTop: 0,
                                      padding: 0,
                                      height: 17,
                                      width: 17,
                                      maxWidth: 17,
                                      flex: 1,
                                      marginLeft: 20,
                                      color: "#fff",
                                    }}
                                  />
                                  <Typography
                                    style={{
                                      fontSize: 13,
                                      padding: 2,
                                      minWidth: 135,
                                      maxWidth: 135,
                                      flex: 1,
                                      marginLeft: 0,
                                      textAlign: "left",
                                    }}
                                  >
                                    {selectedParent[`number`]}
                                  </Typography>
                                </div>
                                <div className="row">
                                  <EmailIcon
                                    style={{
                                      marginTop: -3,
                                      padding: 0,
                                      height: 17,
                                      width: 17,
                                      maxWidth: 17,
                                      flex: 1,
                                      marginLeft: 20,
                                      color: "#fff",
                                    }}
                                  />
                                  <Typography
                                    style={{
                                      marginTop: -5,
                                      fontSize: 13,
                                      padding: 2,
                                      minWidth: 135,
                                      maxWidth: 135,
                                      flex: 1,
                                      marginLeft: 0,
                                      textAlign: "left",
                                    }}
                                  >
                                    {selectedParent[`email`]}
                                  </Typography>
                                </div>
                              </div>
                              <div className="col">
                                <div
                                  className="mb-2"
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "right",
                                    justifyContent: "right",
                                    marginTop: 19,
                                  }}
                                >
                                  <Button
                                    startIcon={<EditIcon />}
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    onClick={() => editParent()}
                                  >
                                    Edit
                                  </Button>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "right",
                                    justifyContent: "right",
                                  }}
                                >
                                  <PermissionsGate scopes={[SCOPES.canDelete]}>
                                    <Button
                                      startIcon={<DeleteIcon />}
                                      variant="contained"
                                      color="warning"
                                      size="small"
                                      onClick={handleOpenDelete}
                                    >
                                      Delete
                                    </Button>
                                  </PermissionsGate>
                                </div>
                              </div>
                              <div className="col">
                                <Button
                                  className="skipbtn"
                                  style={{
                                    float: "right",
                                    objectFit: "cover",
                                    objectPosition: "100%",
                                    width: 45,
                                    minWidth: 45,
                                    height: 45,
                                    minHeight: 45,
                                    marginLeft: 0,
                                    marginRight: 0,
                                    marginTop: 15,
                                    marginBottom: -10,
                                    boxShadow:
                                      "2px 4px 4px 2px rgba(0, 0, 0, 0.2)",
                                    zIndex: 15,
                                    backgroundColor: "#4e9c4e",
                                    color: "#fff",
                                    borderRadius: "50%",
                                  }}
                                  onClick={() => {
                                    handleNext();
                                  }}
                                >
                                  <ArrowForwardIosIcon />
                                </Button>
                              </div>
                            </div>
                          </CardWrapper>
                          <div className="viewcardbody">
                            <Stepper
                              alternativeLabel
                              activeStep={activeStep}
                              style={{ marginBottom: "5px" }}
                            >
                              {steps.map((label, index) => {
                                const stepProps: { completed?: boolean } = {};
                                const labelProps: {
                                  optional?: React.ReactNode;
                                } = {};
                                return (
                                  <Step key={label} {...stepProps}>
                                    <Button
                                      onClick={(event) =>
                                        handleProgress(event, index)
                                      }
                                    >
                                      <StepLabel
                                        key={label}
                                        StepIconComponent={ColorlibStepIcon}
                                        {...labelProps}
                                      >
                                        <div className="steplabel">{label}</div>
                                      </StepLabel>
                                    </Button>
                                  </Step>
                                );
                              })}
                            </Stepper>
                            {activeStep === 0 && (
                              <div>
                                <div className="row input-heading-row">
                                  <Typography
                                    style={{
                                      paddingLeft: 0,
                                      marginTop: "10px",
                                      fontSize: 16,
                                    }}
                                  >
                                    Personal Information
                                  </Typography>
                                </div>

                                <div className="row">
                                  <div className="col">
                                    <Typography
                                      style={{
                                        paddingLeft: 0,
                                        fontSize: 9,
                                        color: "#878787",
                                      }}
                                    >
                                      First Name
                                    </Typography>
                                    <Typography
                                      style={{ paddingLeft: 0, fontSize: 13 }}
                                    >
                                      {selectedParent[`firstname`]}
                                    </Typography>
                                  </div>
                                  <div className="col">
                                    <Typography
                                      style={{
                                        paddingLeft: 0,
                                        fontSize: 9,
                                        color: "#878787",
                                      }}
                                    >
                                      Middle Name
                                    </Typography>
                                    <Typography
                                      style={{ paddingLeft: 0, fontSize: 13 }}
                                    >
                                      {selectedParent[`middlename`]}
                                    </Typography>
                                  </div>
                                  <div className="col">
                                    <Typography
                                      style={{
                                        paddingLeft: 0,
                                        fontSize: 9,
                                        color: "#878787",
                                      }}
                                    >
                                      Last Name
                                    </Typography>
                                    <Typography
                                      style={{ paddingLeft: 0, fontSize: 13 }}
                                    >
                                      {selectedParent[`lastname`]}
                                    </Typography>
                                  </div>
                                  <div className="col">
                                    <Typography
                                      style={{
                                        paddingLeft: 0,
                                        fontSize: 9,
                                        color: "#878787",
                                      }}
                                    >
                                      Title
                                    </Typography>
                                    <Typography
                                      style={{ paddingLeft: 0, fontSize: 13 }}
                                    >
                                      {selectedParent[`title`]}
                                    </Typography>
                                  </div>
                                </div>

                                <div
                                  className="row"
                                  style={{ marginTop: "10px" }}
                                >
                                  <div className="col">
                                    <Typography
                                      style={{
                                        paddingLeft: 0,
                                        fontSize: 9,
                                        color: "#878787",
                                      }}
                                    >
                                      Employer
                                    </Typography>
                                    <Typography
                                      style={{ paddingLeft: 0, fontSize: 13 }}
                                    >
                                      {selectedParent[`employer`]}
                                    </Typography>
                                  </div>
                                  <div className="col">
                                    <Typography
                                      style={{
                                        paddingLeft: 0,
                                        fontSize: 9,
                                        color: "#878787",
                                      }}
                                    >
                                      Business
                                    </Typography>
                                    <Typography
                                      style={{ paddingLeft: 0, fontSize: 13 }}
                                    >
                                      {selectedParent[`business`]}
                                    </Typography>
                                  </div>
                                  <div className="col">
                                    <Typography
                                      style={{
                                        paddingLeft: 0,
                                        fontSize: 9,
                                        color: "#878787",
                                      }}
                                    >
                                      Phone Number
                                    </Typography>
                                    <Typography
                                      style={{ paddingLeft: 0, fontSize: 13 }}
                                    >
                                      {selectedParent[`number`]}
                                    </Typography>
                                  </div>
                                  <div className="col">
                                    <Typography
                                      style={{
                                        paddingLeft: 0,
                                        fontSize: 9,
                                        color: "#878787",
                                      }}
                                    >
                                      Email
                                    </Typography>
                                    <Typography
                                      style={{ paddingLeft: 0, fontSize: 13 }}
                                    >
                                      {selectedParent[`email`]}
                                    </Typography>
                                  </div>
                                </div>

                                <div
                                  className="row"
                                  style={{ marginTop: "10px" }}
                                >
                                  <div className="col">
                                    <Typography
                                      style={{
                                        minWidth: 320,
                                        paddingLeft: 0,
                                        fontSize: 9,
                                        color: "#878787",
                                      }}
                                    >
                                      Address
                                    </Typography>
                                    <Typography
                                      style={{ paddingLeft: 0, fontSize: 13 }}
                                    >
                                      {selectedParent[`address`]}
                                    </Typography>
                                  </div>
                                  <div className="col">
                                    <Typography
                                      style={{
                                        paddingLeft: 0,
                                        fontSize: 9,
                                        color: "#878787",
                                      }}
                                    >
                                      Town
                                    </Typography>
                                    <Typography
                                      style={{ paddingLeft: 0, fontSize: 13 }}
                                    >
                                      {selectedParent[`town`]}
                                    </Typography>
                                  </div>
                                  <div className="col">
                                    <Typography
                                      style={{
                                        paddingLeft: 0,
                                        fontSize: 9,
                                        color: "#878787",
                                      }}
                                    >
                                      Province
                                    </Typography>
                                    <Typography
                                      style={{ paddingLeft: 0, fontSize: 13 }}
                                    >
                                      {selectedParent[`province`]}
                                    </Typography>
                                  </div>
                                </div>
                              </div>
                            )}

                            {activeStep === 1 && (
                              <div>
                                <div className="row input-heading-row">
                                  <Typography
                                    style={{
                                      paddingLeft: 0,
                                      marginTop: "10px",
                                      fontSize: 16,
                                    }}
                                  >
                                    Registrations
                                  </Typography>
                                </div>

                                <NewViewRegistration
                                  onRegistrationsUpdate={newRegistrationHandler}
                                  guardian={selectedParent}
                                  students={studentList}
                                />
                              </div>
                            )}
                          </div>
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </section>
          ) : (
            <div></div>
          )}
        </Grid>

        {/* <Grid className="col" item xs={4}>
        <ViewParent/>
      </Grid> */}
      </Grid>
    </Grid>
  );
}

export default GuardianList;

function GuardianTable(
  navigate: NavigateFunction,
  clickDelete: Function,
  guardians: Guardian[],
  setParent: React.Dispatch<SetStateAction<[]>>,
  loadingGrid: boolean
) {
  const cols: GridColDef[] = [
    // {
    //   field: "id",
    //   headerName: "#",
    //   minWidth: 70,
    //   flex: 1,
    //   renderHeader: (_params: GridColumnHeaderParams) => (
    //     <strong>{"ID "}</strong>
    //   ),
    // },
    // {
    //   field: "title",
    //   type: "singleSelect",
    //   valueOptions: Object.values(JobTitle),
    //   headerName: "Title",
    //   minWidth: 110,
    //   flex: 1,
    //   renderHeader: (_params: GridColumnHeaderParams) => (
    //     <strong>{"Title"}</strong>
    //   ),
    // },
    {
      field: "fullName",
      headerName: "Full name",
      minWidth: 130,
      flex: 1,
      renderHeader: (_params: GridColumnHeaderParams) => (
        <strong>{"Full name"}</strong>
      ),
    },
    {
      field: "number",
      headerName: "Phone Number",
      width: 130,
      maxWidth: 130,
      flex: 1,
      renderHeader: (_params: GridColumnHeaderParams) => (
        <strong>{"Phone"}</strong>
      ),
    },
  ];

  const rows: any[] = (guardians ?? []).map((guardian) => ({
    id: guardian.id,
    fullName: guardian.firstname + " " + guardian.lastname,
    title: guardian.title,
    number: guardian.number,
    parent: guardian,
  }));

  const onRowClickEvent: GridEventListener<"rowClick"> = (
    params: GridRowParams, // GridRowParams
    _event: MuiEvent<React.MouseEvent<HTMLElement>>,
    _details: GridCallbackDetails
  ) => {
    setParent(params.row.parent);
  };
  const dataTable = (
    <div
      style={{
        height: 615,
        width: "100%",
        backgroundColor: "white",
        fontSize: 3,
      }}
    >
      <DataGrid
        headerHeight={40}
        rowHeight={40}
        rows={rows}
        columns={cols}
        pageSize={13}
        rowsPerPageOptions={[13]}
        checkboxSelection={false}
        onRowClick={onRowClickEvent}
        loading={loadingGrid}
      />
    </div>
  );
  return dataTable;
}

function SearchBar(
  setSearch: React.Dispatch<SetStateAction<string>>,
  search: string
) {
  return (
    <form>
      <div style={{ fontSize: 3, height: 50 }}>
        <TextField
          className="searchbarbackground regularTextField"
          id="outlined-basic"
          variant="outlined"
          fullWidth
          label="Search"
          defaultValue={search}
          onChange={(e) => setSearch(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </div>
    </form>
  );
}

function BulkParentTable(
  selectParentFromBulkEdit: Function,
  setBulkList: Function,
  _clickDelete: Function,
  parents: Guardian[],
  isViewingTable: boolean,
  loadingGrid: boolean,
  userName: string
) {
  function UpdateObject(e) {
    Object.keys(parents).forEach((el) => {
      if (parents[el].id === e.id) {
        parents[el][`${[e.field]}`] = e.value;
        parents[el].updatedAt = new Date(Date.now()).toISOString();
        parents[el].updatedBy = userName;
      }
    });
    setBulkList(parents);
  }

  const cols: GridColDef[] = [
    {
      field: "firstname",
      headerName: "First Name",
      width: 100,
      minWidth: 100,
      flex: 1,
      renderHeader: (_params: GridColumnHeaderParams) => (
        <strong>{"First Name"}</strong>
      ),
      renderCell: (params: GridRenderCellParams) => (
        <div
          style={{
            backgroundColor: "#e8e8e8",
            width: "200%",
            height: "100%",
            paddingTop: 11.5,
          }}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: "lastname",
      headerName: "Last Name",
      width: 100,
      minWidth: 100,
      flex: 1,
      renderHeader: (_params: GridColumnHeaderParams) => (
        <strong>{"Last Name"}</strong>
      ),
      renderCell: (params: GridRenderCellParams) => (
        <div
          style={{
            backgroundColor: "#e8e8e8",
            width: "200%",
            height: "100%",
            paddingTop: 11.5,
          }}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: "address",
      headerName: "Address",
      width: 120,
      minWidth: 120,
      flex: 1,
      renderHeader: (_params: GridColumnHeaderParams) => (
        <strong>{"Address"}</strong>
      ),
      renderCell: (params: GridRenderCellParams) => (
        <div
          style={{
            backgroundColor: "#e8e8e8",
            width: "200%",
            height: "100%",
            paddingTop: 11.5,
          }}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: "title",
      headerName: "Title",
      type: "singleSelect",
      valueOptions: Object.values(Title),
      width: 100,
      minWidth: 100,
      flex: 1,
      renderHeader: (_params: GridColumnHeaderParams) => (
        <strong>{"Title"}</strong>
      ),
      editable: !isViewingTable,
      renderCell: (params: GridRenderCellParams) => (
        <>
          {isViewingTable ? (
            <>
              <div
                style={{
                  backgroundColor: "#e8e8e8",
                  width: "100%",
                  height: "100%",
                  paddingTop: 11.5,
                }}
              >
                {params.value}
              </div>
            </>
          ) : (
            <div
              style={{
                marginLeft: 3,
              }}
            >
              {params.value}{" "}
            </div>
          )}
        </>
      ),
    },
    {
      field: "employer",
      headerName: "Employer",
      width: 100,
      minWidth: 100,
      flex: 1,
      renderHeader: (_params: GridColumnHeaderParams) => (
        <strong>{"Employer"}</strong>
      ),
      renderCell: (params: GridRenderCellParams) => (
        <div
          style={{
            backgroundColor: "#e8e8e8",
            width: "200%",
            height: "100%",
            paddingTop: 11.5,
          }}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: "number",
      headerName: "Phone Number",
      width: 100,
      minWidth: 100,
      flex: 1,
      renderHeader: (_params: GridColumnHeaderParams) => (
        <strong>{"Phone Number"}</strong>
      ),
      editable: !isViewingTable,
      renderCell: (params: GridRenderCellParams) => (
        <>
          {isViewingTable ? (
            <>
              <div
                style={{
                  backgroundColor: "#e8e8e8",
                  width: "100%",
                  height: "100%",
                  paddingTop: 11.5,
                }}
              >
                {params.value}
              </div>
            </>
          ) : (
            <div
              style={{
                marginLeft: 3,
              }}
            >
              {params.value}{" "}
            </div>
          )}
        </>
      ),
    },
    {
      field: "business",
      headerName: "Business",
      width: 100,
      minWidth: 100,
      flex: 1,
      renderHeader: (_params: GridColumnHeaderParams) => (
        <strong>{"Business"}</strong>
      ),
      editable: !isViewingTable,
      renderCell: (params: GridRenderCellParams) => (
        <>
          {isViewingTable ? (
            <>
              <div
                style={{
                  backgroundColor: "#e8e8e8",
                  width: "100%",
                  height: "100%",
                  paddingTop: 11.5,
                }}
              >
                {params.value}
              </div>
            </>
          ) : (
            <div
              style={{
                marginLeft: 3,
              }}
            >
              {params.value}{" "}
            </div>
          )}
        </>
      ),
    },
    // {
    //   field: "parent2lastname",
    //   headerName: "Parent Last Name",
    //   width: 120,
    //   minWidth: 120,
    //   flex: 1,
    //   renderHeader: (_params: GridColumnHeaderParams) => (
    //     <strong className="parentColumnCell">{"Parent Last Name"}</strong>
    //   ),
    //   cellClassName: 'parentColumnCell',
    // },
  ];
  // 54c9a70c-7804-4bcd-b246-38a831290564

  const rows: any[] = parents.map((s) => ({
    id: s.id,
    firstname: s.firstname,
    lastname: s.lastname,
    number: s.number,
    email: s.email,
    address: s.address,
    town: s.town,
    province: s.province,
    employer: s.employer,
    title: s.title,
    business: s.business,
    children:
      (s.students[0]?.firstname ?? "") +
      " " +
      (s.students[0]?.lastname ?? "") +
      (s.students[1] ? ", " : "") +
      (s.students[1]?.firstname ?? "") +
      " " +
      (s.students[1]?.lastname ?? ""),
  }));

  const onRowClickEvent: GridEventListener<"rowClick"> = (
    params: GridRowParams,
    _event: MuiEvent<React.MouseEvent<HTMLElement>>,
    _details: GridCallbackDetails
  ) => {
    if (isViewingTable) {
      let index = parents.findIndex((object) => {
        return object.id === params.row.id;
      });
      console.log(index);
      selectParentFromBulkEdit(index);
    }
  };
  const dataTable = (
    <div
      style={{
        height: 615,
        width: "100%",
        backgroundColor: "white",
        fontSize: 3,
      }}
    >
      <DataGrid
        headerHeight={40}
        rowHeight={40}
        rows={rows}
        columns={cols}
        pageSize={13}
        rowsPerPageOptions={[13]}
        checkboxSelection={false}
        onRowClick={onRowClickEvent}
        onCellEditCommit={UpdateObject}
        loading={loadingGrid}
      />
    </div>
  );
  return dataTable;
}

function AddGuardianButton(
  addGuardianClicked: MouseEventHandler<HTMLButtonElement>
) {
  return (
    <Button
      startIcon={<PersonAddIcon />}
      variant="contained"
      color="success"
      size="small"
      onClick={addGuardianClicked}
    >
      Add Parent
    </Button>
  );
}

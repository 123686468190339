import {
    Button,
    TextField,
    Typography,
    IconButton,
    Snackbar,
    Alert,
    InputAdornment,
  } from "@mui/material";
  
  import React, { useState } from "react";
  import { useNavigate } from "react-router-dom";
  import countries from "i18n-iso-countries";
  import enLocale from "i18n-iso-countries/langs/en.json";
  import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
  import SaveAsIcon from "@mui/icons-material/SaveAs";
  import ArrowBackIcon from "@mui/icons-material/ArrowBack";
  import CloseIcon from "@mui/icons-material/Close";
  
  class NewRegistration extends React.Component {
    state = {
    isLoading: false,
      fees: {
        id: "c892344d-4d67-46ed-8de8-c2c1415bd929",
        registration: 0,
        babyclass: 0,
        grade1: 0,
        grade2: 0,
        grade3: 0,
        grade4: 0,
        grade5: 0,
        grade6: 0,
        grade7: 0,
        grade8: 0,
        grade9: 0,
        grade10: 0,
        grade11: 0,
        grade12: 0,
      },
    };
  
    componentDidMount() {
        // fetch("https://devschool.kozosms.com/api/api/fees/c892344d-4d67-46ed-8de8-c2c1415bd929")
        // .then((resp) => resp.json())
        // .then((resp) => {
        //   this.setState({
        //     ...this.state,
        //     fees: resp,
        //     isLoading: false,
        //   });
        // })
        // .then((resp) => {
        //     console.log(this.state.fees)
        //   });
    }
  
    onClick() {
      return true;
    }
  
    render() {
      const navigateFn = this.props.navigateFn;
  
      const handleCloseSnackbar = (
        event: React.SyntheticEvent | Event,
        reason?: string
      ) => {
        if (reason === "clickaway") {
          return;
        }
        this.setState({
          ...this.state,
          snackbarOpen: false,
        });
      };
  
      const actionSnackbar = (
        <React.Fragment>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleCloseSnackbar}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
      );
  
      let updateInput = (event: any) => {
        this.setState({
          ...this.state,
          fees: {
            ...this.state.fees,
            [event.target.name]: event.target.value,
          },
        });
      };
  
      let submitForm = (event: any) => {
         event.preventDefault();
            
  
        const requestOptions = {
          method: "PATCH",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(this.state.fees),
        };
  
        fetch(
          "https://devschool.kozosms.com/api/api/fees/c892344d-4d67-46ed-8de8-c2c1415bd929",
          requestOptions
        )
          .then((response) => response.json())
          .then((data) => this.setState({ postId: data.id }));
    
        this.setState({
          ...this.state,
          snackbarOpen: true,
        });
         window.location.href = "/settings";
      };
  
      let { fees } = this.state;
  

      return (
        <React.Fragment>
          <section className="listbackground">
          <section className="left add-contact">
            <div className="container">
              <div className="row">
                <div className="col" style={{ paddingLeft: 0 }}>
                  <div
                    className="headerlist"
                    style={{ paddingLeft: 0, display: "flex" }}
                  >
                    <EditTwoToneIcon fontSize="large" color="primary" />
                    <Typography variant="h4">
                      {" "}
                      New Registration{" "}
                    </Typography>
                  </div>
                </div>
                <div className="row">
                  <div className="col" style={{ paddingLeft: 0 }}>
                    <div className="mb-2">
                      <Button
                        startIcon={<ArrowBackIcon />}
                        variant="contained"
                        color="secondary"
                        size="large"
                        onClick={() => {
                          navigateFn(`/settings`);
                        }}
                      >
                        Back
                      </Button>
                    </div>
                  </div>
                  <div
                    className="col mb-2"
                    style={{ display: "flex", flexDirection: "row-reverse" }}
                  >
                    <Button
                      startIcon={<SaveAsIcon />}
                      variant="contained"
                      type="submit"
                      form="edit-fees"
                      color="success"
                      size="large"
                    >
                      Save Changes
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </section>
  
          {this.state.isLoading ? (
            <></>
          ) : (
            <section className="left view-contact mt-3 ">
              <form
                id="edit-fees"
                className="container"
                onSubmit={submitForm}
              >
                <div className="row viewcard" style={{ paddingLeft: 0 }}>
                  <div>
                    <>
                        <div>
                          <div className="row input-container">
                            <TextField
                              style={{
                                display: "flex",
                                minWidth: 172,
                                flex: 1,
                                marginRight: 24,
                              }}
                              type="number"
                              label="Registration Fee"
                              name="registration"
                              onChange={updateInput}
                              value={fees.registration}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    K
                                  </InputAdornment>
                                ),
                              }}
                            />
                            <TextField
                              style={{
                                display: "flex",
                                minWidth: 172,
                                flex: 1,
                                marginRight: 24,
                              }}
                              type="number"
                              label="Baby Class"
                              name="babyclass"
                              onChange={updateInput}
                              value={fees.babyclass}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    K
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </div>

                          <div className="row input-container">
                            <TextField
                              style={{
                                display: "flex",
                                minWidth: 172,
                                flex: 1,
                                marginRight: 24,
                              }}
                              type="number"
                              label="Grade 1"
                              name="grade1"
                              onChange={updateInput}
                              value={fees.grade1}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    K
                                  </InputAdornment>
                                ),
                              }}
                            />
                            <TextField
                              style={{
                                display: "flex",
                                minWidth: 172,
                                flex: 1,
                                marginRight: 24,
                              }}
                              type="number"
                              label="Grade 2"
                              name="grade2"
                              onChange={updateInput}
                              value={fees.grade2}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    K
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </div>
  
                          <div className="row input-container">
                            <TextField
                              style={{
                                display: "flex",
                                minWidth: 172,
                                flex: 1,
                                marginRight: 24,
                              }}
                              type="number"
                              label="Grade 3"
                              name="grade3"
                              onChange={updateInput}
                              value={fees.grade3}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    K
                                  </InputAdornment>
                                ),
                              }}
                            />
                            <TextField
                              style={{
                                display: "flex",
                                minWidth: 172,
                                flex: 1,
                                marginRight: 24,
                              }}
                              type="number"
                              label="Grade 4"
                              name="grade4"
                              onChange={updateInput}
                              value={fees.grade4}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    K
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </div>

                          <div className="row input-container">
                            <TextField
                              style={{
                                display: "flex",
                                minWidth: 172,
                                flex: 1,
                                marginRight: 24,
                              }}
                              type="number"
                              label="Grade 5"
                              name="grade5"
                              onChange={updateInput}
                              value={fees.grade5}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    K
                                  </InputAdornment>
                                ),
                              }}
                            />
                            <TextField
                              style={{
                                display: "flex",
                                minWidth: 172,
                                flex: 1,
                                marginRight: 24,
                              }}
                              type="number"
                              label="Grade 6"
                              name="grade6"
                              onChange={updateInput}
                              value={fees.grade6}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    K
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </div>

                          <div className="row input-container">
                            <TextField
                              style={{
                                display: "flex",
                                minWidth: 172,
                                flex: 1,
                                marginRight: 24,
                              }}
                              type="number"
                              label="Grade 7"
                              name="grade7"
                              onChange={updateInput}
                              value={fees.grade7}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    K
                                  </InputAdornment>
                                ),
                              }}
                            />
                            <TextField
                              style={{
                                display: "flex",
                                minWidth: 172,
                                flex: 1,
                                marginRight: 24,
                              }}
                              type="number"
                              label="Grade 8"
                              name="grade8"
                              onChange={updateInput}
                              value={fees.grade8}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    K
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </div>

                          <div className="row input-container">
                            <TextField
                              style={{
                                display: "flex",
                                minWidth: 172,
                                flex: 1,
                                marginRight: 24,
                              }}
                              type="number"
                              label="Grade 9"
                              name="grade9"
                              onChange={updateInput}
                              value={fees.grade9}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    K
                                  </InputAdornment>
                                ),
                              }}
                            />
                            <TextField
                              style={{
                                display: "flex",
                                minWidth: 172,
                                flex: 1,
                                marginRight: 24,
                              }}
                              type="number"
                              label="Grade 10"
                              name="grade10"
                              onChange={updateInput}
                              value={fees.grade10}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    K
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </div>

                          <div className="row input-container">
                            <TextField
                              style={{
                                display: "flex",
                                minWidth: 172,
                                flex: 1,
                                marginRight: 24,
                              }}
                              type="number"
                              label="Grade 11"
                              name="grade11"
                              onChange={updateInput}
                              value={fees.grade11}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    K
                                  </InputAdornment>
                                ),
                              }}
                            />
                            <TextField
                              style={{
                                display: "flex",
                                minWidth: 172,
                                flex: 1,
                                marginRight: 24,
                              }}
                              type="number"
                              label="Grade 12"
                              name="grade12"
                              onChange={updateInput}
                              value={fees.grade12}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    K
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </div>
  
                         
  
                        </div>
                    </>
                  </div>
                </div>
              </form>
              <Snackbar
                open={this.state.snackbarOpen}
                autoHideDuration={4000}
                onClose={handleCloseSnackbar}
                message="Fees saved"
                action={actionSnackbar}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              >
                <Alert
                  onClose={handleCloseSnackbar}
                  severity="success"
                  sx={{ width: "100%" }}
                >
                  Fees saved
                </Alert>
              </Snackbar>
            </section>
          )}
          </section>
        </React.Fragment>
      );
    }
  }
  
  export default withNavigate(NewRegistration);
  
  export function withNavigate<T>(_WrappedComponent: React.ComponentType<T>) {
    const ComponentWithNavigate = (props: any) => {
      const navigate = useNavigate();
      const navigateObj = { navigateFn: navigate };
  
      return <NewRegistration {...navigateObj} {...(props as T)} />;
    };
  
    return ComponentWithNavigate;
  }
  
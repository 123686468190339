import { useState, useEffect } from "react";
import { Amplify, Auth, Hub } from "aws-amplify";
import "@aws-amplify/ui-react/styles.css";
import awsExports from "../../aws-exports";
import { useNavigate } from "react-router";
import KozoLogo from "../../assets/img/images/Kozo_Logo.png";
import KozoTeacher from "../../assets/img/kozoteacher.png";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {
  Button,
  Divider,
  Grid,
  Typography,
  TextField,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import React from "react";
import NumberFormat from "react-number-format";
import ReactCountryFlag from "react-country-flag";
import {
  getAllEmployees,
  getAllParentAccessLogs,
  getAllStudents,
} from "../../util/api-calls";
import { Student } from "../customer/customerlist";
Amplify.configure(awsExports);

const initialFormState = {
  username: "",
  password: "",
  email: "",
  authCode: "",
  formType: "signIn",
  resetCode: "",
  resetPassword: "",
  eid: "",
  dob: "",
  nrc: "",
  school: "",
};

function EmployeeSignIn(setEmployeeaccess: any) {
  const navigate = useNavigate();
  const [formState, updateFormState] = useState(initialFormState);
  const [errormsg, updateError] = useState("");

  const [openLoadDialog, setOpenLoadDialog] = React.useState(false);
  const closeLoadDialog = () => {
    setOpenLoadDialog(false);
  };
  const handleOpenLoadDialog = () => {
    setOpenLoadDialog(true);
  };

  useEffect(() => {
    updateError("");
  }, []);

  function today() {
    let date = new Date();
    return (
      date.getFullYear() +
      "-" +
      ("0" + (new Date().getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + (new Date().getDate() + 1)).slice(-2)
    ).toString();
  }

  function onChange(e: any) {
    //e.persist();
    updateError("");
    updateFormState(() => ({ ...formState, [e.target.name]: e.target.value }));
  }

  const { formType } = formState;

  async function signIn() {
    handleOpenLoadDialog();
    const { eid, dob, nrc, school } = formState;

    if (school != "Ebenezer Private") {
      updateError(
        "The filled information does not match any record. Try again."
      );
      closeLoadDialog();
    } else {
      getAllEmployees().then((employees) => {

        let index = employees.findIndex((object: Employee) => {
          return parseInt(object.employeeid) === parseInt(eid);
        });
        if (
          index === -1 ||
          employees[index]?.dob != dob ||
          parseInt(employees[index]?.nrc.replace(/\//g, '')) != parseInt(nrc.replace(/\//g, ''))
        ) {
          updateError(
            "The filled information does not match any record. Try again."
          );
        } else {

        //   let employeeAccessLog = {
        //     id: uuidv4(),
        //     schoolid: "101",
        //     employeeid: employees[index].id,
        //     createdAt: new Date(Date.now()).toISOString(),
        //   };

        //   const requestOptions = {
        //     method: "POST",
        //     headers: { "Content-Type": "application/json" },
        //     body: JSON.stringify(employeeAccessLog),
        //   };

        //   fetch("https://devschool.kozosms.com/api/api/employeeccesslog/", requestOptions)
        //     .then((_emps) => {
        //       return getAllParentAccessLogs();
        //     })
        //     .then((parentAccessLogs) => {
        //       console.log(parentAccessLogs);
        //     });

          setEmployeeaccess(employees[index]);
          navigate("/employee-access");
        }
        closeLoadDialog();
      });
    }
  }

  return (
    <div className="signinpage">
      <Dialog open={openLoadDialog} keepMounted>
        <DialogContent>
          <CircularProgress></CircularProgress>
        </DialogContent>
      </Dialog>
      <Grid item className="col newsigninblock" xs={3}>
        <Grid item className="row signinblockheader" xs={3}>
          <Grid item className="col logoheader">
            <img src={KozoLogo}></img>
          </Grid>
          <Grid item className="col smsheader" xs={3}>
            <Typography className=" smsheader1">
              School Management System
            </Typography>
            <Divider sx={{ my: 1.5 }} />
            <Typography className=" smsheader2">by Tri8Solutions</Typography>
          </Grid>
        </Grid>
        <Grid item className="row signinsecondheader" xs={3}>
          <Typography variant="h5">Employee Access</Typography>
          <Typography style={{ fontSize: 11 }}>Access payslips</Typography>

          {errormsg ? (
            <>
              <Typography
                style={{
                  marginTop: 3,
                  color: "#cc0000",
                }}
                variant="h7"
              >
                {errormsg}
              </Typography>
            </>
          ) : (
            <>
              <>
                <Typography
                  style={{
                    marginTop: 20,
                    color: "#cc0000",
                  }}
                  variant="h7"
                ></Typography>
              </>
            </>
          )}

          <Grid item marginTop={3}>
            <div className="row input-container signininput">
              <NumberFormat
                size="small"
                style={{
                  marginTop: 20,
                  minWidth: 240,
                  width: 350,
                  maxWidth: 350,
                  flex: 1,
                }}
                className="signinfields regularTextField newsigninfields"
                label="Employee ID #"
                name="eid"
                onChange={onChange}
                customInput={TextField}
                format="######-##-####"
                InputLabelProps={{ shrink: true }}
              />
              <NumberFormat
                size="small"
                style={{
                  marginTop: 20,
                  minWidth: 240,
                  width: 350,
                  maxWidth: 350,
                  flex: 1,
                }}
                className="signinfields regularTextField newsigninfields"
                label="NRC"
                name="nrc"
                onChange={onChange}
                customInput={TextField}
                format="######/##/#"
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                id="outlined-adornment-username-login"
                className="signinfields regularTextField newsigninfields"
                style={{
                  marginTop: 20,
                  minWidth: 240,
                  width: 350,
                  maxWidth: 350,
                  flex: 1,
                }}
                inputProps={{
                  min: "1920-01-24",
                  max: today(),
                }}
                type="date"
                label="DOB"
                name="dob"
                onChange={onChange}
                InputLabelProps={{ shrink: true }}
              />

              <FormControl
                className="signinfields regularTextField newsigninfields"
                size="small"
                style={{
                  marginTop: 20,
                  minWidth: 240,
                  width: 350,
                  maxWidth: 350,
                  flex: 1,
                }}
              >
                <InputLabel shrink={true} id="gender-label">
                  School
                </InputLabel>
                <Select
                  style={{
                    paddingTop: 30,
                  }}
                  defaultValue={""}
                  name="school"
                  labelId="grade-label"
                  id="school"
                  onChange={onChange}
                  label="School"
                >
                  <MenuItem value={"Agincourt Secondary"}>
                    Agincourt Secondary
                  </MenuItem>
                  <MenuItem value={"Burmac Private"}>Burmac Private</MenuItem>
                  <MenuItem value={"Ebenezer Private"}>
                    Ebenezer Private
                  </MenuItem>
                  <MenuItem value={"Holy Cross"}>Holy Cross</MenuItem>
                  <MenuItem value={"Portage Private"}>Portage Private</MenuItem>
                </Select>
              </FormControl>
              <Tooltip title="Login">
                <Button
                  onClick={signIn}
                  size="large"
                  type="submit"
                  variant="contained"
                  style={{
                    marginTop: 20,
                    marginLeft: 0,
                    backgroundColor: "#0048b4",
                    color: "#fff",
                    border: 20,
                    width: 350,
                  }}
                >
                  Segulani
                </Button>
              </Tooltip>
            </div>
          </Grid>
        </Grid>
        <div
        //   style={{
        //     position: "relative",
        //   }}
        >
          <div className="bottomSignin">
            <Grid item marginTop={4}>
              <span
                onClick={() => {
                  navigate("/signin");
                }}
                size="large"
                type="submit"
                variant="contained"
                style={{
                  marginTop: 0,
                  minWidth: 240,
                  flex: 1,

                  width: "100vw",
                  textAlign: "center",
                  maxWidth: 350,
                  color: "#3366CC",
                  fontSize: 14,
                }}
              >
                School Access
              </span>
            </Grid>
            <Grid item marginTop={4}>
              <span
                //   onClick={() => {
                //     navigate("/signin");
                //   }}
                size="large"
                type="submit"
                variant="contained"
                style={{
                  color: "#000",
                  marginTop: 20,
                  color: "#3366CC",
                  fontSize: 12,
                  marginLeft: 0,
                }}
              >
                Privacy Statement
              </span>
              {"  |  "}
              <span
                //   onClick={() => {
                //     navigate("/signin");
                //   }}
                size="large"
                type="submit"
                variant="contained"
                style={{
                  marginTop: 20,
                  color: "#3366CC",
                  fontSize: 12,
                }}
              >
                Careers
              </span>
              {"  |  "}
              <span
                //   onClick={() => {
                //     navigate("/signin");
                //   }}
                size="large"
                type="submit"
                variant="contained"
                style={{
                  marginTop: 20,
                  color: "#3366CC",
                  fontSize: 12,
                }}
              >
                Sustainability Pledge
              </span>
            </Grid>
          </div>
        </div>
      </Grid>

      {/* <Grid>
        <Grid
          style={{
            marginLeft: 500,
          }}
        >
          <div
            style={{
              alignContent: "right",
            }}
          >
            <div
              style={{
                padding: 3,
                marginTop: 170,
                marginRight: 10,
                backgroundColor: "#fff",
                width: 450,
                maxWidth: 450,
                float: "right",
                marginLeft: 100,
              }}
            >
              <Typography
                style={{
                  textAlign: "center",
                  marginLeft: 10,
                  marginTop: 10,
                }}
                variant="h6"
              >
                Hire only Teaching Council of Zambia (TCZ) registered teachers
                through our direct integration with TCZ
              </Typography>
            </div>
            <div
              style={{
                padding: 3,
                marginTop: 50,
                marginLeft: 10,
                backgroundColor: "#fff",
                width: 450,
                maxWidth: 450,
                float: "left",
                marginRight: 100,
              }}
            >
              <Typography
                style={{
                  textAlign: "center",
                  marginLeft: 10,
                  marginTop: 10,
                }}
                variant="h6"
              >
                Ask us about NHIMA, NAPSA and Worker Compensation Direct service
              </Typography>
            </div>

            <div
              style={{
                marginTop: 148,
                marginRight: 10,
                backgroundColor: "#fff",
                width: 500,
                maxWidth: 500,
                float: "right",
                marginLeft: 100,
              }}
            >
              <img src={KozoTeacher} height={350}></img>
            </div>
          </div>
        </Grid>
      </Grid> */}
    </div>
  );
}

export default EmployeeSignIn;

import {
  Button,
  Divider,
  Grid,
  IconButton,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from "@mui/material";
import React from "react";
import { Guardian } from "../../util/data-models";
import DeleteIcon from "@mui/icons-material/Delete";
import { v4 as uuidv4 } from "uuid";

import FamilyRestroomIcon from "@mui/icons-material/FamilyRestroom";
import { TransitionProps } from "@mui/material/transitions";

class EditGuardian extends React.Component<
  { onParentUpdate: (parents: Guardian[]) => void; parents: Guardian[] },
  {
    parents: Guardian[];
    guardianList: any;
    deletePopUp: boolean;
    currentRegistration: number;
  }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      parents: this.props.parents,
      guardianList: "",
      deletePopUp: false,
      currentRegistration: 0,
    };
  }

  componentDidMount() {
    fetch("https://devschool.kozosms.com/api/api/guardians/")
      .then((resp) => resp.json())
      .then((resp) => {
        this.setState({
          ...this.state,
          guardianList: resp,
        });
      });
  }

  Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  handleCloseDelete() {
    this.setState({
      ...this.state,
      deletePopUp: false,
      currentRegistration: 0,
    });
  }

  handleOpenDelete(i: number) {
    console.log(i);
    this.setState({
      currentRegistration: i,
      deletePopUp: true,
    });
  }

  createUI() {
    return (
      <>
        <Dialog
          open={this.state.deletePopUp}
          TransitionComponent={this.Transition}
          keepMounted
          onClose={this.handleCloseDelete.bind(this)}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>{"Are you sure you want to delete?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              If you click Yes, the record will be removed. Click No to cancel.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseDelete.bind(this)}>No</Button>
            <Button
              onClick={this.removeClick.bind(
                this,
                this.state.currentRegistration
              )}
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>

        {this.state?.parents?.map((parent: Guardian, i) => (
          <React.Fragment key={parent.id}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="stretch"
              spacing={2}
            >
              <Grid container item xs={11} spacing={3}>
                <Grid item xs={3.27}>
                  <Box sx={{ minWidth: 130 }}>
                    <FormControl
                      size="small"
                      style={{
                        marginTop: 5,
                        display: "flex",
                        minWidth: 127,
                        flex: 1,
                        marginRight: 9,
                      }}
                    >
                      <InputLabel id="parent-label" shrink={true}>
                        Select Parent
                      </InputLabel>
                      <Select
                      style={{
                        marginTop: 2,
                      }}
                        name="id"
                        labelId="id-label"
                        id="id"
                        value={parent.id || ""}
                        onChange={this.handleChange.bind(this, i)}
                        label="id"
                      >
                        {!!this.state.guardianList?.length &&
                          this.state.guardianList.map((guardian) => (
                            <MenuItem key={guardian.id} value={guardian.id}>
                              {guardian.firstname} {guardian.lastname}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={3.27}>
                  <FormControl
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 127,
                      flex: 1,
                      marginRight: 9,
                    }}
                  >
                    <InputLabel id="parentlegalguardian-label" shrink={true}>
                      Status
                    </InputLabel>
                    <Select
                      style={{
                        marginTop: 2,
                      }}
                      name="parentlegalguardian"
                      labelId="parentlegalguardian-label"
                      id="parentlegalguardian"
                      value={parent.parentlegalguardian || ""}
                      onChange={this.handleChange.bind(this, i)}
                      label="Paternity"
                    >
                      <MenuItem value={"Parent"}>Parent</MenuItem>
                      <MenuItem value={"Legal Guardian"}>
                        Legal Guardian
                      </MenuItem>
                      <MenuItem value={"Guardian"}>Guardian</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={3.27}>
                  <FormControl
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 127,
                      flex: 1,
                      marginRight: 9,
                    }}
                  >
                    <InputLabel id="fathermother-label" shrink={true}>
                      Paternity
                    </InputLabel>
                    <Select
                      style={{
                        marginTop: 2,
                      }}
                      name="fathermother"
                      labelId="fathermother-label"
                      id="fathermother"
                      value={parent.fathermother || ""}
                      onChange={this.handleChange.bind(this, i)}
                      label="Paternity"
                    >
                      <MenuItem value={"Father"}>Father</MenuItem>
                      <MenuItem value={"Mother"}>Mother</MenuItem>
                      <MenuItem value={"Other"}>Other</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={3.27}>
                  <FormControl
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 127,
                      flex: 1,
                      marginRight: 9,
                    }}
                  >
                    <InputLabel
                      id="biologicaladoptedfoster-label"
                      shrink={true}
                    >
                      This student is
                    </InputLabel>
                    <Select
                      style={{
                        marginTop: 2,
                      }}
                      name="biologicaladoptedfoster"
                      labelId="biologicaladoptedfoster-label"
                      id="biologicaladoptedfoster"
                      value={parent.biologicaladoptedfoster || ""}
                      onChange={this.handleChange.bind(this, i)}
                      label="This student is"
                    >
                      <MenuItem value={"Biological"}>Biological</MenuItem>
                      <MenuItem value={"Adopted"}>Adopted</MenuItem>
                      <MenuItem value={"Foster"}>Foster</MenuItem>
                      <MenuItem value={"Other"}>Other</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={3.1}>
                  <TextField
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 127,
                      flex: 1,
                      marginRight: 9,
                    }}
                    label="Relationship"
                    fullWidth
                    type="text"
                    name={"relationship"}
                    value={parent.relationship || ""}
                    onChange={this.handleChange.bind(this, i)}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={3.5}></Grid>
              </Grid>
              <Grid
                container
                item
                direction="column"
                xs={1}
                justifyContent="center"
              >
                <IconButton
                  onClick={() => {
                    this.handleOpenDelete(i);
                  }}
                  style={{ display: "flex" }}
                  aria-label="delete"
                  color="warning"
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Divider style={{ marginTop: 12, marginBottom: 24 }}></Divider>
          </React.Fragment>
        ))}
      </>
    );
  }

  handleChange(i: number, event: React.ChangeEvent) {
    let parents = [...this.state.parents];
    const target = event.target as HTMLInputElement;
    parents[i][event.target.name] = target.value;

    this.setState({ parents });

    this.props.onParentUpdate(parents);
  }

  addClick() {
    this.setState((prevState) => ({
      parents: [
        ...prevState.parents,
        {
          id: uuidv4(),
          fathermother: "",
          relationship: "",
          fathermother: "",
          parentlegalguardian: "",
          biologicaladoptedfoster: "",
        },
      ],
    }));
  }

  removeClick(i: number) {
    let parents = [...this.state.parents];
    parents.splice(i, 1);
    this.setState({ parents, deletePopUp: false, currentRegistration: 0 });
    this.props.onParentUpdate(parents);
  }

  render() {
    return (
      <>
        {this.createUI()}
        <Button
          startIcon={<FamilyRestroomIcon />}
          style={{ marginBottom: 12 }}
          variant="contained"
          color="success"
          size="small"
          onClick={this.addClick.bind(this)}
        >
          Add Parent
        </Button>
      </>
    );
  }
}

export default EditGuardian;

import {S3Config} from './s3-config';
import AWS from 'aws-sdk';
global.Buffer = global.Buffer || require('buffer').Buffer

const S3_access_URL="https://smsdevbucket22.s3.ca-central-1.amazonaws.com/";

export const uploadToS3Bucket = (file, filePath) => {
    return new Promise((resolve, reject) => {
      let s3 = new AWS.S3(S3Config);
  
      const bucketName = S3Config.bucketName;
  
      let bucketPath = filePath;
  
      let params = {
        Bucket: bucketName,
        Key: bucketPath,
        Body: file,
      };
      s3.putObject(params, function (err, data) {
        if (err) {
          console.log(err);
        } else {
          resolve();
        }
      });
    });
  };

export const getS3URL = (filePath) =>{
    return S3_access_URL+filePath;
}

export const deleteFromS3Bucket = (key) =>{
  return new Promise((resolve, reject) => {
    let s3 = new AWS.S3(S3Config);

    const bucketName = S3Config.bucketName;

    let params = {
      Bucket: bucketName,
      Key: key
    };
    s3.deleteObject(params, function (err, data) {
      if (err) {
        console.log(err);
      } else {
        resolve();
      }
    });
  });
};